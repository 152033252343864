import React, { FC, useState } from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Text,
    IconButton,
    FormControl,
    FormErrorMessage,
    Input,
    Button,
    Center,
    useToast,
    Grid,
    GridItem,
} from '@chakra-ui/react'
import { Client } from '@/interfaces'
import { EditLine } from '../icons'
import { colors } from '@/utils/const'
import { useClient } from '@/hooks/client'
import EditableProperty from './EditableProperty'
import EditableForm from './EditableEnterpriseCreatedDate'
import EditableEnterpriseCreatedDate from './EditableEnterpriseCreatedDate'
import EditableAntiquity from './EditableAntiquity'

interface Props {
    client: Client
    opportunityStatus: string
}

const Schema = Yup.object().shape({
    name: Yup.string().required('El nombre es requerido'),
    dni: Yup.string().required('El número de identificación es requerida'),
    postal_code: Yup.string().required('El código postal es requerido'),
    town: Yup.string().required('La población es requerida'),
    province: Yup.string().required('La provincia es requerida'),
    home_address: Yup.string().required('La dirección es requerida'),
    activity: Yup.string().required('La actividad es requerida'),
    cnae: Yup.string(),
    iae: Yup.string(),
    antiquity: Yup.string(),
})

export const CompanyCard: FC<Props> = ({ client, opportunityStatus }) => {
    const { updateClient } = useClient({
        client_id: client?.id,
    })
    const [isEditing, setIsEditing] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const toast = useToast()
    const showIconButton = ['qualified', 'qualified_error'].includes(
        opportunityStatus,
    )
        ? true
        : false

    const formik = useFormik({
        initialValues: {
            name: client?.name || '',
            dni: client?.dni || '',
            home_address: client?.home_address || '',
            postal_code: client?.postal_code || '',
            town: client?.town || '',
            province: client?.province || '',
            activity: client?.activity || '',
            cnae: client?.cnae || '',
            iae: client?.iae || '',
            antiquity: client?.antiquity || '',
        },
        validationSchema: Schema,
        onSubmit: async values => {
            setIsLoading(true)

            try {
                await updateClient({
                    client_id: client?.id,
                    data: {
                        name: values.name,
                        dni: values.dni,
                        home_address: values.home_address,
                        postal_code: values.postal_code,
                        town: values.town,
                        province: values.province,
                        activity: values.activity,
                        cnae: values.cnae,
                        iae: values.iae,
                        antiquity: values.antiquity,
                    },
                })

                toast({
                    title: 'Éxito',
                    description: 'Datos actualizados con éxito',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })

                setIsEditing(false)
            } catch (error) {
                toast({
                    title: 'Error.',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })

                formik.resetForm()
            }

            setIsLoading(false)
        },
    })

    return (
        <Box
            bg="white"
            w="100%"
            borderWidth={1}
            borderRadius="xl"
            boxShadow="lg">
            <Accordion defaultIndex={[0]} allowMultiple>
                <form onSubmit={formik.handleSubmit}>
                    <AccordionItem border="none">
                        <Box
                            ml={4}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between">
                            <Text
                                color="#858585"
                                fontSize={14}
                                fontWeight={400}
                                mt={2}>
                                Datos del cliente
                            </Text>
                            <Box display="flex" alignItems="center">
                                {showIconButton && (
                                    <IconButton
                                        aria-label={''}
                                        bg="transparent"
                                        icon={
                                            <EditLine transform="scale(1.5)" />
                                        }
                                        onClick={() => setIsEditing(!isEditing)}
                                        _active={{}}
                                        _hover={{}}
                                    />
                                )}

                                <AccordionButton>
                                    <AccordionIcon w="35px" h="35px" />
                                </AccordionButton>
                            </Box>
                        </Box>
                        <AccordionPanel px={10}>
                            <Box display="flex" mb={6}>
                                <Text fontSize={13} fontWeight={400} mr={2}>
                                    Tipo
                                </Text>
                                <Text fontSize={14} fontWeight={700}>
                                    {client?.client_type === 's'
                                        ? 'Empresa'
                                        : 'Autónomo'}
                                </Text>
                            </Box>

                            <Grid templateColumns="1fr 1fr" gap={2}>
                                <GridItem w="100%">
                                    <Text
                                        color="#4F4F4F"
                                        fontSize={13}
                                        fontWeight={700}>
                                        Nombre
                                    </Text>
                                    {isEditing ? (
                                        <>
                                            <FormControl
                                                mb={4}
                                                isInvalid={
                                                    formik.errors.name &&
                                                    formik.touched.name
                                                }>
                                                <Input
                                                    id="name"
                                                    placeholder="Nombre"
                                                    w="full"
                                                    maxW="200px"
                                                    rounded="4px"
                                                    size="xs"
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.name}
                                                />
                                                <FormErrorMessage>
                                                    {formik.errors.name}
                                                </FormErrorMessage>
                                            </FormControl>
                                        </>
                                    ) : (
                                        <Text
                                            color="#4F4F4F"
                                            fontSize={12}
                                            fontWeight={400}
                                            ml={4}
                                            mb={4}>
                                            {client?.name}
                                        </Text>
                                    )}
                                </GridItem>

                                <GridItem w="100%">
                                    <Text
                                        color="#4F4F4F"
                                        fontSize={13}
                                        fontWeight={700}>
                                        {client?.client_type === 's'
                                            ? 'CIF'
                                            : 'NIF'}
                                    </Text>
                                    {isEditing ? (
                                        <>
                                            <FormControl
                                                mb={4}
                                                isInvalid={
                                                    formik.errors.dni &&
                                                    formik.touched.dni
                                                }>
                                                <Input
                                                    id="dni"
                                                    placeholder="CIF"
                                                    w="full"
                                                    maxW="200px"
                                                    rounded="4px"
                                                    size="xs"
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.dni}
                                                />
                                                <FormErrorMessage>
                                                    {formik.errors.dni}
                                                </FormErrorMessage>
                                            </FormControl>
                                        </>
                                    ) : (
                                        <Text
                                            color="#4F4F4F"
                                            fontSize={12}
                                            fontWeight={400}
                                            ml={4}
                                            mb={4}>
                                            {client?.dni}
                                        </Text>
                                    )}
                                </GridItem>

                                <GridItem w="100%">
                                    <EditableProperty
                                        id={client?.id}
                                        name="home_address"
                                        initialValue={client?.home_address}
                                        label="Domicilio"
                                    />
                                </GridItem>

                                <GridItem w="100%">
                                    <EditableProperty
                                        id={client?.id}
                                        name="postal_code"
                                        initialValue={client?.postal_code}
                                        label="CP"
                                    />
                                </GridItem>

                                <GridItem w="100%">
                                    <EditableProperty
                                        id={client?.id}
                                        name="town"
                                        initialValue={client?.town}
                                        label="Población"
                                    />
                                </GridItem>

                                <GridItem w="100%">
                                    <EditableProperty
                                        id={client?.id}
                                        name="province"
                                        initialValue={client?.province}
                                        label="Provincia"
                                    />
                                </GridItem>

                                <GridItem w="100%">
                                    <Text
                                        color="#4F4F4F"
                                        fontSize={13}
                                        fontWeight={700}>
                                        Actividad
                                    </Text>
                                    {isEditing ? (
                                        <>
                                            <FormControl
                                                mb={4}
                                                isInvalid={
                                                    formik.errors.activity &&
                                                    formik.touched.activity
                                                }>
                                                <Input
                                                    id="activity"
                                                    placeholder="Actividad"
                                                    w="full"
                                                    maxW="200px"
                                                    rounded="4px"
                                                    size="xs"
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    onBlur={formik.handleBlur}
                                                    value={
                                                        formik.values.activity
                                                    }
                                                />
                                                <FormErrorMessage>
                                                    {formik.errors.activity}
                                                </FormErrorMessage>
                                            </FormControl>
                                        </>
                                    ) : (
                                        <Text
                                            color="#4F4F4F"
                                            fontSize={12}
                                            fontWeight={400}
                                            ml={4}
                                            mb={4}>
                                            {client?.activity}
                                        </Text>
                                    )}
                                </GridItem>

                                <GridItem w="100%">
                                    <Text
                                        color="#4F4F4F"
                                        fontSize={13}
                                        fontWeight={700}>
                                        CNAE
                                    </Text>
                                    {isEditing ? (
                                        <>
                                            <FormControl
                                                mb={4}
                                                isInvalid={
                                                    formik.errors.cnae &&
                                                    formik.touched.cnae
                                                }>
                                                <Input
                                                    id="cnae"
                                                    placeholder="CNAE"
                                                    w="full"
                                                    maxW="200px"
                                                    rounded="4px"
                                                    size="xs"
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.cnae}
                                                />
                                                <FormErrorMessage>
                                                    {formik.errors.cnae}
                                                </FormErrorMessage>
                                            </FormControl>
                                        </>
                                    ) : (
                                        <Text
                                            color="#4F4F4F"
                                            fontSize={12}
                                            fontWeight={400}
                                            ml={4}
                                            mb={4}>
                                            {client?.cnae}
                                        </Text>
                                    )}
                                </GridItem>

                                <GridItem w="100%">
                                    <Text
                                        color="#4F4F4F"
                                        fontSize={13}
                                        fontWeight={700}>
                                        IAE
                                    </Text>
                                    {isEditing ? (
                                        <>
                                            <FormControl
                                                mb={4}
                                                isInvalid={
                                                    formik.errors.iae &&
                                                    formik.touched.iae
                                                }>
                                                <Input
                                                    id="iae"
                                                    placeholder="IAE"
                                                    w="full"
                                                    maxW="200px"
                                                    rounded="4px"
                                                    size="xs"
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.iae}
                                                />
                                                <FormErrorMessage>
                                                    {formik.errors.iae}
                                                </FormErrorMessage>
                                            </FormControl>
                                        </>
                                    ) : (
                                        <Text
                                            color="#4F4F4F"
                                            fontSize={12}
                                            fontWeight={400}
                                            ml={4}
                                            mb={4}>
                                            {client?.iae}
                                        </Text>
                                    )}
                                </GridItem>

                                <GridItem w="100%">
                                    {isEditing ? (
                                        <>
                                            <FormControl
                                                mb={4}
                                                isInvalid={
                                                    formik.errors.antiquity &&
                                                    formik.touched.antiquity
                                                }>
                                                <Input
                                                    id="antiquity"
                                                    placeholder="Antigüedad"
                                                    w="full"
                                                    maxW="200px"
                                                    rounded="4px"
                                                    size="xs"
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    onBlur={formik.handleBlur}
                                                    value={
                                                        formik.values.antiquity
                                                    }
                                                />
                                                <FormErrorMessage>
                                                    {formik.errors.antiquity}
                                                </FormErrorMessage>
                                            </FormControl>
                                        </>
                                    ) : (
                                        // This one
                                        <EditableAntiquity
                                            clientId={client?.id}
                                            initialValue={client?.antiquity}
                                        />
                                    )}
                                </GridItem>

                                <GridItem w="100%" />
                                <GridItem w="100%">
                                    {/* This one  */}
                                    <EditableEnterpriseCreatedDate
                                        clientId={client.id}
                                        initialValue={
                                            client?.enterprise_created_date
                                        }
                                    />
                                </GridItem>
                            </Grid>

                            {isEditing && (
                                <Center mt={6}>
                                    <Button
                                        bg={colors.secondary}
                                        border="1px"
                                        borderColor={colors.secondary}
                                        color="white"
                                        fontSize={12}
                                        fontWeight={500}
                                        h="32px"
                                        isLoading={isLoading}
                                        type="submit"
                                        w={130}
                                        _active={{
                                            bg: colors.primary,
                                            borderColor: colors.tertiary,
                                            color: colors.tertiary,
                                            transform: 'scale(0.98)',
                                        }}
                                        _hover={{
                                            bg: colors.secondary,
                                        }}>
                                        Guardar
                                    </Button>
                                </Center>
                            )}
                        </AccordionPanel>
                    </AccordionItem>
                </form>
            </Accordion>
        </Box>
    )
}
