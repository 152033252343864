import React, { FC, useState, useEffect } from 'react'

import { Box, Text, Spinner } from '@chakra-ui/react'

import { HistorialList } from './HistorialList'
import { useGetEvents } from '@/hooks/opportunities'

interface Props {
    opportunity_id: number
}

export const CommunicationCard: FC<Props> = ({ opportunity_id }) => {
    const [communications, setCommunications] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const { getCommunications } = useGetEvents({
        opportunity_id,
    })

    useEffect(() => {
        const fetchCommunications = async () => {
            setIsLoading(true)
            const communications = await getCommunications({ opportunity_id })
            setCommunications(communications)
            setIsLoading(false)
        }

        fetchCommunications()
    }, [])

    return (
        <Box
            bg="white"
            borderRadius="md"
            borderWidth={1}
            boxShadow="lg"
            fontSize={13}
            p={6}
            pr={12}
            minH={'300px'}
            w="100%">
            <Text fontSize={14} fontWeight={700} color="#474747" mb={4}>
                Historial - Mensajes enviados
            </Text>

            {isLoading ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    w="full"
                    h="150px">
                    <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="xl"
                    />
                </Box>
            ) : (
                <>
                    {communications?.length === 0 ? (
                        <Box
                            alignItems="center"
                            display="flex"
                            justifyContent="center"
                            fontSize={20}
                            fontWeight={500}>
                            <Text>
                                No hay <b>eventos</b> aún
                            </Text>
                        </Box>
                    ) : (
                        <HistorialList communications={communications} />
                    )}
                </>
            )}
        </Box>
    )
}
