import React, { FC, useState, useEffect } from 'react'

import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
    FilterFn,
} from '@tanstack/react-table'

import {
    RankingInfo,
    rankItem,
    compareItems,
} from '@tanstack/match-sorter-utils'

import {
    Box,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    ListItem,
    List,
    Text,
    IconButton,
    Button,
    Link,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
} from '@chakra-ui/react'
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    DeleteIcon,
    ViewIcon,
} from '@chakra-ui/icons'

import { colors } from '@/utils/const'
import { DotsIcon } from '@/components/icons'
import { Pagination } from '@/components/utils'

interface Props {
    data: Note[]
    handleOpenModal: (noteId: number) => void
    handleDeleteModal: (noteId: number) => void
}

interface Note {
    id: number
    title: string
    description: string
    date: string
    user: string
}

declare module '@tanstack/table-core' {
    interface FilterFns {
        fuzzy: FilterFn<unknown>
    }
    interface FilterMeta {
        itemRank: RankingInfo
    }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value)

    // Store the itemRank info
    addMeta({
        itemRank,
    })

    // Return if the item should be filtered in/out
    return itemRank.passed
}

export const NotesTable: FC<Props> = ({
    data = [],
    handleOpenModal,
    handleDeleteModal,
}) => {
    const [mounted, setMounted] = useState(false)

    useEffect(() => setMounted(true), [])

    const columns = React.useMemo<ColumnDef<Note>[]>(
        () => [
            {
                accessorKey: 'title',
                header: () => <Text>Título</Text>,
                cell: info => info.getValue(),
            },
            {
                accessorKey: 'description',
                header: () => <Text>Vista previa</Text>,
                cell: info => (
                    <Text>
                        {info.getValue<string>().length < 20 ? (
                            <>{info.getValue()}</>
                        ) : (
                            <>{`${info.getValue()}`.slice(0, 20)}...</>
                        )}
                    </Text>
                ),
            },
            {
                accessorKey: 'user',
                header: () => <Text>Creador</Text>,
                cell: info => info.getValue(),
            },
            {
                accessorKey: 'date',
                header: () => <Text>Fecha</Text>,
                cell: info =>
                    new Date(`${info.getValue()}`).toLocaleString('es-ES', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                    }),
            },
            {
                accessorKey: 'action',
                header: () => {},
            },
        ],
        [],
    )

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    })

    if (!mounted) return null
    return (
        <Box
            bg="white"
            w="100%"
            borderWidth={1}
            borderRadius="md"
            boxShadow="lg">
            <TableContainer
                mb={2}
                minH={table.getRowModel().rows.length > 0 && '200px'}>
                <Table variant="simple" size={'sm'}>
                    <Thead bg={colors['secondary-soft-hover']}>
                        {table.getHeaderGroups().map(headerGroup => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => {
                                    return (
                                        <Th
                                            key={header.id}
                                            textTransform="capitalize"
                                            py={2}
                                            px={8}
                                            bg={colors['gray-soft-30']}
                                            fontSize={14}
                                            fontWeight={500}
                                            colSpan={header.colSpan}>
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                      header.column.columnDef
                                                          .header,
                                                      header.getContext(),
                                                  )}
                                        </Th>
                                    )
                                })}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {table.getRowModel().rows.map(row => {
                            return (
                                <Tr key={row.id}>
                                    {row.getVisibleCells().map(cell => {
                                        if (cell.column.id === 'title') {
                                            return (
                                                <Td
                                                    py={4}
                                                    color="#202124"
                                                    key={cell.id}
                                                    fontSize={14}
                                                    fontWeight={400}>
                                                    <Link
                                                        onClick={() =>
                                                            handleOpenModal(
                                                                cell.row
                                                                    .original
                                                                    .id,
                                                            )
                                                        }>
                                                        {flexRender(
                                                            cell.column
                                                                .columnDef.cell,
                                                            cell.getContext(),
                                                        )}
                                                    </Link>
                                                </Td>
                                            )
                                        }
                                        if (cell.column.id === 'action') {
                                            return (
                                                <Td key={cell.id} py={4}>
                                                    <Menu>
                                                        <MenuButton aria-label="Options">
                                                            <Box
                                                                borderRadius="md"
                                                                borderWidth={1}
                                                                cursor="pointer"
                                                                display="flex"
                                                                h={5}
                                                                justifyContent="center"
                                                                alignItems="center"
                                                                w={5}>
                                                                <DotsIcon color="#767676" />
                                                            </Box>
                                                        </MenuButton>

                                                        <MenuList
                                                            minWidth="30px"
                                                            fontSize={'14px'}>
                                                            <MenuItem
                                                                icon={
                                                                    <ViewIcon />
                                                                }
                                                                onClick={() =>
                                                                    handleOpenModal(
                                                                        cell.row
                                                                            .original
                                                                            .id,
                                                                    )
                                                                }>
                                                                Ver
                                                            </MenuItem>
                                                            <MenuItem
                                                                icon={
                                                                    <DeleteIcon />
                                                                }
                                                                onClick={() =>
                                                                    handleDeleteModal(
                                                                        cell.row
                                                                            .original
                                                                            .id,
                                                                    )
                                                                }>
                                                                Eliminar
                                                            </MenuItem>
                                                        </MenuList>
                                                    </Menu>
                                                </Td>
                                            )
                                        }

                                        return (
                                            <Td
                                                py={4}
                                                color="#202124"
                                                key={cell.id}
                                                fontSize={14}
                                                fontWeight={400}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext(),
                                                )}
                                            </Td>
                                        )
                                    })}
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
            </TableContainer>

            {table.getRowModel().rows.length === 0 && (
                <Box
                    h="150px"
                    w="full"
                    display="flex"
                    justifyContent="center"
                    alignItems="center">
                    <Text fontSize={14} fontWeight={400}>
                        No hay elementos para mostrar
                    </Text>
                </Box>
            )}

            {table.getPageCount() > 1 && <Pagination table={table} />}
        </Box>
    )
}
