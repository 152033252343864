import React, { FC } from 'react'

import { Box, Text } from '@chakra-ui/react'

import { User3Line } from '../icons'
import { Opportunity, VolunteerRepresentatives } from '@/interfaces'

interface Props {
    opportunity: Opportunity
    representatives: VolunteerRepresentatives[]
}

export const VolunteerRepresentativeCard: FC<Props> = ({
    opportunity,
    representatives,
}) => {
    let representative = 'No tiene'

    if (opportunity?.client?.representative_volunteer_id) {
        const found = representatives.find(
            item => item.id === opportunity.client.representative_volunteer_id,
        )

        if (found) {
            representative = found.name
        }
    }

    return (
        <Box w="100%" borderWidth={1} borderRadius="md" boxShadow="lg">
            <Box
                mx={4}
                my={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between">
                <Box display="flex" alignItems="center">
                    <User3Line transform="scale(1.25)" />
                    <Text ml={2} fontSize={14} color="#858585">
                        Representante Voluntario
                    </Text>
                </Box>
            </Box>
            <Box
                alignItems="center"
                color="#474747"
                display="flex"
                fontSize={14}
                h="130px"
                justifyContent="center"
                w="full">
                {representative}
            </Box>
        </Box>
    )
}
