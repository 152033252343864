import React, { FC } from 'react'

import { FormikProps } from 'formik'
import { Text, Box, FormControl, FormLabel, Textarea } from '@chakra-ui/react'

import { RepresentativeType } from '@/interfaces'

interface Props {
    formik: FormikProps<FormikValues>
    representativesTypes: RepresentativeType
}

interface FormikValues {
    comment: string
    representatives: Representative[]
    client_type: string
}

interface Representative {
    representative_position: string
}

export const Step7: FC<Props> = ({ formik, representativesTypes }) => {
    return (
        <Box w="full" mt={4} color="black">
            <Box
                w="90%"
                display="flex"
                justifyContent="space-between"
                fontSize={13}
                fontWeight={400}>
                <Box display="flex">
                    <Text mr={4}>Cliente</Text>
                    <Text fontWeight={700}>
                        {formik.values.client_type === 'a'
                            ? 'Autónomo'
                            : 'Empresa'}
                    </Text>
                </Box>
                {formik.values.client_type === 's' && (
                    <Box display="flex">
                        <Text mr={4}>Representante</Text>
                        <Text fontWeight={700}>
                            {
                                representativesTypes[
                                    formik.values.representatives[0]
                                        .representative_position
                                ].type
                            }
                        </Text>
                    </Box>
                )}
            </Box>

            <FormControl color="#474747" my={8}>
                <FormLabel fontSize={13} fontWeight={500}>
                    Comentario
                </FormLabel>

                <Textarea
                    id="comment"
                    mb={6}
                    onChange={formik.handleChange}
                    value={formik.values.comment}
                />
            </FormControl>
        </Box>
    )
}
