import React, { FC } from 'react'

import { FormikProps } from 'formik'
import { chakra, Box, RadioGroup, Radio, Stack, Text } from '@chakra-ui/react'
import { Test } from '../qualifySteps/AutoDiagnosticTest'
import { Answers } from '@/interfaces'

interface Props {
    formik: FormikProps<FormikValues>
    question: string
    answers: Answers[]
    answerNumber: number
}

interface FormikValues {
    question_test: Test
}

export const QuestionStep: FC<Props> = ({
    formik,
    question,
    answers,
    answerNumber,
}) => {
    const handleChange = e => {
        formik.setFieldValue(`question_test.${answerNumber}`, {
            answer_id: parseInt(e),
        })
    }

    return (
        <Box minH="180px" my={4} color="black">
            <Text fontSize={14} fontWeight={700} mb={6} textAlign="justify">
                {question}
                <chakra.span color="#FD7C04"> *</chakra.span>
            </Text>

            <RadioGroup
                display="flex"
                justifyContent="center"
                onChange={handleChange}
                value={
                    formik.values.question_test[
                        answerNumber
                    ].answer_id?.toString() ?? ''
                }>
                <Stack direction="column">
                    {answers?.length > 0 &&
                        answers.map(item => (
                            <Radio
                                key={item.id}
                                value={item?.id?.toString()}
                                mb={2}>
                                <Text fontSize={14} fontWeight={400}>
                                    {item.name}
                                </Text>
                            </Radio>
                        ))}
                </Stack>
            </RadioGroup>
        </Box>
    )
}
