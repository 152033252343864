import { OPPORTUNITY_TYPES } from '@/constants'
import useToggle from '@/hooks/useToggle'
import {
    Button,
    HStack,
    Icon,
    IconButton,
    Select,
    Text,
    useToast,
} from '@chakra-ui/react'
import React, { FormEvent } from 'react'
import { EditLine } from '../icons'
import { BsFloppy2, BsX } from 'react-icons/bs'
import { useOpportunity } from '@/hooks/opportunities'

type Props = {
    id: number | string
    opportunity_type: string | undefined
}

export default function OpportunityTypeForm({ opportunity_type, id }: Props) {
    const toast = useToast()

    const [isEditing, toggleEditing] = useToggle()

    const type = OPPORTUNITY_TYPES.find(
        ({ value }) => value === opportunity_type,
    )

    const { updateOpportunity, isUpdating } = useOpportunity({
        id,
    })

    async function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()

        const opportunity_type = e.target['opportunity_type']?.value

        try {
            await updateOpportunity({
                opportunity_id: id,
                data: { opportunity_type },
            })

            toast({
                title: 'Éxito',
                description: 'Actualizado con éxito',
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
        } catch (error) {
            console.error('New Error Updating Value', error)
        } finally {
            toggleEditing()
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <HStack hidden={isEditing}>
                <Text fontSize={12} fontWeight={700}>
                    Tipo de oportunidad:
                </Text>
                <Text fontSize={12} fontWeight={600} mx={2}>
                    {type?.label ?? '-'}
                </Text>

                <IconButton
                    as={Button}
                    aria-label="Editar propiedad"
                    icon={
                        <Icon
                            as={EditLine}
                            transform="scale(1.2)"
                            color="#858585"
                        />
                    }
                    onClick={toggleEditing}
                    variant="ghost"
                    type="button"
                />
            </HStack>

            <HStack hidden={!isEditing}>
                <Select
                    id="opportunity_type"
                    size="xs"
                    rounded={6}
                    defaultValue={opportunity_type}
                    w="250px"
                    isDisabled={isUpdating}>
                    {OPPORTUNITY_TYPES.map(item => (
                        <option key={item.value} value={item.value}>
                            {item.label}
                        </option>
                    ))}
                </Select>

                <IconButton
                    size="sm"
                    as={Button}
                    aria-label="Guardar propiedad"
                    icon={<Icon fontSize="xs" as={BsFloppy2} color="#858585" />}
                    variant="ghost"
                    type="submit"
                    isLoading={isUpdating}
                />

                <IconButton
                    size="sm"
                    as={Button}
                    aria-label="Cerrar"
                    onClick={toggleEditing}
                    icon={<Icon as={BsX} color="#858585" />}
                    variant="ghost"
                    type="button"
                    isLoading={isUpdating}
                />
            </HStack>
        </form>
    )
}
