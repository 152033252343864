import React from 'react'

export default function useToggle() {
    const [isToggle, setIsToggle] = React.useState(false)

    function toggleEditing() {
        setIsToggle(prev => !prev)
    }

    return [isToggle, toggleEditing] as const
}
