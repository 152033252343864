import React, { FC, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
    Button,
    Center,
    HStack,
    Select,
    Text,
    VStack,
    useToast,
} from '@chakra-ui/react'

import { colors } from '@/utils/const'
import { useOpportunity } from '@/hooks/opportunities'
import { statusArray } from '@/hooks/statusOpportunity'
import { OPPORTUNITY_VERSIONS } from '@/constants'

interface Props {
    opportunity_id: number
    status: string
    version: string
}

const Schema = Yup.object().shape({
    status: Yup.string().required('El estado es requerido'),
})

export const SuperAdminForm: FC<Props> = ({
    opportunity_id,
    status,
    version,
}) => {
    const { updateOpportunity } = useOpportunity({
        id: opportunity_id,
    })
    const [isLoading, setIsLoading] = useState(false)

    const toast = useToast()

    const formik = useFormik({
        initialValues: { status, version },
        validationSchema: Schema,
        onSubmit: async values => {
            setIsLoading(true)

            try {
                await updateOpportunity({
                    opportunity_id,
                    data: {
                        status: values.status,
                        opportunity_version: values.version,
                    },
                })

                toast({
                    title: 'Éxito',
                    description: 'Actualizado con éxito',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })
            } catch (error) {
                toast({
                    title: 'Error.',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })

                formik.resetForm()
            } finally {
                setIsLoading(false)
            }
        },
    })

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <HStack spacing={2} w="500px" alignItems="flex-end">
                    <VStack alignItems="flex-start" gap={1}>
                        <Text fontSize={12} fontWeight={500}>
                            Estado de la oportunidad
                        </Text>
                        <Select
                            id="status"
                            size="xs"
                            rounded={6}
                            value={formik.values.status}
                            w="250px"
                            onChange={formik.handleChange}>
                            {statusArray.map(item => (
                                <option key={item.text} value={item.status}>
                                    {item.text}
                                </option>
                            ))}
                        </Select>
                    </VStack>

                    <VStack alignItems="flex-start" gap={1}>
                        <Text fontSize={12} fontWeight={500}>
                            Versión
                        </Text>
                        <Select
                            id="version"
                            size="xs"
                            rounded={6}
                            value={formik.values.version}
                            w="250px"
                            onChange={formik.handleChange}>
                            {OPPORTUNITY_VERSIONS.map(item => (
                                <option key={item.value} value={item.value}>
                                    {item.label}
                                </option>
                            ))}
                        </Select>
                    </VStack>

                    <Center>
                        <Button
                            bg={colors.tertiary}
                            border="1px"
                            color="white"
                            fontSize={12}
                            fontWeight={500}
                            h="24px"
                            isLoading={isLoading}
                            type="submit"
                            w={84}
                            _active={{
                                transform: 'scale(0.98)',
                            }}
                            _hover={{}}>
                            Guardar
                        </Button>
                    </Center>
                </HStack>
            </form>
        </>
    )
}
