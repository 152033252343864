import React, { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react'

import NextLink from 'next/link'
import { useRouter } from 'next/router'

import {
    Column,
    Table,
    useReactTable,
    ColumnFiltersState,
    getCoreRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    sortingFns,
    getSortedRowModel,
    FilterFn,
    SortingFn,
    ColumnDef,
    flexRender,
    FilterFns,
} from '@tanstack/react-table'

import {
    Box,
    Grid,
    GridItem,
    Heading,
    Input,
    Table as ChakraTable,
    TableContainer,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    ListItem,
    List,
    Text,
    chakra,
    Link,
    Spinner,
    Button,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Tooltip,
    Flex,
} from '@chakra-ui/react'

import {
    RankingInfo,
    rankItem,
    compareItems,
} from '@tanstack/match-sorter-utils'
import {
    ChevronDownIcon,
    DeleteIcon,
    TriangleDownIcon,
    TriangleUpIcon,
    ViewIcon,
} from '@chakra-ui/icons'

import { colors } from '@/utils/const'
import { statusArray } from '@/hooks/statusOpportunity'
import { Pagination, PaginationComplex } from '../utils'
import { StatusFilter } from './filters/StatusFilter'
import { DistributorFilter } from './filters/DistributorFilter'
import { ComercialFilter } from './filters/ComercialFilter'
import { Distributor, OpportunityMeta } from '@/interfaces'
import { User } from '@/interfaces'
import { OpportunitiesFilters } from './filters/OpportunitiesFilters'
import { formatDate } from '@/lib/date'
import { OPPORTUNITY_TYPES } from '@/constants'

interface Props {
    userRol: string
    data: Opportunity[]
    meta: OpportunityMeta
    setPageIndex: Dispatch<SetStateAction<number>>
    isLoading: boolean
    handleDelete: (id) => void
    users: User[]
    distributors: Distributor[]
    handleExport: () => void
}

interface Opportunity {
    id: number
    name: string
    id_str: string
    phone: string
    contact_name: string
    status: string
    distributor: Distributor
    comercial: User
}

declare module '@tanstack/table-core' {
    interface FilterFns {
        fuzzy: FilterFn<unknown>
    }
    interface FilterMeta {
        itemRank: RankingInfo
    }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value)

    // Store the itemRank info
    addMeta({
        itemRank,
    })

    // Return if the item should be filtered in/out
    return itemRank.passed
}

export const OpportunitiesTable: FC<Props> = ({
    userRol,
    data = [],
    meta,
    setPageIndex,
    isLoading,
    handleDelete,
    users,
    distributors,
    handleExport,
}) => {
    const rerender = React.useReducer(() => ({}), {})[1]
    const [mounted, setMounted] = React.useState(false)
    const [initialData, setInitialData] = React.useState([])
    const desdeFilter = useRef(null)
    const router = useRouter()

    React.useEffect(() => setMounted(true), [])

    React.useEffect(() => {
        if (!isLoading) {
            const initialData = data.map(item => {
                return {
                    ...item,
                    distributor: {
                        id: item.distributor?.id || 0,
                        alias: item.distributor?.alias || '',
                        name: item.distributor?.name || '',
                    },
                    comercial: {
                        id: item.comercial?.id || 0,
                        alias: item.comercial?.alias || '',
                        name: item.comercial?.name || '',
                    },
                }
            })
            setInitialData(initialData)
        }
    }, [isLoading, data])

    const [
        columnFilters,
        setColumnFilters,
    ] = React.useState<ColumnFiltersState>([])
    const [globalFilter, setGlobalFilter] = React.useState('')

    const columns = React.useMemo<ColumnDef<Opportunity>[]>(
        () => [
            {
                accessorKey: 'name',
                header: () => <Text>Nombre</Text>,
                cell: cell => {
                    const value = cell.getValue<string>()

                    return (
                        <Tooltip
                            bg="white"
                            borderWidth={1}
                            borderColor={colors.secondary}
                            color={colors.secondary}
                            cursor="pointer"
                            rounded="md"
                            fontSize={12}
                            label={`
                    Contácto: ${cell.row.original.contact_name},
                    Teléfono: ${cell.row.original.phone}
                `}>
                            <Box>
                                <NextLink
                                    href={{
                                        pathname: '/opportunities/[id]',
                                        query: {
                                            id: cell.row.original.id,
                                        },
                                    }}>
                                    <Link>{value}</Link>
                                </NextLink>
                            </Box>
                        </Tooltip>
                    )
                },
            },
            { accessorKey: 'id_str', header: () => <Text>ID</Text> },
            {
                accessorKey: 'status',
                header: () => <Text>Estado</Text>,
                cell: cell => {
                    const value = cell.getValue<string>()

                    return (
                        <Text color={getStatusColor(cell.row.original.status)}>
                            {getStatusText(value)}
                        </Text>
                    )
                },
                filterFn: 'equalsString',
            },
            {
                accessorKey: 'comercial.alias',
                header: () => <Text>Responsable</Text>,
                cell: cell => {
                    const name = cell.row.original.comercial.name
                    const alias = cell.row.original.comercial.alias
                    return name ? (
                        <Tooltip
                            bg="white"
                            borderWidth={1}
                            borderColor={colors.secondary}
                            color={colors.secondary}
                            cursor="pointer"
                            rounded="md"
                            fontSize={12}
                            label={name}>
                            {alias}
                        </Tooltip>
                    ) : (
                        '-'
                    )
                },
            },
            {
                accessorKey: 'distributor.alias',
                header: () => <Text>Distribuidor</Text>,
                cell: cell => {
                    const name = cell.row.original.distributor.name
                    const alias = cell.row.original.distributor.alias
                    return name ? (
                        <Tooltip
                            bg="white"
                            borderWidth={1}
                            borderColor={colors.secondary}
                            color={colors.secondary}
                            cursor="pointer"
                            rounded="md"
                            fontSize={12}
                            label={name}>
                            {alias}
                        </Tooltip>
                    ) : (
                        '-'
                    )
                },
            },
            {
                accessorKey: 'prev_close_date',
                header: () => <Text>F. prevista de cierre</Text>,
                filterFn: 'equalsString',
                cell: cell => {
                    const value = cell.getValue<string>()
                    formatDate(value)

                    const date = formatDate(value)

                    return (
                        <Td
                            key={cell.row.original.id}
                            fontSize={12}
                            fontWeight={700}>
                            {date}
                        </Td>
                    )
                },
            },
            {
                accessorKey: 'opportunity_type',
                header: () => <Text>Tipo de oportunidad</Text>,
                cell: cell => {
                    const value = cell.getValue()
                    return (
                        OPPORTUNITY_TYPES.find(item => item.value === value)
                            ?.label ?? '-'
                    )
                },
            },
            {
                accessorKey: 'action',
                header: () => <Text>Acciones</Text>,
                cell: cell => {
                    return (
                        <Box display="flex" justifyContent="center">
                            <Menu>
                                <MenuButton mx="auto" aria-label="Options">
                                    <Box
                                        mx="auto"
                                        borderWidth={1}
                                        borderRadius="md"
                                        cursor="pointer">
                                        <ChevronDownIcon w={5} h={5} />
                                    </Box>
                                </MenuButton>

                                <MenuList minWidth="30px" fontSize={'14px'}>
                                    <MenuItem
                                        icon={<ViewIcon />}
                                        onClick={() =>
                                            router.push(
                                                `/opportunities/${cell.row.original.id}`,
                                            )
                                        }>
                                        Ver
                                    </MenuItem>
                                    {userRol === 'admin' && (
                                        <MenuItem
                                            icon={<DeleteIcon />}
                                            onClick={() =>
                                                handleDelete(
                                                    cell.row.original.id,
                                                )
                                            }>
                                            Eliminar
                                        </MenuItem>
                                    )}
                                </MenuList>
                            </Menu>
                        </Box>
                    )
                },
            },
        ],
        [handleDelete, router, userRol],
    )

    const table = useReactTable({
        data: initialData,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            columnFilters,
            globalFilter,
        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
    })

    // const columnFilter = table.getHeaderGroups()[0].headers[0].column

    const getStatusText = status => {
        const statusFind = statusArray.find(item => item.status === status)
            ? statusArray.find(item => item.status === status)
            : { status: '', text: '', color: colors.primary }
        return statusFind.text
    }

    const getStatusColor = status => {
        const statusFind = statusArray.find(item => item.status === status)
            ? statusArray.find(item => item.status === status)
            : { status: '', text: '', color: colors.primary }
        return statusFind.color
    }

    if (!mounted) return null

    return (
        <Box
            bg="white"
            w="100%"
            borderWidth={1}
            borderRadius="md"
            boxShadow="lg">
            <Grid templateColumns="1fr 150px" gap={2} p={4}>
                <GridItem>
                    <Heading fontSize={18} fontWeight={600}>
                        Lista de oportunidades
                    </Heading>
                </GridItem>
                <Button
                    fontSize={14}
                    bg="white"
                    borderWidth={2}
                    borderColor={colors.secondary}
                    color={colors.secondary}
                    h="30px"
                    p={1}
                    w="150px"
                    onClick={handleExport}
                    _active={{
                        transform: 'scale(0.98)',
                    }}
                    _hover={{}}>
                    Exportar Excel
                </Button>
            </Grid>

            <OpportunitiesFilters
                comercials={users}
                distributors={distributors}
                setPageIndex={setPageIndex}
            />

            <TableContainer mb={2} h="340px">
                {isLoading ? (
                    <Box
                        w="full"
                        h="280px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center">
                        <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="blue.500"
                            size="xl"
                        />
                    </Box>
                ) : (
                    <>
                        {table.getRowModel().rows.length === 0 ? (
                            <Box
                                h="200px"
                                w="full"
                                display="flex"
                                justifyContent="center"
                                alignItems="center">
                                <Text fontSize={20} fontWeight={500}>
                                    No hay oportunidades aún
                                </Text>
                            </Box>
                        ) : (
                            <ChakraTable variant="unstyled" size={'sm'}>
                                <Thead bg={colors['secondary-soft-hover']}>
                                    {table
                                        .getHeaderGroups()
                                        .map(headerGroup => (
                                            <Tr key={headerGroup.id}>
                                                {headerGroup.headers.map(
                                                    header => {
                                                        return (
                                                            <Th
                                                                key={header.id}
                                                                textTransform="inherit"
                                                                colSpan={
                                                                    header.colSpan
                                                                }>
                                                                {header.isPlaceholder ? null : (
                                                                    <>
                                                                        <Box
                                                                            {...{
                                                                                display:
                                                                                    'flex',
                                                                                className: header.column.getCanSort()
                                                                                    ? 'cursor-pointer select-none'
                                                                                    : '',
                                                                                onClick: header.column.getToggleSortingHandler(),
                                                                            }}>
                                                                            {flexRender(
                                                                                header
                                                                                    .column
                                                                                    .columnDef
                                                                                    .header,
                                                                                header.getContext(),
                                                                            )}
                                                                            {{
                                                                                asc: (
                                                                                    <chakra.span
                                                                                        ml={
                                                                                            1
                                                                                        }>
                                                                                        {' '}
                                                                                        <TriangleUpIcon aria-label="sorted ascending" />
                                                                                    </chakra.span>
                                                                                ),
                                                                                desc: (
                                                                                    <chakra.span
                                                                                        ml={
                                                                                            1
                                                                                        }>
                                                                                        {' '}
                                                                                        <TriangleDownIcon aria-label="sorted descending" />
                                                                                    </chakra.span>
                                                                                ),
                                                                            }[
                                                                                header.column.getIsSorted() as string
                                                                            ] ??
                                                                                null}
                                                                        </Box>
                                                                    </>
                                                                )}
                                                            </Th>
                                                        )
                                                    },
                                                )}
                                            </Tr>
                                        ))}
                                </Thead>
                                <Tbody>
                                    {table.getRowModel().rows.map(row => {
                                        return (
                                            <Tr key={row.id}>
                                                {row
                                                    .getVisibleCells()
                                                    .map(cell => {
                                                        return (
                                                            <Td
                                                                py={0}
                                                                key={cell.id}
                                                                fontSize={12}
                                                                fontWeight={
                                                                    700
                                                                }>
                                                                {flexRender(
                                                                    cell.column
                                                                        .columnDef
                                                                        .cell,
                                                                    cell.getContext(),
                                                                )}
                                                            </Td>
                                                        )
                                                    })}
                                            </Tr>
                                        )
                                    })}
                                </Tbody>
                            </ChakraTable>
                        )}
                    </>
                )}
            </TableContainer>

            {meta?.last_page > 0 && (
                <PaginationComplex meta={meta} setPageIndex={setPageIndex} />
            )}
        </Box>
    )
}
