import React, { FC, useState } from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    Text,
    Button,
    Box,
    useToast,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Divider,
    Textarea,
    chakra,
} from '@chakra-ui/react'
import { colors } from '@/utils/const'
import { Opportunity } from '@/interfaces'
import { useOpportunity } from '@/hooks/opportunities'

interface Props {
    opportunity: Opportunity
    isOpen: boolean
    onClose: () => void
}

const SIX_MONTHS = 15634800000

const Schema = Yup.object().shape({
    reason: Yup.string().min(20, 'El motivo debe tener mínimo 20 caracteres'),
})

export const LostBonusModal: FC<Props> = ({ opportunity, isOpen, onClose }) => {
    const [isLoading, setIsLoading] = useState(false)
    const toast = useToast()
    const { setStatusOpportunity, updateOpportunity } = useOpportunity({
        id: opportunity?.id,
    })

    const formik = useFormik({
        initialValues: {
            reason: '',
        },
        validationSchema: Schema,
        onSubmit: async values => {
            try {
                setIsLoading(true)

                await updateOpportunity({
                    opportunity_id: opportunity.id,
                    data: {
                        lost_description: values.reason,
                    },
                })

                await setStatusOpportunity({
                    opportunity_id: opportunity.id,
                    status: 'denied',
                })

                toast({
                    title: 'Éxito',
                    description: 'Oportunidad actualizada con éxito',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })
                formik.resetForm()
                onClose()
            } catch (error) {
                toast({
                    title: 'Error.',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            }

            setIsLoading(false)
        },
    })

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="sm">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader pt={6} pb={4} textAlign="center">
                    <Text color="#474747" fontSize={18} fontWeight={600}>
                        Gestión de bono
                    </Text>
                    <Text
                        color={colors['danger-high']}
                        fontSize={12}
                        fontWeight={400}>
                        Perdida
                    </Text>
                </ModalHeader>

                <form onSubmit={formik.handleSubmit}>
                    <ModalBody color="#474747" p={0}>
                        <Box px="20px" mb={6}>
                            <FormControl
                                mb={4}
                                isInvalid={
                                    formik.errors.reason &&
                                    formik.touched.reason
                                }>
                                <FormLabel fontSize={13} fontWeight={500}>
                                    Motivo
                                </FormLabel>
                                <Textarea
                                    id="reason"
                                    placeholder="Motivo"
                                    rounded="4px"
                                    size="md"
                                    fontSize={13}
                                    fontWeight={500}
                                    value={formik.values.reason}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                <FormErrorMessage>
                                    {formik.errors.reason}
                                </FormErrorMessage>
                            </FormControl>

                            <Text fontSize={13} fontWeight={500}>
                                <chakra.span color="#FD7C04">* </chakra.span>
                                Describa el motivo del porqué este evento está
                                perdido
                            </Text>
                        </Box>

                        <Divider />

                        <Box my={4} display="flex" justifyContent="center">
                            <Button
                                bg="white"
                                borderColor={colors.secondary}
                                borderWidth={1}
                                color={colors.secondary}
                                fontSize={14}
                                fontWeight={500}
                                mr={4}
                                h="32px"
                                w="130px"
                                onClick={onClose}
                                _active={{
                                    transform: 'scale(0.98)',
                                }}
                                _hover={{}}>
                                Atrás
                            </Button>
                            <Button
                                bg={colors.secondary}
                                color="white"
                                fontSize={14}
                                fontWeight={500}
                                isLoading={isLoading}
                                h="32px"
                                type="submit"
                                w="130px"
                                _active={{
                                    transform: 'scale(0.98)',
                                }}
                                _hover={{}}>
                                Aceptar
                            </Button>
                        </Box>
                    </ModalBody>
                </form>
            </ModalContent>
        </Modal>
    )
}
