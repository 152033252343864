// @ts-nocheck to remove "@ts-ignore"
import React, { FC } from 'react'

import { FormikProps } from 'formik'
import {
    FormLabel,
    FormControl,
    Input,
    FormErrorMessage,
    chakra,
    Button,
    Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    Select,
} from '@chakra-ui/react'

import { RepresentativeType } from '@/interfaces'
import { colors } from '@/utils/const'

interface Props {
    formik: FormikProps<FormikValues>
    representativesTypes: RepresentativeType
    segment: number
}

interface FormikValues {
    email: string
    phone: string
    name: string
    representatives: Representative[]
    client_type: string
}

interface Representative {
    name: string
    lastname: string
    second_lastname: string
    dni: string
    phone: string
    email: string
    representative_position: string
    notary_name: string
    notary_lastname: string
    notary_second_lastname: string
    notary_protocol_number: string
    notary_protocol_date: string
}

export const RepresentativeB: FC<Props> = ({
    formik,
    representativesTypes,
    segment,
}) => {
    const addRepresentative = () => {
        let newRepresentatives = formik.values.representatives
        newRepresentatives.push({
            name: '',
            lastname: '',
            second_lastname: '',
            dni: '',
            phone: '',
            email: '',
            representative_position: 'adm_unique',
            notary_name: '',
            notary_lastname: '',
            notary_second_lastname: '',
            notary_protocol_number: '',
            notary_protocol_date: '',
        })
        formik.setFieldValue('representatives', newRepresentatives)
    }

    const removeRepresentative = () => {
        let newRepresentatives = formik.values.representatives
        newRepresentatives.pop()
        formik.setFieldValue('representatives', newRepresentatives)
    }
    return (
        <>
            {formik.values.representatives.length > 1 ? (
                <Accordion w="full" allowMultiple color="black">
                    {formik.values.representatives.map((item, index) => (
                        <AccordionItem borderWidth={1} rounded={4} key={index}>
                            <h2>
                                <AccordionButton bg={colors['gray-soft-30']}>
                                    <Box flex="1" textAlign="left">
                                        Representante {index + 1}
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <FormControl mt={4}>
                                    <FormLabel fontSize={13} fontWeight={700}>
                                        Cargo del representante
                                    </FormLabel>

                                    <Select
                                        id={`representatives[${index}].representative_position`}
                                        color="#4F4F4F"
                                        isDisabled={index === 0 ? true : false}
                                        value={
                                            formik.values.representatives[index]
                                                .representative_position
                                        }
                                        fontSize={13}
                                        onChange={formik.handleChange}
                                        rounded="md"
                                        size="xs">
                                        {Object.values(
                                            representativesTypes,
                                        ).map((item, index) => (
                                            <option
                                                key={index}
                                                value={item.name}>
                                                {item.label}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl
                                    my={4}
                                    isInvalid={
                                        Array.isArray(
                                            formik.errors.representatives,
                                        ) &&
                                        formik.errors?.representatives[index]
                                            ?.name &&
                                        Array.isArray(
                                            formik.touched.representatives,
                                        ) &&
                                        formik.touched.representatives[index]
                                            ?.name
                                    }>
                                    <FormLabel fontSize={13} fontWeight={700}>
                                        Nombre del representante{' '}
                                        <chakra.span
                                            style={{ color: '#FD7C04' }}>
                                            *
                                        </chakra.span>
                                    </FormLabel>
                                    <Input
                                        id={`representatives[${index}].name`}
                                        placeholder="Nombre"
                                        rounded="4px"
                                        size="xs"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={
                                            formik.values.representatives[index]
                                                .name
                                        }
                                    />
                                    {Array.isArray(
                                        formik.errors.representatives,
                                    ) && (
                                        <FormErrorMessage>
                                            {
                                                formik.errors.representatives[
                                                    index
                                                ]?.name
                                            }
                                        </FormErrorMessage>
                                    )}
                                </FormControl>

                                <FormControl
                                    my={4}
                                    isInvalid={
                                        Array.isArray(
                                            formik.errors.representatives,
                                        ) &&
                                        formik.errors?.representatives[index]
                                            ?.lastname &&
                                        Array.isArray(
                                            formik.touched.representatives,
                                        ) &&
                                        formik.touched.representatives[index]
                                            ?.lastname
                                    }>
                                    <FormLabel fontSize={13} fontWeight={700}>
                                        Primer apellido{' '}
                                        <chakra.span
                                            style={{ color: '#FD7C04' }}>
                                            *
                                        </chakra.span>
                                    </FormLabel>
                                    <Input
                                        id={`representatives[${index}].lastname`}
                                        placeholder="Primer apellido"
                                        rounded="4px"
                                        size="xs"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={
                                            formik.values.representatives[index]
                                                .lastname
                                        }
                                    />
                                    {Array.isArray(
                                        formik.errors.representatives,
                                    ) && (
                                        <FormErrorMessage>
                                            {
                                                formik.errors.representatives[
                                                    index
                                                ]?.lastname
                                            }
                                        </FormErrorMessage>
                                    )}
                                </FormControl>

                                <FormControl my={4}>
                                    <FormLabel fontSize={13} fontWeight={700}>
                                        Segundo apellido{' '}
                                    </FormLabel>
                                    <Input
                                        id={`representatives[${index}].second_lastname`}
                                        placeholder="Segundo apellido"
                                        rounded="4px"
                                        size="xs"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={
                                            formik.values.representatives[index]
                                                .second_lastname
                                        }
                                    />
                                </FormControl>

                                <FormControl
                                    mb={4}
                                    isInvalid={
                                        Array.isArray(
                                            formik.errors.representatives,
                                        ) &&
                                        formik.errors?.representatives[index]
                                            ?.dni &&
                                        Array.isArray(
                                            formik.touched.representatives,
                                        ) &&
                                        formik.touched.representatives[index]
                                            ?.dni
                                    }>
                                    <FormLabel fontSize={13} fontWeight={700}>
                                        NIF{' '}
                                        <chakra.span
                                            style={{ color: '#FD7C04' }}>
                                            *
                                        </chakra.span>
                                    </FormLabel>
                                    <Input
                                        id={`representatives[${index}].dni`}
                                        placeholder="NIF"
                                        rounded="4px"
                                        size="xs"
                                        onChange={formik.handleChange}
                                        value={
                                            formik.values.representatives[index]
                                                .dni
                                        }
                                    />
                                    {Array.isArray(
                                        formik.errors.representatives,
                                    ) && (
                                        <FormErrorMessage>
                                            {
                                                formik.errors.representatives[
                                                    index
                                                ]?.dni
                                            }
                                        </FormErrorMessage>
                                    )}
                                </FormControl>

                                {segment === 1 && (
                                    <>
                                        <FormControl
                                            my={4}
                                            isInvalid={
                                                Array.isArray(
                                                    formik.errors
                                                        .representatives,
                                                ) &&
                                                formik.errors?.representatives[
                                                    index
                                                ]?.phone &&
                                                Array.isArray(
                                                    formik.touched
                                                        .representatives,
                                                ) &&
                                                formik.touched.representatives[
                                                    index
                                                ].phone
                                            }>
                                            <FormLabel
                                                fontSize={13}
                                                fontWeight={700}>
                                                Teléfono
                                                <chakra.span
                                                    style={{
                                                        color: '#FD7C04',
                                                    }}>
                                                    {' '}
                                                    *
                                                </chakra.span>
                                            </FormLabel>
                                            <Input
                                                id={`representatives[${index}].phone`}
                                                placeholder="Teléfono"
                                                rounded="4px"
                                                size="xs"
                                                onChange={formik.handleChange}
                                                value={
                                                    formik.values
                                                        .representatives[index]
                                                        .phone
                                                }
                                            />
                                            {Array.isArray(
                                                formik.errors.representatives,
                                            ) && (
                                                <FormErrorMessage>
                                                    {
                                                        formik.errors
                                                            .representatives[
                                                            index
                                                        ]?.phone
                                                    }
                                                </FormErrorMessage>
                                            )}
                                        </FormControl>

                                        <FormControl
                                            mb={4}
                                            isInvalid={
                                                Array.isArray(
                                                    formik.errors
                                                        .representatives,
                                                ) &&
                                                formik.errors?.representatives[
                                                    index
                                                ]?.email &&
                                                Array.isArray(
                                                    formik.touched
                                                        .representatives,
                                                ) &&
                                                formik.touched.representatives[
                                                    index
                                                ].email
                                            }>
                                            <FormLabel
                                                fontSize={13}
                                                fontWeight={700}>
                                                Correo electrónico
                                                <chakra.span
                                                    style={{
                                                        color: '#FD7C04',
                                                    }}>
                                                    {' '}
                                                    *
                                                </chakra.span>
                                            </FormLabel>
                                            <Input
                                                id={`representatives[${index}].email`}
                                                placeholder="Correo electrónico"
                                                rounded="4px"
                                                size="xs"
                                                onChange={formik.handleChange}
                                                value={
                                                    formik.values
                                                        .representatives[index]
                                                        .email
                                                }
                                            />
                                            {Array.isArray(
                                                formik.errors.representatives,
                                            ) && (
                                                <FormErrorMessage>
                                                    {
                                                        formik.errors
                                                            .representatives[
                                                            index
                                                        ]?.email
                                                    }
                                                </FormErrorMessage>
                                            )}
                                        </FormControl>

                                        {index === 0 && (
                                            <>
                                                <FormControl
                                                    color="#4F4F4F"
                                                    my={4}
                                                    isInvalid={
                                                        Array.isArray(
                                                            formik.errors
                                                                .representatives,
                                                        ) &&
                                                        formik.errors
                                                            ?.representatives[
                                                            index
                                                        ]?.notary_name &&
                                                        Array.isArray(
                                                            formik.touched
                                                                .representatives,
                                                        ) &&
                                                        formik.touched
                                                            .representatives[
                                                            index
                                                        ].notary_name
                                                    }>
                                                    <FormLabel
                                                        fontSize={13}
                                                        fontWeight={700}>
                                                        Nombre del notario
                                                        <chakra.span
                                                            style={{
                                                                color:
                                                                    '#FD7C04',
                                                            }}>
                                                            *
                                                        </chakra.span>
                                                    </FormLabel>
                                                    <Input
                                                        id={`representatives[${index}].notary_name`}
                                                        placeholder="Nombre del notario"
                                                        rounded="4px"
                                                        size="xs"
                                                        onChange={
                                                            formik.handleChange
                                                        }
                                                        onBlur={
                                                            formik.handleBlur
                                                        }
                                                        value={
                                                            formik.values
                                                                .representatives[
                                                                index
                                                            ].notary_name
                                                        }
                                                    />
                                                    {Array.isArray(
                                                        formik.errors
                                                            .representatives,
                                                    ) && (
                                                        <FormErrorMessage>
                                                            {
                                                                formik.errors
                                                                    .representatives[
                                                                    index
                                                                ]?.notary_name
                                                            }
                                                        </FormErrorMessage>
                                                    )}
                                                </FormControl>

                                                <FormControl
                                                    color="#4F4F4F"
                                                    my={4}
                                                    isInvalid={
                                                        Array.isArray(
                                                            formik.errors
                                                                .representatives,
                                                        ) &&
                                                        formik.errors
                                                            ?.representatives[
                                                            index
                                                        ]?.notary_lastname &&
                                                        Array.isArray(
                                                            formik.touched
                                                                .representatives,
                                                        ) &&
                                                        formik.touched
                                                            .representatives[
                                                            index
                                                        ].notary_lastname
                                                    }>
                                                    <FormLabel
                                                        fontSize={13}
                                                        fontWeight={700}>
                                                        Primer apellido del
                                                        notario{' '}
                                                        <chakra.span
                                                            style={{
                                                                color:
                                                                    '#FD7C04',
                                                            }}>
                                                            *
                                                        </chakra.span>
                                                    </FormLabel>
                                                    <Input
                                                        id={`representatives[${index}].notary_lastname`}
                                                        placeholder="Primer apellido del notario"
                                                        rounded="4px"
                                                        size="xs"
                                                        onChange={
                                                            formik.handleChange
                                                        }
                                                        onBlur={
                                                            formik.handleBlur
                                                        }
                                                        value={
                                                            formik.values
                                                                .representatives[
                                                                index
                                                            ].notary_lastname
                                                        }
                                                    />
                                                    {Array.isArray(
                                                        formik.errors
                                                            .representatives,
                                                    ) && (
                                                        <FormErrorMessage>
                                                            {
                                                                formik.errors
                                                                    .representatives[
                                                                    index
                                                                ]
                                                                    ?.notary_lastname
                                                            }
                                                        </FormErrorMessage>
                                                    )}
                                                </FormControl>

                                                <FormControl
                                                    color="#4F4F4F"
                                                    my={4}>
                                                    <FormLabel
                                                        fontSize={13}
                                                        fontWeight={700}>
                                                        Segundo apellido del
                                                        notario{' '}
                                                    </FormLabel>
                                                    <Input
                                                        id={`representatives[${index}].notary_second_lastname`}
                                                        placeholder="Segundo apellido del notario"
                                                        rounded="4px"
                                                        size="xs"
                                                        onChange={
                                                            formik.handleChange
                                                        }
                                                        onBlur={
                                                            formik.handleBlur
                                                        }
                                                        value={
                                                            formik.values
                                                                .representatives[
                                                                index
                                                            ]
                                                                .notary_second_lastname
                                                        }
                                                    />
                                                </FormControl>

                                                <FormControl
                                                    color="#4F4F4F"
                                                    my={4}
                                                    isInvalid={
                                                        Array.isArray(
                                                            formik.errors
                                                                .representatives,
                                                        ) &&
                                                        formik.errors
                                                            ?.representatives[
                                                            index
                                                        ]
                                                            ?.notary_protocol_number &&
                                                        Array.isArray(
                                                            formik.touched
                                                                .representatives,
                                                        ) &&
                                                        formik.touched
                                                            .representatives[
                                                            index
                                                        ].notary_protocol_number
                                                    }>
                                                    <FormLabel
                                                        fontSize={13}
                                                        fontWeight={700}>
                                                        Número de Protocolo
                                                        <chakra.span
                                                            style={{
                                                                color:
                                                                    '#FD7C04',
                                                            }}>
                                                            *
                                                        </chakra.span>
                                                    </FormLabel>
                                                    <Input
                                                        id={`representatives[${index}].notary_protocol_number`}
                                                        placeholder="Número de Protocolo"
                                                        rounded="4px"
                                                        size="xs"
                                                        onChange={
                                                            formik.handleChange
                                                        }
                                                        onBlur={
                                                            formik.handleBlur
                                                        }
                                                        value={
                                                            formik.values
                                                                .representatives[
                                                                index
                                                            ]
                                                                .notary_protocol_number
                                                        }
                                                    />
                                                    {Array.isArray(
                                                        formik.errors
                                                            .representatives,
                                                    ) && (
                                                        <FormErrorMessage>
                                                            {
                                                                formik.errors
                                                                    .representatives[
                                                                    index
                                                                ]
                                                                    ?.notary_protocol_number
                                                            }
                                                        </FormErrorMessage>
                                                    )}
                                                </FormControl>

                                                <FormControl
                                                    color="#4F4F4F"
                                                    my={4}
                                                    isInvalid={
                                                        Array.isArray(
                                                            formik.errors
                                                                .representatives,
                                                        ) &&
                                                        formik.errors
                                                            ?.representatives[
                                                            index
                                                        ]
                                                            ?.notary_protocol_date &&
                                                        Array.isArray(
                                                            formik.touched
                                                                .representatives,
                                                        ) &&
                                                        formik.touched
                                                            .representatives[
                                                            index
                                                        ].notary_protocol_date
                                                    }>
                                                    <FormLabel
                                                        fontSize={13}
                                                        fontWeight={700}>
                                                        Fecha Autorización
                                                        Protocolo
                                                        <chakra.span
                                                            style={{
                                                                color:
                                                                    '#FD7C04',
                                                            }}>
                                                            *
                                                        </chakra.span>
                                                    </FormLabel>
                                                    <Input
                                                        id={`representatives[${index}].notary_protocol_date`}
                                                        rounded="4px"
                                                        size="xs"
                                                        type="date"
                                                        onChange={
                                                            formik.handleChange
                                                        }
                                                        value={
                                                            formik.values
                                                                .representatives[
                                                                index
                                                            ]
                                                                .notary_protocol_date
                                                        }
                                                    />
                                                    {Array.isArray(
                                                        formik.errors
                                                            .representatives,
                                                    ) && (
                                                        <FormErrorMessage>
                                                            {}
                                                            {
                                                                formik.errors
                                                                    .representatives[
                                                                    index
                                                                ]
                                                                    ?.notary_protocol_date
                                                            }
                                                        </FormErrorMessage>
                                                    )}
                                                </FormControl>
                                            </>
                                        )}
                                    </>
                                )}
                            </AccordionPanel>
                        </AccordionItem>
                    ))}
                </Accordion>
            ) : (
                <Box mt={8} w="90%" color="black">
                    <FormControl
                        my={4}
                        isInvalid={
                            Array.isArray(formik.errors.representatives) &&
                            formik.errors?.representatives[0]?.name &&
                            Array.isArray(formik.touched.representatives) &&
                            formik.touched.representatives[0].name
                        }>
                        <FormLabel fontSize={13} fontWeight={700}>
                            Nombre del representante{' '}
                            <chakra.span style={{ color: '#FD7C04' }}>
                                *
                            </chakra.span>
                        </FormLabel>
                        <Input
                            id="representatives[0].name"
                            placeholder="Nombre"
                            rounded="4px"
                            size="xs"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.representatives[0].name}
                        />
                        {Array.isArray(formik.errors.representatives) && (
                            <FormErrorMessage>
                                {formik.errors.representatives.map(
                                    error => error.name,
                                )}
                            </FormErrorMessage>
                        )}
                    </FormControl>

                    <FormControl
                        my={4}
                        isInvalid={
                            Array.isArray(formik.errors.representatives) &&
                            formik.errors?.representatives[0]?.lastname &&
                            Array.isArray(formik.touched.representatives) &&
                            formik.touched.representatives[0].lastname
                        }>
                        <FormLabel fontSize={13} fontWeight={700}>
                            Primer apellido del representante{' '}
                            <chakra.span style={{ color: '#FD7C04' }}>
                                *
                            </chakra.span>
                        </FormLabel>
                        <Input
                            id="representatives[0].lastname"
                            placeholder="Primer apellido"
                            rounded="4px"
                            size="xs"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.representatives[0].lastname}
                        />
                        {Array.isArray(formik.errors.representatives) && (
                            <FormErrorMessage>
                                {formik.errors.representatives.map(
                                    error => error.lastname,
                                )}
                            </FormErrorMessage>
                        )}
                    </FormControl>

                    <FormControl my={4}>
                        <FormLabel fontSize={13} fontWeight={700}>
                            Segundo apellido del representante
                        </FormLabel>
                        <Input
                            id="representatives[0].second_lastname"
                            placeholder="Segundo apellido"
                            rounded="4px"
                            size="xs"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={
                                formik.values.representatives[0].second_lastname
                            }
                        />
                    </FormControl>

                    <FormControl
                        my={4}
                        isInvalid={
                            Array.isArray(formik.errors.representatives) &&
                            formik.errors?.representatives[0]?.dni &&
                            Array.isArray(formik.touched.representatives) &&
                            formik.touched.representatives[0]?.dni
                        }>
                        <FormLabel fontSize={13} fontWeight={700}>
                            NIF{' '}
                            <chakra.span style={{ color: '#FD7C04' }}>
                                *
                            </chakra.span>
                        </FormLabel>
                        <Input
                            id="representatives[0].dni"
                            placeholder="NIF"
                            rounded="4px"
                            size="xs"
                            onChange={formik.handleChange}
                            value={formik.values.representatives[0].dni}
                        />
                        {Array.isArray(formik.errors.representatives) && (
                            <FormErrorMessage>
                                {formik.errors.representatives.map(
                                    error => error.dni,
                                )}
                            </FormErrorMessage>
                        )}
                    </FormControl>

                    {segment === 1 && (
                        <>
                            <FormControl
                                my={4}
                                isInvalid={
                                    Array.isArray(
                                        formik.errors.representatives,
                                    ) &&
                                    formik.errors?.representatives[0]?.phone &&
                                    Array.isArray(
                                        formik.touched.representatives,
                                    ) &&
                                    formik.touched.representatives[0].phone
                                }>
                                <FormLabel fontSize={13} fontWeight={700}>
                                    Teléfono
                                    <chakra.span style={{ color: '#FD7C04' }}>
                                        {' '}
                                        *
                                    </chakra.span>
                                </FormLabel>
                                <Input
                                    id="representatives[0].phone"
                                    placeholder="Teléfono"
                                    rounded="4px"
                                    size="xs"
                                    onChange={formik.handleChange}
                                    value={
                                        formik.values.representatives[0].phone
                                    }
                                />
                                {Array.isArray(
                                    formik.errors.representatives,
                                ) && (
                                    <FormErrorMessage>
                                        {formik.errors.representatives.map(
                                            error => error.phone,
                                        )}
                                    </FormErrorMessage>
                                )}
                            </FormControl>

                            <FormControl
                                mb={4}
                                isInvalid={
                                    Array.isArray(
                                        formik.errors.representatives,
                                    ) &&
                                    formik.errors?.representatives[0]?.email &&
                                    Array.isArray(
                                        formik.touched.representatives,
                                    ) &&
                                    formik.touched.representatives[0].email
                                }>
                                <FormLabel fontSize={13} fontWeight={700}>
                                    Correo electrónico
                                    <chakra.span style={{ color: '#FD7C04' }}>
                                        {' '}
                                        *
                                    </chakra.span>
                                </FormLabel>
                                <Input
                                    id="representatives[0].email"
                                    placeholder="Correo electrónico"
                                    rounded="4px"
                                    size="xs"
                                    onChange={formik.handleChange}
                                    value={
                                        formik.values.representatives[0].email
                                    }
                                />
                                {Array.isArray(
                                    formik.errors.representatives,
                                ) && (
                                    <FormErrorMessage>
                                        {formik.errors.representatives.map(
                                            error => error.email,
                                        )}
                                    </FormErrorMessage>
                                )}
                            </FormControl>

                            <FormControl
                                color="#4F4F4F"
                                my={4}
                                isInvalid={
                                    Array.isArray(
                                        formik.errors.representatives,
                                    ) &&
                                    formik.errors?.representatives[0]
                                        ?.notary_name &&
                                    Array.isArray(
                                        formik.touched.representatives,
                                    ) &&
                                    formik.touched.representatives[0]
                                        .notary_name
                                }>
                                <FormLabel fontSize={13} fontWeight={700}>
                                    Nombre del notario
                                    <chakra.span style={{ color: '#FD7C04' }}>
                                        *
                                    </chakra.span>
                                </FormLabel>
                                <Input
                                    id="representatives[0].notary_name"
                                    placeholder="Nombre del notario"
                                    rounded="4px"
                                    size="xs"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={
                                        formik.values.representatives[0]
                                            .notary_name
                                    }
                                />
                                {Array.isArray(
                                    formik.errors.representatives,
                                ) && (
                                    <FormErrorMessage>
                                        {formik.errors.representatives.map(
                                            error => error.notary_name,
                                        )}
                                    </FormErrorMessage>
                                )}
                            </FormControl>

                            <FormControl
                                color="#4F4F4F"
                                my={4}
                                isInvalid={
                                    Array.isArray(
                                        formik.errors.representatives,
                                    ) &&
                                    formik.errors?.representatives[0]
                                        ?.notary_lastname &&
                                    Array.isArray(
                                        formik.touched.representatives,
                                    ) &&
                                    formik.touched.representatives[0]
                                        .notary_lastname
                                }>
                                <FormLabel fontSize={13} fontWeight={700}>
                                    Primer apellido del notario{' '}
                                    <chakra.span style={{ color: '#FD7C04' }}>
                                        *
                                    </chakra.span>
                                </FormLabel>
                                <Input
                                    id="representatives[0].notary_lastname"
                                    placeholder="Primer apellido del notario"
                                    rounded="4px"
                                    size="xs"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={
                                        formik.values.representatives[0]
                                            .notary_lastname
                                    }
                                />
                                {Array.isArray(
                                    formik.errors.representatives,
                                ) && (
                                    <FormErrorMessage>
                                        {formik.errors.representatives.map(
                                            error => error.notary_lastname,
                                        )}
                                    </FormErrorMessage>
                                )}
                            </FormControl>

                            <FormControl color="#4F4F4F" my={4}>
                                <FormLabel fontSize={13} fontWeight={700}>
                                    Segundo apellido del notario{' '}
                                </FormLabel>
                                <Input
                                    id="representatives[0].notary_second_lastname"
                                    placeholder="Segundo apellido del notario"
                                    rounded="4px"
                                    size="xs"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={
                                        formik.values.representatives[0]
                                            .notary_second_lastname
                                    }
                                />
                            </FormControl>

                            <FormControl
                                color="#4F4F4F"
                                my={4}
                                isInvalid={
                                    Array.isArray(
                                        formik.errors.representatives,
                                    ) &&
                                    formik.errors?.representatives[0]
                                        ?.notary_protocol_number &&
                                    Array.isArray(
                                        formik.touched.representatives,
                                    ) &&
                                    formik.touched.representatives[0]
                                        .notary_protocol_number
                                }>
                                <FormLabel fontSize={13} fontWeight={700}>
                                    Número de Protocolo
                                    <chakra.span style={{ color: '#FD7C04' }}>
                                        *
                                    </chakra.span>
                                </FormLabel>
                                <Input
                                    id="representatives[0].notary_protocol_number"
                                    placeholder="Número de Protocolo"
                                    rounded="4px"
                                    size="xs"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={
                                        formik.values.representatives[0]
                                            .notary_protocol_number
                                    }
                                />
                                {Array.isArray(
                                    formik.errors.representatives,
                                ) && (
                                    <FormErrorMessage>
                                        {formik.errors.representatives.map(
                                            error =>
                                                error.notary_protocol_number,
                                        )}
                                    </FormErrorMessage>
                                )}
                            </FormControl>

                            <FormControl
                                color="#4F4F4F"
                                my={4}
                                isInvalid={
                                    Array.isArray(
                                        formik.errors.representatives,
                                    ) &&
                                    formik.errors?.representatives[0]
                                        ?.notary_protocol_date &&
                                    Array.isArray(
                                        formik.touched.representatives,
                                    ) &&
                                    formik.touched.representatives[0]
                                        .notary_protocol_date
                                }>
                                <FormLabel fontSize={13} fontWeight={700}>
                                    Fecha Autorización Protocolo
                                    <chakra.span style={{ color: '#FD7C04' }}>
                                        *
                                    </chakra.span>
                                </FormLabel>
                                <Input
                                    id="representatives[0].notary_protocol_date"
                                    rounded="4px"
                                    size="xs"
                                    type="date"
                                    onChange={formik.handleChange}
                                    value={
                                        formik.values.representatives[0]
                                            .notary_protocol_date
                                    }
                                />
                                {Array.isArray(
                                    formik.errors.representatives,
                                ) && (
                                    <FormErrorMessage>
                                        {formik.errors.representatives.map(
                                            error => error.notary_protocol_date,
                                        )}
                                    </FormErrorMessage>
                                )}
                            </FormControl>
                        </>
                    )}
                </Box>
            )}
            <Box mt={8} w="full" display="flex" justifyContent="center">
                <Button
                    color={colors.secondary}
                    fontSize={13}
                    fontWeight={700}
                    variant="link"
                    onClick={addRepresentative}
                    _active={{}}
                    _hover={{}}>
                    + Agregar otro representante
                </Button>
            </Box>

            {formik.values.representatives.length > 1 && (
                <Box mt={4} w="full" display="flex" justifyContent="center">
                    <Button
                        color={colors['danger-high']}
                        fontSize={13}
                        fontWeight={700}
                        variant="link"
                        onClick={removeRepresentative}
                        _active={{}}
                        _hover={{}}>
                        - Eliminar un representante
                    </Button>
                </Box>
            )}
        </>
    )
}
