import React, { FC, useState } from 'react'

import {
    ListItem,
    Tag,
    Box,
    Text,
    UnorderedList,
    IconButton,
    List,
} from '@chakra-ui/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { colors } from '@/utils/const'

interface Props {
    communications: Communication[]
}

interface Communication {
    created_at: string
    index: number
    user: string
    description: string
    properties: string
}

const ITEMS_PER_PAGE = 5

export const HistorialList: FC<Props> = ({ communications }) => {
    const [communicationsToShow, setCommunicationsToShow] = useState(
        [...communications].splice(0, ITEMS_PER_PAGE),
    )

    const [currentPage, setCurrentPage] = useState(0)
    const paginationArray = [
        ...Array(Math.ceil(communications.length / ITEMS_PER_PAGE)),
    ].map((item, index) => index + 1)

    const nextHandler = () => {
        const totalElements = communications.length
        const nextPage = currentPage + 1
        const firstIndex = nextPage * ITEMS_PER_PAGE

        if (firstIndex >= totalElements) return

        setCommunicationsToShow(
            [...communications].splice(firstIndex, ITEMS_PER_PAGE),
        )
        setCurrentPage(nextPage)
    }

    const prevHandler = () => {
        const prevPage = currentPage - 1

        if (prevPage < 0) return
        const firstIndex = prevPage * ITEMS_PER_PAGE

        setCommunicationsToShow(
            [...communications].splice(firstIndex, ITEMS_PER_PAGE),
        )
        setCurrentPage(prevPage)
    }

    const handlePageIndex = (index: number) => {
        const firstIndex = (index - 1) * ITEMS_PER_PAGE

        setCommunicationsToShow(
            [...communications].splice(firstIndex, ITEMS_PER_PAGE),
        )
        setCurrentPage(index - 1)
    }

    return (
        <Box>
            <Box minH="190px">
                <UnorderedList>
                    {communicationsToShow.map((item, index) => (
                        <CommunicationList
                            created_at={item.created_at}
                            description={item.description}
                            index={index}
                            key={index}
                            properties={item.properties}
                            user={item.user}
                        />
                    ))}
                </UnorderedList>
            </Box>

            <Box display="flex" justifyContent="end">
                <IconButton
                    aria-label={''}
                    isDisabled={currentPage === 0}
                    onClick={prevHandler}
                    bg={colors['secondary-soft-hover']}
                    color={colors.secondary}
                    cursor="pointer"
                    h={6}
                    icon={<ChevronLeftIcon boxSize={6} />}
                    rounded="full"
                />

                <List
                    bg={colors['secondary-soft-hover']}
                    color={'#6E6B7B'}
                    display={'flex'}
                    fontSize={10}
                    justifyContent="space-between"
                    mx={2}
                    px={4}
                    rounded="full">
                    {paginationArray.map((item, index) => {
                        if (index === currentPage) {
                            return (
                                <ListItem
                                    key={item}
                                    bg={colors.secondary}
                                    color="white"
                                    display="flex"
                                    fontWeight={700}
                                    justifyContent={'center'}
                                    p={1}
                                    rounded={'full'}
                                    w={'24px'}>
                                    {item}
                                </ListItem>
                            )
                        }

                        return (
                            <ListItem
                                cursor="pointer"
                                key={item}
                                p={1}
                                onClick={() => {
                                    handlePageIndex(item)
                                }}>
                                {item}
                            </ListItem>
                        )
                    })}
                </List>

                <IconButton
                    aria-label={''}
                    isDisabled={
                        (currentPage + 1) * ITEMS_PER_PAGE >=
                        communications.length
                    }
                    onClick={nextHandler}
                    bg={colors['secondary-soft-hover']}
                    color={colors.secondary}
                    cursor="pointer"
                    h={6}
                    icon={<ChevronRightIcon boxSize={6} />}
                    rounded="full"
                />
            </Box>
        </Box>
    )
}

const CommunicationList: FC<Communication> = ({
    created_at,
    index,
    user,
    description,
    properties,
}) => {
    const { via, title, error } = JSON.parse(properties)

    return (
        <ListItem key={index} mb={4}>
            <Box display="flex" mb={1} color="#878787">
                <Text mr={2}>
                    {new Date(created_at).toLocaleString('es-ES', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                        hour12: false,
                    })}
                </Text>
                <Text color="#6C6C6C" mr={2}>
                    {title}
                </Text>
                {user && (
                    <Text color="#6C6C6C" fontSize={10} fontWeight={400}>
                        - {user}
                    </Text>
                )}
            </Box>
            <Text color="#878787" mb={1}>
                {description}
            </Text>

            {error && (
                <>
                    {typeof error === 'string' ? (
                        <Text color="#878787" mb={1}>
                            <b>Mensaje de error:</b> {error}
                        </Text>
                    ) : (
                        <Box color="#878787">
                            <Text mb={1}>
                                <b>Código de error:</b> {error.code}
                            </Text>
                            <Text mb={1}>
                                <b>Mensaje de error:</b> {error.message}
                            </Text>
                        </Box>
                    )}
                </>
            )}

            {via === 'email' ? (
                <Tag variant="subtle" colorScheme="green" mt={2}>
                    Email
                </Tag>
            ) : (
                <Tag variant="subtle" colorScheme="cyan" mt={2}>
                    SMS
                </Tag>
            )}
        </ListItem>
    )
}
