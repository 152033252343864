import React, { FC, useEffect, useState } from 'react'

import { useFormik } from 'formik'
import { useSWRConfig } from 'swr'
import * as Yup from 'yup'
import {
    Box,
    Modal,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useToast,
    ModalBody,
    FormControl,
    FormLabel,
    Divider,
    Button,
    ModalFooter,
    Accordion,
    AccordionIcon,
    AccordionButton,
    AccordionPanel,
    Select,
    AccordionItem,
    Input,
    Spinner,
    Text,
} from '@chakra-ui/react'
import { colors } from '@/utils/const'
import { Client, Solution } from '@/interfaces'
import { getPackageManagment, getProductManagment } from '@/hooks/solutions'
import { useClient } from '@/hooks/client'

const Schema = Yup.object().shape({
    products: Yup.array().of(
        Yup.object({
            solution_managment_id: Yup.string().required(
                'El nombre de la solución es requerido',
            ),
            product_managment_id: Yup.string().required(
                'El nombre del producto es requerido',
            ),
            package_managment_id: Yup.string().required(
                'El nombre del paquete es requerido',
            ),
            price: Yup.string().required(
                'El precio de la solución es requerido',
            ),
        }),
    ),
})

interface Props {
    client: Client
    isOpen: boolean
    segment: number | string
    opportunity_id: number | string
    onClose: () => void
    solutions: Solution[]
}

export const UpdateProducts: FC<Props> = ({
    client,
    solutions,
    segment,
    opportunity_id,
    isOpen,
    onClose,
}) => {
    const { updateClient } = useClient({ client_id: client?.id })
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingProduct, setIsLoadingProduct] = useState(false)
    const { mutate } = useSWRConfig()
    const toast = useToast()

    const [solutionsSelect, setSolutionsSelect] = useState([
        {
            solutions: [],
            products: [],
            packages: [],
        },
    ])
    const [defaultOpenAccordion, setDefaultOpenAccordion] = useState(0)
    let disableSave = false

    const formik = useFormik({
        initialValues: {
            products: client?.products
                ? client.products.map(item => {
                      return {
                          solution_managment_id:
                              item.solution_managment?.id || '',
                          product_managment_id:
                              item.product_managment?.id || '',
                          package_managment_id:
                              item.package_managment?.id || '',
                          price: item.price || '',
                      }
                  })
                : [
                      {
                          solution_managment_id: '',
                          product_managment_id: '',
                          package_managment_id: '',
                          price: '',
                      },
                  ],
        },
        validationSchema: Schema,
        onSubmit: async values => {
            try {
                setIsLoading(true)

                await updateClient({
                    client_id: client?.id,
                    data: {
                        products: values.products,
                    },
                })

                toast({
                    title: 'Exito',
                    description: 'Productos actualizadas',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })

                mutate(`/api/opportunity/${opportunity_id}`)
                formik.resetForm()
                onClose()
            } catch (error) {
                toast({
                    title: 'Error.',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
                setIsLoading(false)
            }

            setIsLoading(false)
        },
    })

    useEffect(() => {
        const setInitialSelects = async () => {
            const promises = client.products.map(async (item, index) => {
                const productsManagment = await getProductManagment({
                    solution_managment_id: item.solution_managment.id,
                })

                const packageManagment = await getPackageManagment({
                    product_managment_id: item.product_managment.id,
                })

                return {
                    index,
                    solutions,
                    productsManagment,
                    packageManagment,
                }
            })
            let solutionSelect = [...Array(promises.length)]

            for await (const res of await Promise.all(promises)) {
                solutionSelect[res.index] = {
                    solutions: res.solutions,
                    products: res.productsManagment,
                    packages: res.packageManagment,
                }
            }

            setSolutionsSelect(solutionSelect)
        }

        if (client?.products.length > 0) {
            setInitialSelects()
        } else {
            setSolutionsSelect([
                {
                    solutions,
                    products: [],
                    packages: [],
                },
            ])
        }
    }, [])

    const addProduct = async () => {
        setSolutionsSelect([
            ...solutionsSelect,
            {
                solutions,
                products: [],
                packages: [],
            },
        ])

        formik.setFieldValue('products', [
            ...formik.values.products,
            {
                solution_managment_id: '',
                product_managment_id: '',
                package_managment_id: '',
                price: '',
            },
        ])
    }

    const removeProduct = () => {
        let newProducts = formik.values.products
        newProducts.pop()
        formik.setFieldValue('products', newProducts)
    }

    const simpleChangeSolution = async e => {
        if (isNaN(parseInt(e.target.value))) return null

        setDefaultOpenAccordion(0)

        formik.setFieldValue(
            'products[0].solution_managment_id',
            e.target.value,
        )
        formik.setFieldValue('products[0].product_managment_id', '')
        formik.setFieldValue('products[0].package_managment_id', '')
        formik.setFieldValue('products[0].price', '')

        try {
            setIsLoadingProduct(true)

            const productsManagment = await getProductManagment({
                solution_managment_id: parseInt(e.target.value),
            })

            setSolutionsSelect([
                {
                    solutions,
                    products: productsManagment,
                    packages: [],
                },
            ])
        } catch (error) {
            toast({
                title: 'Error.',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }
        setIsLoadingProduct(false)
    }

    const simpleChangeProduct = async e => {
        if (isNaN(parseInt(e.target.value))) return null

        setDefaultOpenAccordion(0)

        formik.setFieldValue('products[0].product_managment_id', e.target.value)
        formik.setFieldValue('products[0].package_managment_id', '')
        formik.setFieldValue('products[0].price', '')

        try {
            setIsLoadingProduct(true)

            const packageManagment = await getPackageManagment({
                product_managment_id: parseInt(e.target.value),
            })

            setSolutionsSelect([
                {
                    solutions,
                    products: [...solutionsSelect[0].products],
                    packages: packageManagment,
                },
            ])
        } catch (error) {
            toast({
                title: 'Error.',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }
        setIsLoadingProduct(false)
    }

    const simpleChangePackage = async e => {
        if (isNaN(parseInt(e.target.value))) return null

        setDefaultOpenAccordion(0)

        formik.setFieldValue('products[0].package_managment_id', e.target.value)

        formik.setFieldValue(
            'products[0].price',
            solutionsSelect[0].packages.find(
                item => item.id === parseInt(e.target.value),
            ).price,
        )
    }

    const complexChangeSolution = async (e, index) => {
        if (isNaN(parseInt(e.target.value))) return null

        setDefaultOpenAccordion(index)

        formik.setFieldValue(
            `products[${index}].solution_managment_id`,
            e.target.value,
        )
        formik.setFieldValue(`products[${index}].product_managment_id`, '')
        formik.setFieldValue(`products[${index}].package_managment_id`, '')
        formik.setFieldValue(`products[${index}].price`, '')

        try {
            setIsLoadingProduct(true)

            const productsManagment = await getProductManagment({
                solution_managment_id: parseInt(e.target.value),
            })

            let newSolutionsSelect = [...solutionsSelect]

            newSolutionsSelect[index] = {
                solutions,
                products: productsManagment,
                packages: [],
            }

            setSolutionsSelect(newSolutionsSelect)
        } catch (error) {
            toast({
                title: 'Error.',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }
        setIsLoadingProduct(false)
    }

    const complexChangeProduct = async (e, index) => {
        if (isNaN(parseInt(e.target.value))) return null

        setDefaultOpenAccordion(index)

        formik.setFieldValue(
            `products[${index}].product_managment_id`,
            e.target.value,
        )
        formik.setFieldValue(`products[${index}].package_managment_id`, '')
        formik.setFieldValue(`products[${index}].price`, '')

        try {
            setIsLoadingProduct(true)

            const packageManagment = await getPackageManagment({
                product_managment_id: parseInt(e.target.value),
            })

            let newSolutionsSelect = [...solutionsSelect]

            newSolutionsSelect[index] = {
                solutions,
                products: [...solutionsSelect[index].products],
                packages: packageManagment,
            }

            setSolutionsSelect(newSolutionsSelect)
        } catch (error) {
            toast({
                title: 'Error.',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }
        setIsLoadingProduct(false)
    }

    const complexChangePackage = async (e, index) => {
        if (isNaN(parseInt(e.target.value))) return null

        setDefaultOpenAccordion(index)

        formik.setFieldValue(
            `products[${index}].package_managment_id`,
            e.target.value,
        )

        formik.setFieldValue(
            `products[${index}].price`,
            solutionsSelect[index].packages.find(
                item => item.id === parseInt(e.target.value),
            ).price,
        )
    }

    formik.values.products.forEach(item => {
        if (!item.package_managment_id) disableSave = true
        if (!item.product_managment_id) disableSave = true
        if (!item.solution_managment_id) disableSave = true
        if (!item.price) disableSave = true
    })

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="md"
            closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent>
                <form onSubmit={formik.handleSubmit}>
                    <ModalHeader
                        px={6}
                        pb={2}
                        fontSize={18}
                        fontWeight={400}
                        textAlign="center">
                        Actualizar soluciones
                    </ModalHeader>

                    <ModalBody color="#474747">
                        {isLoadingProduct ? (
                            <Box
                                alignItems="center"
                                display="flex"
                                justifyContent="center"
                                h="300px"
                                w="full">
                                <Spinner
                                    thickness="4px"
                                    speed="0.65s"
                                    emptyColor="gray.200"
                                    color="blue.500"
                                    size="xl"
                                />
                            </Box>
                        ) : (
                            <>
                                {formik.values.products.length > 1 ? (
                                    <Accordion
                                        mt={8}
                                        w="full"
                                        defaultIndex={[defaultOpenAccordion]}
                                        allowMultiple
                                        color="black">
                                        {formik.values.products.map(
                                            (item, index) => (
                                                <AccordionItem
                                                    borderWidth={1}
                                                    rounded={4}
                                                    key={index}>
                                                    <h2>
                                                        <AccordionButton
                                                            bg={
                                                                colors[
                                                                    'gray-soft-30'
                                                                ]
                                                            }>
                                                            <Box
                                                                flex="1"
                                                                textAlign="left">
                                                                Solución -
                                                                Producto -
                                                                Paquete -
                                                                {index + 1}
                                                            </Box>
                                                            <AccordionIcon />
                                                        </AccordionButton>
                                                    </h2>
                                                    <AccordionPanel pb={4}>
                                                        <FormControl mt={4}>
                                                            <FormLabel
                                                                fontSize={13}
                                                                fontWeight={
                                                                    700
                                                                }>
                                                                Solución
                                                            </FormLabel>

                                                            <Select
                                                                id={`products[${index}].solution_managment_id`}
                                                                placeholder="Solución"
                                                                color="#4F4F4F"
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .products[
                                                                        index
                                                                    ]
                                                                        ?.solution_managment_id
                                                                }
                                                                fontSize={13}
                                                                onChange={e =>
                                                                    complexChangeSolution(
                                                                        e,
                                                                        index,
                                                                    )
                                                                }
                                                                rounded="md"
                                                                size="xs">
                                                                {solutionsSelect[
                                                                    index
                                                                ]?.solutions.map(
                                                                    (
                                                                        item,
                                                                        index,
                                                                    ) => {
                                                                        if (
                                                                            !item.is_contable
                                                                        )
                                                                            return null

                                                                        return (
                                                                            <option
                                                                                key={
                                                                                    index
                                                                                }
                                                                                value={
                                                                                    item.id
                                                                                }>
                                                                                {
                                                                                    item.label
                                                                                }
                                                                            </option>
                                                                        )
                                                                    },
                                                                )}
                                                            </Select>
                                                        </FormControl>

                                                        <FormControl mt={4}>
                                                            <FormLabel
                                                                fontSize={13}
                                                                fontWeight={
                                                                    700
                                                                }>
                                                                Producto
                                                            </FormLabel>

                                                            <Select
                                                                id={`products[${index}].product_managment_id`}
                                                                placeholder="Producto"
                                                                color="#4F4F4F"
                                                                isDisabled={
                                                                    solutionsSelect[
                                                                        index
                                                                    ]?.products
                                                                        .length ===
                                                                    0
                                                                }
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .products[
                                                                        index
                                                                    ]
                                                                        ?.product_managment_id
                                                                }
                                                                fontSize={13}
                                                                onChange={e =>
                                                                    complexChangeProduct(
                                                                        e,
                                                                        index,
                                                                    )
                                                                }
                                                                rounded="md"
                                                                size="xs">
                                                                {solutionsSelect[
                                                                    index
                                                                ]?.products.map(
                                                                    (
                                                                        item,
                                                                        index,
                                                                    ) => (
                                                                        <option
                                                                            key={
                                                                                index
                                                                            }
                                                                            value={
                                                                                item.id
                                                                            }>
                                                                            {
                                                                                item.label
                                                                            }
                                                                        </option>
                                                                    ),
                                                                )}
                                                            </Select>
                                                        </FormControl>

                                                        <FormControl mt={4}>
                                                            <FormLabel
                                                                fontSize={13}
                                                                fontWeight={
                                                                    700
                                                                }>
                                                                Paquete
                                                            </FormLabel>

                                                            <Select
                                                                id={`products[${index}].package_managment_id`}
                                                                color="#4F4F4F"
                                                                placeholder="Paquete"
                                                                isDisabled={
                                                                    solutionsSelect[
                                                                        index
                                                                    ]?.packages
                                                                        .length ===
                                                                    0
                                                                }
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .products[
                                                                        index
                                                                    ]
                                                                        ?.package_managment_id
                                                                }
                                                                fontSize={13}
                                                                onChange={e =>
                                                                    complexChangePackage(
                                                                        e,
                                                                        index,
                                                                    )
                                                                }
                                                                rounded="md"
                                                                size="xs">
                                                                {solutionsSelect[
                                                                    index
                                                                ]?.packages.map(
                                                                    (
                                                                        item,
                                                                        index,
                                                                    ) => (
                                                                        <option
                                                                            key={
                                                                                index
                                                                            }
                                                                            value={
                                                                                item.id
                                                                            }>
                                                                            {
                                                                                item.label
                                                                            }
                                                                        </option>
                                                                    ),
                                                                )}
                                                            </Select>
                                                        </FormControl>

                                                        <FormControl mt={4}>
                                                            <FormLabel
                                                                fontSize={13}
                                                                fontWeight={
                                                                    700
                                                                }>
                                                                Precio
                                                            </FormLabel>

                                                            <Input
                                                                id={`products[${index}].price`}
                                                                placeholder="Precio"
                                                                isDisabled={
                                                                    !formik
                                                                        .values
                                                                        .products[
                                                                        index
                                                                    ]
                                                                        .package_managment_id
                                                                }
                                                                color="#4F4F4F"
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .products[
                                                                        index
                                                                    ]?.price
                                                                }
                                                                fontSize={13}
                                                                onChange={
                                                                    formik.handleChange
                                                                }
                                                                rounded="md"
                                                                size="xs"
                                                            />
                                                        </FormControl>

                                                        {formik.values.products[
                                                            index
                                                        ]
                                                            .solution_managment_id &&
                                                            segment && (
                                                                <>
                                                                    <Text
                                                                        mt={4}
                                                                        fontSize={
                                                                            13
                                                                        }
                                                                        fontWeight={
                                                                            700
                                                                        }>
                                                                        Límite
                                                                        por
                                                                        solución:{' '}
                                                                        {
                                                                            solutionsSelect[
                                                                                index
                                                                            ]?.solutions?.find(
                                                                                item =>
                                                                                    item.id ==
                                                                                    formik
                                                                                        .values
                                                                                        .products[
                                                                                        index
                                                                                    ]
                                                                                        .solution_managment_id,
                                                                            )?.[
                                                                                `limit_segment_${segment}`
                                                                            ]
                                                                        }{' '}
                                                                        €
                                                                    </Text>

                                                                    {parseFloat(
                                                                        // @ts-ignore
                                                                        formik
                                                                            .values
                                                                            .products[
                                                                            index
                                                                        ].price,
                                                                    ) >
                                                                        parseFloat(
                                                                            solutionsSelect[
                                                                                index
                                                                            ]?.solutions?.find(
                                                                                item =>
                                                                                    item.id ==
                                                                                    formik
                                                                                        .values
                                                                                        .products[
                                                                                        index
                                                                                    ]
                                                                                        .solution_managment_id,
                                                                            )?.[
                                                                                `limit_segment_${segment}`
                                                                            ],
                                                                        ) && (
                                                                        <Text
                                                                            mt={
                                                                                4
                                                                            }
                                                                            color={
                                                                                colors[
                                                                                    'danger-high'
                                                                                ]
                                                                            }
                                                                            fontSize={
                                                                                13
                                                                            }
                                                                            fontWeight={
                                                                                700
                                                                            }>
                                                                            Monto
                                                                            a
                                                                            pagar
                                                                            por
                                                                            exceso
                                                                            de
                                                                            límite:{' '}
                                                                            {parseFloat(
                                                                                // @ts-ignore
                                                                                formik
                                                                                    .values
                                                                                    .products[
                                                                                    index
                                                                                ]
                                                                                    .price,
                                                                            ) -
                                                                                parseFloat(
                                                                                    solutionsSelect[
                                                                                        index
                                                                                    ]?.solutions?.find(
                                                                                        item =>
                                                                                            item.id ==
                                                                                            formik
                                                                                                .values
                                                                                                .products[
                                                                                                index
                                                                                            ]
                                                                                                .solution_managment_id,
                                                                                    )?.[
                                                                                        `limit_segment_${segment}`
                                                                                    ],
                                                                                )}{' '}
                                                                            €
                                                                        </Text>
                                                                    )}
                                                                </>
                                                            )}
                                                    </AccordionPanel>
                                                </AccordionItem>
                                            ),
                                        )}
                                    </Accordion>
                                ) : (
                                    <Box mt={8} w="90%" color="black">
                                        <FormControl mt={4}>
                                            <FormLabel
                                                fontSize={13}
                                                fontWeight={700}>
                                                Solución
                                            </FormLabel>

                                            <Select
                                                id={`products[${0}].solution_managment_id`}
                                                placeholder="Solución"
                                                color="#4F4F4F"
                                                value={
                                                    formik.values.products[0]
                                                        ?.solution_managment_id
                                                }
                                                fontSize={13}
                                                onChange={simpleChangeSolution}
                                                rounded="md"
                                                size="xs">
                                                {solutionsSelect[0]?.solutions.map(
                                                    (item, index) => {
                                                        if (!item.is_contable)
                                                            return null

                                                        return (
                                                            <option
                                                                key={index}
                                                                value={item.id}>
                                                                {item.label}
                                                            </option>
                                                        )
                                                    },
                                                )}
                                            </Select>
                                        </FormControl>

                                        <FormControl mt={4}>
                                            <FormLabel
                                                fontSize={13}
                                                fontWeight={700}>
                                                Producto
                                            </FormLabel>

                                            <Select
                                                id={`products[${0}].product_managment_id`}
                                                placeholder="Producto"
                                                color="#4F4F4F"
                                                isDisabled={
                                                    solutionsSelect[0]?.products
                                                        .length === 0
                                                }
                                                value={
                                                    formik.values.products[0]
                                                        ?.product_managment_id
                                                }
                                                fontSize={13}
                                                onChange={simpleChangeProduct}
                                                rounded="md"
                                                size="xs">
                                                {solutionsSelect[0]?.products.map(
                                                    (item, index) => (
                                                        <option
                                                            key={index}
                                                            value={item.id}>
                                                            {item.label}
                                                        </option>
                                                    ),
                                                )}
                                            </Select>
                                        </FormControl>

                                        <FormControl mt={4}>
                                            <FormLabel
                                                fontSize={13}
                                                fontWeight={700}>
                                                Paquete
                                            </FormLabel>

                                            <Select
                                                id={`products[${0}].package_managment_id`}
                                                color="#4F4F4F"
                                                placeholder="Paquete"
                                                isDisabled={
                                                    solutionsSelect[0]?.packages
                                                        .length === 0
                                                }
                                                value={
                                                    formik.values.products[0]
                                                        ?.package_managment_id
                                                }
                                                fontSize={13}
                                                onChange={simpleChangePackage}
                                                rounded="md"
                                                size="xs">
                                                {solutionsSelect[0]?.packages.map(
                                                    (item, index) => (
                                                        <option
                                                            key={index}
                                                            value={item.id}>
                                                            {item.label}
                                                        </option>
                                                    ),
                                                )}
                                            </Select>
                                        </FormControl>

                                        <FormControl mt={4}>
                                            <FormLabel
                                                fontSize={13}
                                                fontWeight={700}>
                                                Precio
                                            </FormLabel>

                                            <Input
                                                id={`products[${0}].price`}
                                                placeholder="Precio"
                                                isDisabled={
                                                    !formik.values.products[0]
                                                        .package_managment_id
                                                }
                                                color="#4F4F4F"
                                                value={
                                                    formik.values.products[0]
                                                        ?.price
                                                }
                                                fontSize={13}
                                                onChange={formik.handleChange}
                                                rounded="md"
                                                size="xs"
                                            />
                                        </FormControl>

                                        {formik.values.products[0]
                                            .solution_managment_id &&
                                            segment && (
                                                <>
                                                    <Text
                                                        mt={4}
                                                        fontSize={13}
                                                        fontWeight={700}>
                                                        Límite por solución:{' '}
                                                        {
                                                            solutions?.find(
                                                                item =>
                                                                    item.id ==
                                                                    formik
                                                                        .values
                                                                        .products[0]
                                                                        .solution_managment_id,
                                                            )?.[
                                                                `limit_segment_${segment}`
                                                            ]
                                                        }{' '}
                                                        €
                                                    </Text>

                                                    {parseFloat(
                                                        // @ts-ignore
                                                        formik.values
                                                            .products[0].price,
                                                    ) >
                                                        parseFloat(
                                                            solutions?.find(
                                                                item =>
                                                                    item.id ==
                                                                    formik
                                                                        .values
                                                                        .products[0]
                                                                        .solution_managment_id,
                                                            )?.[
                                                                `limit_segment_${segment}`
                                                            ],
                                                        ) && (
                                                        <Text
                                                            mt={4}
                                                            color={
                                                                colors[
                                                                    'danger-high'
                                                                ]
                                                            }
                                                            fontSize={13}
                                                            fontWeight={700}>
                                                            Monto a pagar por
                                                            exceso de límite:{' '}
                                                            {parseFloat(
                                                                // @ts-ignore
                                                                formik.values
                                                                    .products[0]
                                                                    .price,
                                                            ) -
                                                                parseFloat(
                                                                    solutions?.find(
                                                                        item =>
                                                                            item.id ==
                                                                            formik
                                                                                .values
                                                                                .products[0]
                                                                                .solution_managment_id,
                                                                    )?.[
                                                                        `limit_segment_${segment}`
                                                                    ],
                                                                )}{' '}
                                                            €
                                                        </Text>
                                                    )}
                                                </>
                                            )}
                                    </Box>
                                )}
                                <Box
                                    mt={8}
                                    w="full"
                                    display="flex"
                                    justifyContent="center">
                                    <Button
                                        color={colors.secondary}
                                        fontSize={13}
                                        fontWeight={700}
                                        variant="link"
                                        onClick={addProduct}
                                        _active={{}}
                                        _hover={{}}>
                                        + Agregar otro producto
                                    </Button>
                                </Box>

                                {formik.values.products.length > 1 && (
                                    <Box
                                        mt={4}
                                        w="full"
                                        display="flex"
                                        justifyContent="center">
                                        <Button
                                            color={colors['danger-high']}
                                            fontSize={13}
                                            fontWeight={700}
                                            variant="link"
                                            onClick={removeProduct}
                                            _active={{}}
                                            _hover={{}}>
                                            - Eliminar un producto
                                        </Button>
                                    </Box>
                                )}
                            </>
                        )}
                    </ModalBody>

                    <Divider />

                    <ModalFooter justifyContent="center" gap={2}>
                        <Button
                            bg="white"
                            borderWidth={1}
                            borderColor={colors.secondary}
                            color={colors.secondary}
                            isLoading={isLoading}
                            fontSize={14}
                            fontWeight={500}
                            h="32px"
                            w={154}
                            onClick={async () => {
                                formik.resetForm()

                                onClose()
                            }}
                            _active={{
                                transform: 'scale(0.98)',
                            }}
                            _hover={{}}>
                            Cancelar
                        </Button>

                        <Button
                            bg={colors.secondary}
                            color="white"
                            isDisabled={disableSave}
                            isLoading={isLoading}
                            fontSize={14}
                            fontWeight={500}
                            type="submit"
                            h="32px"
                            w={154}
                            _active={{
                                transform: 'scale(0.98)',
                            }}
                            _hover={{}}>
                            Guardar
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    )
}
