import React, { FC, useState } from 'react'

import { Box, Button, Text, useDisclosure, useToast } from '@chakra-ui/react'
import { colors } from '@/utils/const'
import { NotesTable } from './NotesTable'
import { CreateNote } from './CreateNote'
import { ShowNote } from './ShowNote'
import { Opportunity } from '@/interfaces'
import { DeleteNote } from './DeleteNote'
import { useOpportunity } from '@/hooks/opportunities'

interface Props {
    opportunity: Opportunity
}

export const NotesCard: FC<Props> = ({ opportunity }) => {
    const { deleteNote } = useOpportunity({ id: opportunity?.id })

    const {
        isOpen: isOpenCreate,
        onOpen: onOpenCreate,
        onClose: onCloseCreate,
    } = useDisclosure()

    const {
        isOpen: isOpenShow,
        onOpen: onOpenShow,
        onClose: onCloseShow,
    } = useDisclosure()

    const {
        isOpen: isOpenDelete,
        onOpen: onOpenDelete,
        onClose: onCloseDelete,
    } = useDisclosure()

    const [noteToShow, setNoteToShow] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const toast = useToast()

    const handleOpenModal = (noteId: number) => {
        setNoteToShow(noteId)
        onOpenShow()
    }

    const handleDeleteModal = (noteId: number) => {
        setNoteToShow(noteId)
        onOpenDelete()
    }

    const onDelete = () => {
        onCloseShow()
        onOpenDelete()
    }

    const handleDeleteNote = async () => {
        try {
            setIsLoading(true)

            await deleteNote({ opportunity_note_id: noteToShow })

            toast({
                title: 'Exito',
                description: 'Nota eliminada con éxito',
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
            setIsLoading(false)
            onCloseDelete()
        } catch (error) {
            toast({
                title: 'Error.',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
            setIsLoading(false)
        }
    }

    return (
        <Box
            bg="white"
            w="100%"
            borderWidth={1}
            borderRadius="md"
            boxShadow="lg">
            <Box display="flex" justifyContent="space-between" py={4} px={6}>
                <Text color="#858585" fontSize={20} fontWeight={400}>
                    Notas
                </Text>

                <Button
                    bg={colors.secondary}
                    color="white"
                    fontSize={14}
                    fontWeight={500}
                    h="32px"
                    w={97}
                    onClick={onOpenCreate}
                    _active={{
                        transform: 'scale(0.98)',
                    }}
                    _hover={{}}>
                    Nuevo
                </Button>
            </Box>

            <CreateNote
                opportunity_id={opportunity?.id}
                isOpen={isOpenCreate}
                onClose={onCloseCreate}
            />

            <ShowNote
                noteToShow={noteToShow}
                notes={opportunity?.notes || []}
                isOpen={isOpenShow}
                onDelete={onDelete}
                onClose={onCloseShow}
            />

            <DeleteNote
                isLoading={isLoading}
                isOpen={isOpenDelete}
                onClose={onCloseDelete}
                handleDeleteNote={handleDeleteNote}
            />

            <NotesTable
                data={opportunity?.notes || []}
                handleOpenModal={handleOpenModal}
                handleDeleteModal={handleDeleteModal}
            />
        </Box>
    )
}
