import React, { Dispatch, FC, SetStateAction, useContext } from 'react'

import { useSWRConfig } from 'swr'
import { Box, Button, HStack, Input, Select, Text } from '@chakra-ui/react'
import { FiltersContext } from '@/context/filters'
import { statusArray } from '@/hooks/statusOpportunity'
import { Distributor, User } from '@/interfaces'
import { colors } from '@/utils/const'

interface Props {
    comercials: User[]
    distributors: Distributor[]
    setPageIndex: Dispatch<SetStateAction<number>>
}

export const OpportunitiesFilters: FC<Props> = ({
    comercials,
    distributors,
    setPageIndex,
}) => {
    const {
        filters,
        updateSearchFilter,
        updateStatusFilter,
        updateDistributorFilter,
        updateCommercialFilter,
        updateStartDateFilter,
        updateEndDateFilter,
        updateOppotunityTypeFilter,
        resetState,
    } = useContext(FiltersContext)

    const { mutate } = useSWRConfig()

    return (
        <Box display="flex" justifyContent="space-between" mb={4} mx={4}>
            <Box display="flex" flexWrap="wrap">
                <Box mr={2} mb={2}>
                    <Text fontSize={13} fontWeight={600} color="#474747">
                        Buscar oportunidad
                    </Text>
                    <Input
                        fontSize={13}
                        placeholder="Buscar"
                        size="sm"
                        rounded="md"
                        value={filters.search}
                        onChange={async e => {
                            await updateSearchFilter(e.target.value)
                        }}
                    />
                </Box>

                <Box mr={2}>
                    <Text fontSize={13} fontWeight={600} color="#474747">
                        Filtrar por estado
                    </Text>
                    <Select
                        fontSize={13}
                        size="sm"
                        rounded="md"
                        placeholder="Estado"
                        w="200px"
                        color="#474747"
                        value={filters.status || ''}
                        onChange={async e => {
                            await updateStatusFilter(e.target.value)
                        }}>
                        {statusArray.map(status => (
                            <option value={status.status} key={status.status}>
                                {status.text}
                            </option>
                        ))}
                    </Select>
                </Box>

                <Box mr={2}>
                    <Text fontSize={13} fontWeight={600} color="#474747">
                        Filtrar por responsable
                    </Text>
                    <Select
                        fontSize={13}
                        size="sm"
                        rounded="md"
                        w="200px"
                        placeholder="Responsable"
                        color="#474747"
                        value={filters.commercial_id || ''}
                        onChange={async e => {
                            await updateCommercialFilter(e.target.value)
                        }}>
                        {comercials?.map(item => {
                            if (item === null) return null
                            return (
                                <option value={item.id} key={item.id}>
                                    {item.name}
                                </option>
                            )
                        })}
                    </Select>
                </Box>

                <Box mr={2}>
                    <Text fontSize={13} fontWeight={600} color="#474747">
                        Filtrar por distribuidor
                    </Text>
                    <Select
                        fontSize={13}
                        size="sm"
                        rounded="md"
                        w="200px"
                        placeholder="Distribuidor"
                        color="#474747"
                        value={filters.distributor_id || ''}
                        onChange={async e => {
                            await updateDistributorFilter(e.target.value)
                        }}>
                        {distributors?.map(item => {
                            if (item === null) return null
                            return (
                                <option value={item.id} key={item.id}>
                                    {item.name}
                                </option>
                            )
                        })}
                    </Select>
                </Box>
                <Box mr={2}>
                    <Box display="flex">
                        <Box mr={2}>
                            <Text
                                fontSize={13}
                                fontWeight={600}
                                color="#474747">
                                Desde
                            </Text>

                            <Input
                                fontSize={13}
                                placeholder="Desde"
                                mr={2}
                                size="sm"
                                rounded="md"
                                color="#474747"
                                type="date"
                                value={filters.start_date || ''}
                                onChange={async e => {
                                    await updateStartDateFilter(e.target.value)
                                }}
                            />
                        </Box>

                        <Box mr={2}>
                            <Text
                                fontSize={13}
                                fontWeight={600}
                                color="#474747">
                                Hasta
                            </Text>

                            <Input
                                fontSize={13}
                                placeholder="Hasta"
                                mr={2}
                                size="sm"
                                rounded="md"
                                color="#474747"
                                type="date"
                                value={filters.end_date || ''}
                                onChange={async e => {
                                    await updateEndDateFilter(e.target.value)
                                }}
                            />
                        </Box>

                        <Box>
                            <Text
                                fontSize={13}
                                fontWeight={600}
                                color="#474747">
                                Tipo de oportunidad
                            </Text>

                            <Select
                                fontSize={13}
                                size="sm"
                                rounded="md"
                                w="200px"
                                placeholder="Tipo de oportunidad"
                                color="#474747"
                                value={filters.opportunity_type || ''}
                                onChange={e =>
                                    updateOppotunityTypeFilter(e.target.value)
                                }>
                                <option value="asesoria">Asesoria</option>
                            </Select>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <HStack align="top" mt={5} spacing={2}>
                <Button
                    color="white"
                    bg={colors.secondary}
                    fontSize={13}
                    h="30px"
                    w="110px"
                    p={1}
                    onClick={() => {
                        mutate(`/api/opportunity?page=1`)
                        setPageIndex(1)
                    }}
                    _active={{
                        transform: 'scale(0.98)',
                    }}
                    _hover={{}}>
                    Filtrar
                </Button>

                <Button
                    color={colors.secondary}
                    bg="white"
                    borderWidth={2}
                    borderColor={colors.secondary}
                    fontSize={13}
                    h="30px"
                    w="110px"
                    p={1}
                    onClick={async () => {
                        await resetState()
                        mutate(`/api/opportunity?page=1`)
                        setPageIndex(1)
                    }}
                    _active={{
                        transform: 'scale(0.98)',
                    }}
                    _hover={{}}>
                    Limpiar
                </Button>
            </HStack>
        </Box>
    )
}
