import React, { FC } from 'react'

import { FormikProps } from 'formik'
import {
    Box,
    FormLabel,
    FormControl,
    Input,
    FormErrorMessage,
    chakra,
    Text,
    Select,
} from '@chakra-ui/react'
import { RepresentativeType } from '@/interfaces'
import { ANTIQUITY_OPTIONS } from '@/constants/antiquity'

interface Props {
    formik: FormikProps<FormikValues>
    representativesTypes: RepresentativeType
}

interface FormikValues {
    activity: string
    cnae: string
    iae: string
    antiquity: string
    enterprise_created_date: string
    client_type: string
    representatives: Representative[]
}

interface Representative {
    representative_position: string
}

export const StepInfoCompany: FC<Props> = ({
    formik,
    representativesTypes,
}) => {
    function handleOnTainted(key: string) {
        formik.setTouched({ ...formik.touched, [key]: true })
    }

    return (
        <Box mt={8} w="90%" color="black">
            <Box
                w="90%"
                display="flex"
                justifyContent="space-between"
                fontSize={13}
                fontWeight={400}
                mb={4}>
                <Box display="flex">
                    <Text mr={4}>Cliente</Text>
                    <Text fontWeight={700}>
                        {formik.values.client_type === 'a'
                            ? 'Autónomo'
                            : 'Empresa'}
                    </Text>
                </Box>
                {formik.values.client_type === 's' && (
                    <Box display="flex">
                        <Text mr={4}>Representante</Text>
                        <Text fontWeight={700}>
                            {
                                representativesTypes[
                                    formik.values.representatives[0]
                                        .representative_position
                                ].type
                            }
                        </Text>
                    </Box>
                )}
            </Box>

            <FormControl
                mb={4}
                isInvalid={formik.errors.activity && formik.touched.activity}>
                <FormLabel fontSize={13} fontWeight={700}>
                    Actividad{' '}
                    <chakra.span style={{ color: '#FD7C04' }}>*</chakra.span>
                </FormLabel>
                <Input
                    id="activity"
                    placeholder="Actividad"
                    rounded="4px"
                    size="xs"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.activity}
                />
                <FormErrorMessage>{formik.errors.activity}</FormErrorMessage>
            </FormControl>

            <FormControl
                mb={4}
                isInvalid={formik.errors.cnae && formik.touched.cnae}
                onBlur={() => handleOnTainted('cnae')}>
                <FormLabel fontSize={13} fontWeight={700}>
                    CNAE{' '}
                    <chakra.span style={{ color: '#FD7C04' }}>*</chakra.span>
                </FormLabel>
                <Input
                    id="cnae"
                    placeholder="CNAE"
                    rounded="4px"
                    size="xs"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.cnae}
                />
                <FormErrorMessage>{formik.errors.cnae}</FormErrorMessage>
            </FormControl>

            <FormControl
                mb={4}
                isInvalid={formik.errors.iae && formik.touched.iae}
                onBlur={() => handleOnTainted('iae')}>
                <FormLabel fontSize={13} fontWeight={700}>
                    IAE{' '}
                    <chakra.span style={{ color: '#FD7C04' }}>*</chakra.span>
                </FormLabel>
                <Input
                    id="iae"
                    placeholder="IAE"
                    rounded="4px"
                    size="xs"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.iae}
                />
                <FormErrorMessage>{formik.errors.iae}</FormErrorMessage>
            </FormControl>

            <FormControl
                mb={4}
                isInvalid={formik.errors.antiquity && formik.touched.antiquity}
                onBlur={() => handleOnTainted('antiquity')}>
                <FormLabel fontSize={13} fontWeight={700}>
                    Antigüedad Empresa{' '}
                    <chakra.span style={{ color: '#FD7C04' }}>*</chakra.span>
                </FormLabel>

                <Select
                    id="antiquity"
                    placeholder="Antigüedad Empresa"
                    rounded="4px"
                    size="xs"
                    onChange={e => {
                        formik.setFieldValue('enterprise_created_date', '')
                        formik.handleChange(e)
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.antiquity}>
                    {ANTIQUITY_OPTIONS.map(item => (
                        <option key={item} value={item}>
                            {item}
                        </option>
                    ))}
                </Select>
                <FormErrorMessage>{formik.errors.antiquity}</FormErrorMessage>
            </FormControl>

            {formik.values.antiquity === 'Menos de 6 meses' && (
                <FormControl
                    mb={4}
                    isInvalid={
                        formik.errors.enterprise_created_date &&
                        formik.touched.enterprise_created_date
                    }
                    onBlur={() => handleOnTainted('enterprise_created_date')}>
                    <FormLabel fontSize={13} fontWeight={700}>
                        Fecha de creación de la Empresa{' '}
                        <chakra.span style={{ color: '#FD7C04' }}>
                            *
                        </chakra.span>
                    </FormLabel>

                    <Input
                        id="enterprise_created_date"
                        rounded="4px"
                        size="xs"
                        type="date"
                        onChange={formik.handleChange}
                        value={formik.values.enterprise_created_date}
                    />
                    <FormErrorMessage>
                        {formik.errors.enterprise_created_date}
                    </FormErrorMessage>
                </FormControl>
            )}
        </Box>
    )
}
