import React, { FC } from 'react'

import { Box, Text } from '@chakra-ui/react'

interface Props {
    color: string
    segment: number
    icon: React.ReactElement
}

export const Segmento: FC<Props> = ({ color, icon, segment }) => {
    return (
        <Box bg="white" w="57px" h="57px" borderWidth={1} borderRadius="md">
            <Box position="absolute">
                <Box w="55px" bg={color} h="30px" roundedTop="md"></Box>
                <Box roundedBottom="100%" w="55px" bg={color} h="16px" />
            </Box>
            <Box
                position="absolute"
                bg="transparent"
                color="white"
                mt={1}
                w="57px"
                h="57px">
                <Box display="flex" justifyContent="center">
                    {icon}
                </Box>
                <Box display="flex" justifyContent="center">
                    <Text fontSize={8} fontWeight={700}>
                        Segmento
                    </Text>
                </Box>
                <Box display="flex" justifyContent="center">
                    <Text fontSize={12} fontWeight={900}>
                        {segment}
                    </Text>
                </Box>
            </Box>
        </Box>
    )
}
