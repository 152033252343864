import React, { FC, useState } from 'react'

import {
    Button,
    Divider,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Box,
    Text,
} from '@chakra-ui/react'

import { colors } from '@/utils/const'

interface Props {
    noteToShow: number
    notes: Note[]
    isOpen: boolean
    onClose: () => void
    onDelete: () => void
}

interface Note {
    id: number
    title: string
    description: string
    date: string
}

export const ShowNote: FC<Props> = ({
    noteToShow,
    notes,
    isOpen,
    onClose,
    onDelete,
}) => {
    const note = notes.find(item => {
        return item.id === noteToShow
    })
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="md">
            <ModalOverlay />
            <ModalContent>
                <ModalBody color="#474747" py={4}>
                    <Box display="flex" justifyContent="space-between" mb={2}>
                        <Text color="#474747" fontSize={13} fontWeight={500}>
                            Titulo
                        </Text>

                        <Button
                            color={colors.secondary}
                            fontSize={14}
                            fontWeight={500}
                            variant="link"
                            onClick={onClose}
                            _active={{
                                transform: 'scale(0.98)',
                            }}
                            _hover={{}}>
                            Cerrar
                        </Button>
                    </Box>

                    <Text fontSize={13} fontWeight={700} ml={4} mb={4}>
                        {note?.title}
                    </Text>

                    <Text color="#474747" fontSize={13} fontWeight={500} mb={4}>
                        Título
                    </Text>

                    <Box
                        display="flex"
                        justifyContent="center"
                        minH="60px"
                        mb={4}
                        color="rgba(0, 0, 0, 0.54)"
                        fontSize={13}
                        fontWeight={500}>
                        <Box w="90%">
                            <Text>{note?.description}</Text>
                        </Box>
                    </Box>
                </ModalBody>

                <Divider />

                <ModalFooter justifyContent="space-between" py={4}>
                    <Button
                        color="black"
                        fontSize={14}
                        fontWeight={500}
                        h="32px"
                        variant="link"
                        onClick={onDelete}
                        _active={{
                            transform: 'scale(0.98)',
                        }}
                        _hover={{}}>
                        Eliminar
                    </Button>

                    <Button
                        bg={colors.secondary}
                        border="1px"
                        color="white"
                        fontSize={14}
                        fontWeight={500}
                        h="32px"
                        w={154}
                        onClick={onClose}
                        _active={{
                            transform: 'scale(0.98)',
                        }}
                        _hover={{}}>
                        Listo
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
