import React, { FC, useState } from 'react'

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    Text,
    Button,
    ModalFooter,
    Divider,
    Box,
    Input,
    useToast,
} from '@chakra-ui/react'
import { colors } from '@/utils/const'
import { cancelDocumentViafirma } from '@/hooks/client'

interface Props {
    client_id: number
    isOpen: boolean
    onClose: () => void
    onOpenValidate: () => void
}

export const CancelDocumentModal: FC<Props> = ({
    client_id,
    isOpen,
    onClose,
    onOpenValidate,
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [value, setValue] = useState('')
    const toast = useToast()

    const handleChange = e => {
        setValue(e.target.value)
    }

    const handleValidate = async () => {
        setIsLoading(true)
        try {
            await cancelDocumentViafirma({ client_id })

            onOpenValidate()
            toast({
                title: 'Documento cancelado',
                description: 'El documento ha sido cancelado con éxito.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
        } catch (error) {
            toast({
                title: 'Error',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }

        setIsLoading(false)
        onClose()
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="sm">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader
                    color="#474747"
                    display="flex"
                    fontSize={18}
                    fontWeight={600}
                    justifyContent="center"
                    pt={6}
                    pb={4}>
                    Cancelar contrato
                </ModalHeader>

                <ModalBody px={12} mb={10}>
                    <Text
                        color="#565758"
                        mb={6}
                        fontSize={16}
                        fontWeight={700}
                        textAlign="center">
                        ¿Está seguro que quiere cancelar el contrato actual?
                    </Text>

                    <Text mb={2} fontSize={12} fontWeight={500}>
                        Si es así, escriba &apos;CANCELAR CONTRATO&apos; para
                        continuar
                    </Text>

                    <Input
                        size="sm"
                        rounded={4}
                        fontSize={12}
                        fontWeight={400}
                        onChange={handleChange}
                    />
                </ModalBody>

                <Divider />

                <ModalFooter px={0}>
                    <Box display="flex" justifyContent="center" w="full">
                        <Button
                            bg="white"
                            border="1px"
                            borderColor={colors.secondary}
                            color={colors.secondary}
                            fontSize={12}
                            fontWeight={500}
                            h="32px"
                            mr={10}
                            w={112}
                            onClick={onClose}
                            _active={{
                                transform: 'scale(0.98)',
                            }}
                            _hover={{}}>
                            Atrás
                        </Button>

                        <Button
                            bg={colors.secondary}
                            border="1px"
                            color="white"
                            isDisabled={value !== 'CANCELAR CONTRATO'}
                            isLoading={isLoading}
                            fontSize={12}
                            fontWeight={500}
                            h="32px"
                            mr={10}
                            w={112}
                            onClick={handleValidate}
                            _active={{
                                transform: 'scale(0.98)',
                            }}
                            _hover={{}}>
                            Continuar
                        </Button>
                    </Box>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
