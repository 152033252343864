import React, { FC, useEffect, useState } from 'react'

import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
    Box,
    Button,
    Center,
    IconButton,
    Select,
    Text,
    useToast,
} from '@chakra-ui/react'
import { EditLine } from '../icons'
import { colors } from '@/utils/const'
import { Distributor } from '@/interfaces'
import { useOpportunities, useOpportunity } from '@/hooks/opportunities'

interface Props {
    opportunity_id: number
    rolUser: string
    distributor: Distributor
}

const Schema = Yup.object().shape({
    distributor: Yup.number(),
})

export const DistributorDetail: FC<Props> = ({
    opportunity_id,
    rolUser,
    distributor,
}) => {
    const { updateOpportunity } = useOpportunity({ id: opportunity_id })
    const { getDistributors } = useOpportunities({})
    const [distributors, setDistributors] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const toast = useToast()

    useEffect(() => {
        getDistributors()
            .then(data => setDistributors(data))
            .catch(error => {
                toast({
                    title: 'Error.',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            })
    }, [])

    const formik = useFormik({
        initialValues: {
            distributor: distributor?.id || 0,
        },
        validationSchema: Schema,
        onSubmit: async values => {
            if (values.distributor !== 0) {
                setIsLoading(true)

                await updateOpportunity({
                    opportunity_id,
                    data: {
                        distributor_id: values.distributor,
                    },
                })

                try {
                    toast({
                        title: 'Éxito',
                        description: 'Distribuidor actualizado con éxito',
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    })

                    setIsEditing(false)
                } catch (error) {
                    toast({
                        title: 'Error.',
                        description: error.message,
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    })

                    formik.resetForm()
                }

                setIsLoading(false)
            } else if (values.distributor === 0 && distributors.length > 0) {
                setIsLoading(true)

                await updateOpportunity({
                    opportunity_id,
                    data: {
                        distributor_id: distributors[0].id,
                    },
                })

                try {
                    toast({
                        title: 'Éxito',
                        description: 'Distribuidor actualizado con éxito',
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    })

                    setIsEditing(false)
                } catch (error) {
                    toast({
                        title: 'Error.',
                        description: error.message,
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    })

                    formik.resetForm()
                }

                setIsLoading(false)
            }
        },
    })

    return (
        <>
            {isEditing ? (
                <>
                    <Box w="full" maxW={300}>
                        <form onSubmit={formik.handleSubmit}>
                            <Text fontSize={12} fontWeight={700} mb={1}>
                                Distribuidor:
                            </Text>
                            <Select
                                id="distributor"
                                size="xs"
                                mb={2}
                                rounded={6}
                                value={formik.values.distributor}
                                onChange={formik.handleChange}>
                                {distributors.map(distributor => (
                                    <option
                                        key={distributor.id}
                                        value={distributor.id}>
                                        {distributor.name}
                                    </option>
                                ))}
                            </Select>

                            <Center>
                                <Button
                                    bg={colors.tertiary}
                                    border="1px"
                                    color="white"
                                    fontSize={12}
                                    fontWeight={500}
                                    h="24px"
                                    isLoading={isLoading}
                                    type="submit"
                                    w={84}
                                    _active={{
                                        transform: 'scale(0.98)',
                                    }}
                                    _hover={{}}>
                                    Guardar
                                </Button>
                            </Center>
                        </form>
                    </Box>

                    <IconButton
                        aria-label={''}
                        bg="transparent"
                        icon={
                            <EditLine transform="scale(1.2)" color="#858585" />
                        }
                        onClick={() => setIsEditing(!isEditing)}
                        _active={{}}
                        _hover={{}}
                    />
                </>
            ) : (
                <>
                    <Text fontSize={12} fontWeight={700}>
                        Distribuidor:
                    </Text>
                    <Text fontSize={12} fontWeight={600} mx={2}>
                        {distributor ? distributor.name : 'Sin definir'}
                    </Text>

                    <IconButton
                        aria-label={''}
                        bg="transparent"
                        icon={
                            <EditLine transform="scale(1.2)" color="#858585" />
                        }
                        onClick={() => setIsEditing(!isEditing)}
                        _active={{}}
                        _hover={{}}
                    />
                </>
            )}
        </>
    )
}
