import React, { FC, useEffect, useState } from 'react'

import {
    Box,
    Grid,
    GridItem,
    IconButton,
    Text,
    useDisclosure,
} from '@chakra-ui/react'
import { Client } from '@/interfaces'
import { PencilIcon } from '../icons'
import { UpdateProducts } from './modals/UpdateProducts'
import { getSolutions } from '@/hooks/solutions'

interface Props {
    client: Client
    opportunity_id: number | string
}

export const ProductsCard: FC<Props> = ({ client, opportunity_id }) => {
    const [solutions, setSolutions] = useState([])
    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        const fetchSolutions = async () => {
            const solutions = await getSolutions()
            setSolutions(solutions)
        }

        fetchSolutions()
    }, [])

    return (
        <>
            <Box
                bg="#F8F8F8"
                w="100%"
                borderWidth={1}
                borderRadius="xl"
                boxShadow="lg">
                <Grid
                    bg="#001647"
                    color="white"
                    fontSize={12}
                    fontWeight={700}
                    gap={4}
                    h="33px"
                    p={2}
                    templateColumns="10px 1fr 1fr 0.6fr 40px"
                    alignItems="center"
                    justifyContent="start">
                    <GridItem>#</GridItem>
                    <GridItem>Solución</GridItem>
                    <GridItem>Producto</GridItem>
                    <GridItem>Paquete</GridItem>
                    <GridItem>
                        <IconButton
                            aria-label="Pencil"
                            bg="transparent"
                            boxSize="20px"
                            isDisabled={solutions.length === 0}
                            onClick={onOpen}
                            _active={{}}
                            _hover={{}}>
                            <PencilIcon color="#fff" transform="scale(0.8)" />
                        </IconButton>
                    </GridItem>
                </Grid>

                {client?.products.map((item, index) => {
                    return (
                        <Grid
                            alignItems="center"
                            key={index}
                            bg="white"
                            borderBottomWidth={1}
                            fontWeight={500}
                            gap={4}
                            p={2}
                            templateColumns="10px 1fr 1fr 0.6fr 40px">
                            <GridItem mb={1} fontSize={13}>
                                <Text color="#000">{index + 1}</Text>
                            </GridItem>
                            <GridItem mb={1} fontSize={13}>
                                <Text color="#000">
                                    {item.solution_managment.label}
                                </Text>
                            </GridItem>
                            <GridItem mb={1} fontSize={13}>
                                <Text color="#000">
                                    {item.product_managment.label}
                                </Text>
                            </GridItem>
                            <GridItem mb={1} fontSize={13}>
                                <Text color="#000">
                                    {item.package_managment.label}
                                </Text>
                            </GridItem>
                        </Grid>
                    )
                })}
            </Box>

            {/* modals */}
            {solutions.length > 0 && (
                <UpdateProducts
                    client={client}
                    solutions={solutions}
                    segment={client.employees_number}
                    opportunity_id={opportunity_id}
                    isOpen={isOpen}
                    onClose={onClose}
                />
            )}
        </>
    )
}
