import React, { FC } from 'react'

import { Box, Divider, Text, Button } from '@chakra-ui/react'
import { colors } from '@/utils/const'
import { Person } from '../icons'

interface Props {
    onOpen: () => void
}

export const UnstartedOpportunity: FC<Props> = ({ onOpen }) => {
    return (
        <Box
            bg="white"
            w="100%"
            borderWidth={1}
            borderRadius="md"
            boxShadow="lg">
            <Box
                h="245px"
                display="flex"
                justifyContent="center"
                alignItems="center">
                <Box>
                    <Box display="flex" justifyContent="center" mb={2}>
                        <Person />
                    </Box>
                    <Text>Esta oportunidad no ha sido iniciada</Text>
                </Box>
            </Box>

            <Divider />

            <Box display="flex" justifyContent="center" my={6}>
                <Button
                    color="white"
                    bg={colors.secondary}
                    h="32px"
                    w="154px"
                    fontSize={14}
                    fontWeight={500}
                    _active={{
                        bg: colors.primary,
                        transform: 'scale(0.98)',
                    }}
                    _hover={{
                        bg: colors.primary,
                    }}
                    onClick={onOpen}>
                    Iniciar proceso
                </Button>
            </Box>
        </Box>
    )
}
