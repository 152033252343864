import React, { FC, useState } from 'react'

import Vapor from 'laravel-vapor'
import { useSWRConfig } from 'swr'
import {
    Box,
    Text,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Progress,
    Center,
    Button,
    Tabs,
    useToast,
    UnorderedList,
    ListItem,
    Spinner,
} from '@chakra-ui/react'
import { DocumentsTable } from './DocumentsTable'
import { Opportunity } from '@/interfaces'
import { colors } from '@/utils/const'
import { DocumentUpload } from '../user'
import { useOpportunities } from '@/hooks/opportunities'
import { RepeatIcon } from '@chakra-ui/icons'
import { refreshViafirmaDocuments } from '@/hooks/client'

interface Props {
    opportunity: Opportunity
    isLoading: boolean
}

const headers = ['Ver documentos', 'Subir documentos']

export const DocumentsOpportunity: FC<Props> = ({ opportunity, isLoading }) => {
    const { mutate } = useSWRConfig()
    const { uploadFile } = useOpportunities({})
    const [documentsSelected, setDocumentsSelected] = useState<FileList>(null)
    const [isLoadingUpload, setIsLoadingUpload] = useState(false)
    const [progress, setProgress] = useState(0)
    const [isRefreshing, setIsRefreshing] = useState(false)

    const toast = useToast()

    const handleRefresh = async () => {
        setIsRefreshing(true)
        try {
            await refreshViafirmaDocuments({
                client_id: opportunity?.client?.id,
            })

            mutate(`/api/opportunity/${opportunity?.id}`)
        } catch (error) {
            toast({
                title: 'Error.',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }

        setIsRefreshing(false)
    }

    const changeDocument = e => {
        if (e.target.files && e.target.files[0] !== undefined) {
            setDocumentsSelected(e.target.files)
        }
    }

    const handleUpload = async () => {
        let existError = false
        setIsLoadingUpload(true)

        for await (const document of documentsSelected) {
            setProgress(0)

            await Vapor.store(document, {
                progress: progress => {
                    setProgress(Math.round(progress * 100))
                },
            }).then(async response => {
                const responseValues: any = {
                    uuid: response.uuid,
                    key: response.key,
                    name: document.name,
                    content_type: document.type,
                }

                try {
                    await uploadFile({
                        opportunity_id: opportunity.id,
                        documents: [responseValues],
                    })
                    toast({
                        title: 'Éxito',
                        description: '¡Documento cargado con éxito!',
                        status: 'success',
                        duration: 2000,
                        isClosable: true,
                    })
                } catch (error) {
                    toast({
                        title: 'Error.',
                        description: error.message,
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    })
                    existError = true
                    setIsLoadingUpload(false)
                }
            })
        }

        if (!existError) {
            mutate(`/api/opportunity/${opportunity?.id}`)
            setDocumentsSelected(null)
            setIsLoadingUpload(false)
        }
    }

    return (
        <Box
            bg="white"
            borderRadius="md"
            borderWidth={1}
            boxShadow="lg"
            fontSize={13}
            p={6}
            pr={12}
            minH={'300px'}
            w="100%">
            <Box display="flex" justifyContent="space-between">
                <Text fontSize={14} fontWeight={700} color="#474747" mb={4}>
                    Documentos
                </Text>

                {[
                    'bonus_managment_pending',
                    'bonus_managment_pending_antiquity',
                    'bonus_managment_pending_sent',
                    'bonus_managment_sent',
                    'closed',
                ].includes(opportunity?.status) &&
                    opportunity?.client?.signed_date && (
                        <>
                            {!isRefreshing ? (
                                <RepeatIcon
                                    h={4}
                                    w={4}
                                    cursor="pointer"
                                    onClick={handleRefresh}
                                />
                            ) : (
                                <Spinner
                                    thickness="4px"
                                    speed="0.65s"
                                    emptyColor="gray.200"
                                    color="blue.500"
                                />
                            )}
                        </>
                    )}
            </Box>

            <Tabs>
                <TabList fontSize={16} fontWeight={300}>
                    {headers.map(item => (
                        <Tab
                            key={item}
                            pb={0}
                            _selected={{
                                fontWeight: '700',
                                borderBottom: '2px',
                                borderColor: colors.secondary,
                            }}>
                            {item}
                        </Tab>
                    ))}
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <DocumentsTable
                            data={opportunity?.documents?.sort((a, b) => {
                                const aTime = new Date(a.created_at).getTime(),
                                    bTime = new Date(b.created_at).getTime()
                                return bTime - aTime
                            })}
                            isLoading={isLoading}
                        />
                    </TabPanel>

                    <TabPanel>
                        {documentsSelected?.length > 0 ? (
                            <>
                                <Box
                                    display="flex"
                                    h="150px"
                                    alignItems="center"
                                    justifyContent="center">
                                    {isLoadingUpload ? (
                                        <Box w="70%">
                                            <Progress
                                                hasStripe
                                                height="25px"
                                                value={progress}
                                                mb={2}
                                            />
                                            <Center>
                                                <Text
                                                    fontSize={16}
                                                    fontWeight={600}>
                                                    Subiendo documento
                                                </Text>
                                            </Center>
                                        </Box>
                                    ) : (
                                        <>
                                            <Box>
                                                <Center>
                                                    <UnorderedList mb={4}>
                                                        {[
                                                            ...Array(
                                                                documentsSelected.length,
                                                            ),
                                                        ].map((n, index) => (
                                                            <ListItem
                                                                key={index}
                                                                mb={1}
                                                                fontSize={14}
                                                                fontWeight={
                                                                    600
                                                                }>
                                                                {
                                                                    documentsSelected[
                                                                        index
                                                                    ].name
                                                                }
                                                            </ListItem>
                                                        ))}
                                                    </UnorderedList>
                                                </Center>

                                                <Box>
                                                    <Button
                                                        bg="white"
                                                        border="1px"
                                                        borderColor={
                                                            colors.secondary
                                                        }
                                                        color={colors.secondary}
                                                        isDisabled={
                                                            isLoadingUpload
                                                        }
                                                        fontSize={10}
                                                        fontWeight={400}
                                                        h="28px"
                                                        mr={8}
                                                        w={90}
                                                        onClick={() =>
                                                            setDocumentsSelected(
                                                                null,
                                                            )
                                                        }
                                                        _active={{
                                                            transform:
                                                                'scale(0.98)',
                                                        }}
                                                        _hover={{}}>
                                                        Cancelar
                                                    </Button>
                                                    <Button
                                                        bg={colors.secondary}
                                                        border="1px"
                                                        borderColor={
                                                            colors.secondary
                                                        }
                                                        color="white"
                                                        isLoading={
                                                            isLoadingUpload
                                                        }
                                                        fontSize={10}
                                                        fontWeight={400}
                                                        h="28px"
                                                        w={90}
                                                        onClick={handleUpload}
                                                        _active={{
                                                            transform:
                                                                'scale(0.98)',
                                                        }}
                                                        _hover={{}}>
                                                        Guardar
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </>
                                    )}
                                </Box>
                            </>
                        ) : (
                            <DocumentUpload changeDocument={changeDocument} />
                        )}
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    )
}
