import React, { FC } from 'react'

import { Box, Text, Image, Center, Button } from '@chakra-ui/react'

import { colors } from '@/utils/const'

interface Props {
    onOpen: () => void
}

export const CompanyWPreliminary: FC<Props> = ({ onOpen }) => {
    return (
        <Box w="100%" borderWidth={1} borderRadius="md" boxShadow="lg">
            <Box mx={4} my={2} display="flex" alignItems="center">
                <Text ml={2} fontSize={14} fontWeight={400} color="#858585">
                    Datos del cliente
                </Text>
            </Box>

            <Box display="flex" alignItems="center" justifyContent="center">
                <Box>
                    <Center mb={2}>
                        <Image
                            src="/images/PersonWPreliminary.png"
                            alt="Person"
                        />
                    </Center>

                    <Text mb={2} fontSize={14} fontWeight={500} color="#474747">
                        Sin datos de pre-acuerdo
                    </Text>

                    <Center mb={8}>
                        <Button
                            variant="link"
                            color={colors.secondary}
                            onClick={onOpen}
                            _active={{
                                transform: 'scale(0.98)',
                            }}>
                            Rellenar
                        </Button>
                    </Center>
                </Box>
            </Box>
        </Box>
    )
}
