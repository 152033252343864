import React, { FC, useState } from 'react'

import { useFormik } from 'formik'
import {
    Button,
    Divider,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Box,
    Text,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    useToast,
} from '@chakra-ui/react'

import { colors } from '@/utils/const'
import { useOpportunity } from '@/hooks/opportunities'

interface Props {
    opportunity_id: number
    isOpen: boolean
    onClose: () => void
}

export const CreateNote: FC<Props> = ({ opportunity_id, isOpen, onClose }) => {
    const { createNote } = useOpportunity({ id: opportunity_id })
    const [isLoading, setIsLoading] = useState(false)
    const toast = useToast()

    const formik = useFormik({
        initialValues: {
            title: '',
            note: '',
        },
        onSubmit: async values => {
            try {
                setIsLoading(true)

                await createNote({
                    data: {
                        opportunity_id,
                        title: values.title,
                        note: values.note,
                    },
                })

                toast({
                    title: 'Exito',
                    description: 'Nota creada con éxito',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })
                formik.resetForm()
                onClose()
            } catch (error) {
                toast({
                    title: 'Error.',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
                setIsLoading(false)
            }

            setIsLoading(false)
        },
    })

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="md">
            <ModalOverlay />
            <ModalContent>
                <form onSubmit={formik.handleSubmit}>
                    <ModalHeader px={6} pb={2}>
                        <Box display="flex" justifyContent="space-between">
                            <Text
                                color="#858585"
                                fontSize={20}
                                fontWeight={400}>
                                Notas
                            </Text>

                            <Button
                                color={colors.secondary}
                                fontSize={14}
                                fontWeight={500}
                                variant="link"
                                onClick={onClose}
                                _active={{
                                    transform: 'scale(0.98)',
                                }}
                                _hover={{}}>
                                Cerrar
                            </Button>
                        </Box>
                    </ModalHeader>

                    <ModalBody color="#474747">
                        <FormControl mb={4}>
                            <FormLabel fontSize={13} fontWeight={500}>
                                Título
                            </FormLabel>
                            <Input
                                id="title"
                                placeholder="Titulo"
                                rounded="6px"
                                size="sm"
                                onChange={formik.handleChange}
                                value={formik.values.title}
                            />
                        </FormControl>

                        <FormControl mb={4}>
                            <FormLabel fontSize={13} fontWeight={500}>
                                Descripción
                            </FormLabel>
                            <Textarea
                                id="note"
                                rounded="6px"
                                size="sm"
                                onChange={formik.handleChange}
                                value={formik.values.note}
                            />
                        </FormControl>
                    </ModalBody>

                    <Divider />

                    <ModalFooter justifyContent="end">
                        <Button
                            bg={colors.secondary}
                            color="white"
                            isLoading={isLoading}
                            fontSize={14}
                            fontWeight={500}
                            type="submit"
                            h="32px"
                            w={154}
                            _active={{
                                transform: 'scale(0.98)',
                            }}
                            _hover={{}}>
                            Guardar
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    )
}
