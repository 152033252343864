import React, { FC, useState } from 'react'

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    Text,
    Button,
    useToast,
} from '@chakra-ui/react'
import { colors } from '@/utils/const'
import { Opportunity } from '@/interfaces'
import { useOpportunity } from '@/hooks/opportunities'

interface Props {
    opportunity: Opportunity
    isOpen: boolean
    onClose: () => void
    onlyClose?: boolean
}

export const CloseModal: FC<Props> = ({
    opportunity,
    isOpen,
    onClose,
    onlyClose,
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const toast = useToast()
    const { setStatusOpportunity } = useOpportunity({ id: opportunity?.id })

    const handleStatus = async (status: 'closed' | 'lost') => {
        try {
            setIsLoading(true)

            await setStatusOpportunity({
                opportunity_id: opportunity?.id,
                status,
            })

            setIsLoading(false)
            onClose()
        } catch (error) {
            toast({
                title: 'Error.',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }
        setIsLoading(false)
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="sm">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader
                    color="#474747"
                    display="flex"
                    fontSize={18}
                    fontWeight={600}
                    justifyContent="center"
                    pt={6}
                    pb={4}>
                    {!onlyClose ? 'Perder oportunidad' : 'Cerrar oportunidad'}
                </ModalHeader>

                <ModalBody px={12} mb={10}>
                    <Text
                        color="#565758"
                        mb={6}
                        fontSize={12}
                        fontWeight={400}
                        textAlign="center">
                        Estado de la etapa
                    </Text>

                    <Button
                        color="#474747"
                        mb={6}
                        fontSize={12}
                        fontWeight={400}
                        isLoading={isLoading}
                        w="full"
                        bg="white"
                        borderColor="#474747"
                        borderWidth="1px"
                        onClick={() => handleStatus('lost')}
                        _hover={{
                            bg: colors.primary,
                            color: 'white',
                        }}>
                        Perdida
                    </Button>

                    {onlyClose && (
                        <Button
                            color="#474747"
                            mb={6}
                            fontSize={12}
                            fontWeight={400}
                            isLoading={isLoading}
                            w="full"
                            bg="white"
                            borderColor="#474747"
                            borderWidth="1px"
                            onClick={() => handleStatus('closed')}
                            _hover={{
                                bg: colors.primary,
                                color: 'white',
                            }}>
                            Cerrada
                        </Button>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
