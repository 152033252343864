import React, { FC, useState, useEffect, useRef } from 'react'

import {
    ColumnDef,
    flexRender,
    PaginationState,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
    FilterFn,
} from '@tanstack/react-table'

import {
    RankingInfo,
    rankItem,
    compareItems,
} from '@tanstack/match-sorter-utils'

import {
    Box,
    Text,
    TableContainer,
    Table,
    Tr,
    Tbody,
    Td,
    Spinner,
} from '@chakra-ui/react'

import { colors } from '@/utils/const'
import { EyeLine } from '../icons'
import { Pagination } from '../utils'
import { useOpportunities } from '@/hooks/opportunities'
import { CircleIcon } from '../icons/CircleIcon'

interface Props {
    data: any[]
    isLoading: boolean
}

declare module '@tanstack/table-core' {
    interface FilterFns {
        fuzzy: FilterFn<unknown>
    }
    interface FilterMeta {
        itemRank: RankingInfo
    }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value)

    // Store the itemRank info
    addMeta({
        itemRank,
    })

    // Return if the item should be filtered in/out
    return itemRank.passed
}
export const DocumentsTable: FC<Props> = ({ data = [], isLoading }) => {
    const { viewFile } = useOpportunities({})
    const [sorting, setSorting] = React.useState<SortingState>([])
    const [mounted, setMounted] = useState(false)
    const [isLoadingView, setIsLoadingView] = useState(false)
    const [isLoadingViewID, setIsLoadingViewID] = useState(null)

    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'status',
                header: () => <Text>Nombre</Text>,
            },
            {
                accessorKey: 'name',
                header: () => <Text>Nombre</Text>,
                cell: info => info.getValue(),
            },
            {
                accessorKey: 'actions',
                header: () => <Text>Acciones</Text>,
            },
        ],
        [],
    )

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
        },
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    })

    useEffect(() => {
        setMounted(true)
        table.setPageSize(5)
    }, [])

    if (!mounted) return null

    const handleView = async file_id => {
        setIsLoadingView(true)
        setIsLoadingViewID(file_id)
        const download = await viewFile({ file_id })
        const link = document.createElement('a')
        link.href = download
        link.target = '_blank'
        document.body.appendChild(link)
        link.click()
        setIsLoadingView(false)
        setIsLoadingViewID(null)
    }

    return (
        <Box>
            <TableContainer mb={2} minH="150px">
                {isLoading ? (
                    <Box
                        w="full"
                        h="280px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center">
                        <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="blue.500"
                            size="xl"
                        />
                    </Box>
                ) : (
                    <>
                        {table.getRowModel().rows.length === 0 ? (
                            <Box
                                h="150px"
                                w="full"
                                display="flex"
                                justifyContent="center"
                                alignItems="center">
                                <Text fontSize={20} fontWeight={500}>
                                    No hay documentos aún
                                </Text>
                            </Box>
                        ) : (
                            <Table
                                variant="unstyled"
                                layout="fixed"
                                size={'sm'}>
                                <colgroup>
                                    <col style={{ width: '5%' }} />
                                    <col style={{ width: '85%' }} />
                                    <col style={{ width: '10%' }} />
                                </colgroup>
                                <Tbody>
                                    {table.getRowModel().rows.map(row => {
                                        let circleColor = '#D9D9D9'
                                        let textColor = '#979797'
                                        let textToShow = 'Enviado'

                                        if (row.original.uuid) {
                                            circleColor = colors.secondary
                                            textColor = colors.secondary
                                            textToShow = 'Documento cargado'
                                        } else if (
                                            !row.original.uuid &&
                                            row.original.path
                                        ) {
                                            circleColor = colors['success-high']
                                            textColor = colors['success-high']
                                            textToShow = 'Firmado'
                                        }

                                        return (
                                            <Tr
                                                key={row.id}
                                                color="#474747"
                                                borderBottomWidth={1}>
                                                <Td>
                                                    <CircleIcon
                                                        color={circleColor}
                                                    />
                                                </Td>
                                                <Td
                                                    fontSize={12}
                                                    fontWeight={500}>
                                                    <Box w="full" width="100%">
                                                        <Text
                                                            noOfLines={1}
                                                            title={
                                                                row.original
                                                                    .name
                                                            }>
                                                            {row.original.name}
                                                        </Text>
                                                    </Box>

                                                    <Box
                                                        display="flex"
                                                        mt={1}
                                                        fontSize={10}>
                                                        <Text color={textColor}>
                                                            {textToShow}
                                                        </Text>
                                                        <Text
                                                            ml={2}
                                                            color="#A3A3A3">
                                                            {new Date(
                                                                row.original.updated_at,
                                                            ).toLocaleString(
                                                                'es-ES',
                                                                {
                                                                    day:
                                                                        '2-digit',
                                                                    month:
                                                                        'short',
                                                                    year:
                                                                        'numeric',
                                                                    hour12: false,
                                                                },
                                                            )}
                                                        </Text>
                                                    </Box>
                                                </Td>
                                                {row.original.path && (
                                                    <Td
                                                        display="flex"
                                                        justifyContent="space-between"
                                                        px={2}>
                                                        {isLoadingViewID &&
                                                        isLoadingViewID ===
                                                            row.original.id ? (
                                                            <Spinner
                                                                thickness="4px"
                                                                speed="0.65s"
                                                                emptyColor="gray.200"
                                                                color="blue.500"
                                                            />
                                                        ) : (
                                                            <EyeLine
                                                                cursor="pointer"
                                                                transform="scale(0.85)"
                                                                color={
                                                                    colors[
                                                                        'gray-primary'
                                                                    ]
                                                                }
                                                                onClick={() =>
                                                                    handleView(
                                                                        row
                                                                            .original
                                                                            .id,
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    </Td>
                                                )}
                                            </Tr>
                                        )
                                    })}
                                </Tbody>
                            </Table>
                        )}
                    </>
                )}
            </TableContainer>

            {table.getPageCount() > 1 && <Pagination table={table} />}
        </Box>
    )
}
