import React, { FC } from 'react'

import { FormikProps } from 'formik'
import {
    Box,
    FormLabel,
    FormControl,
    Input,
    FormErrorMessage,
    chakra,
} from '@chakra-ui/react'

interface Props {
    formik: FormikProps<FormikValues>
    segment: number
}

interface FormikValues {
    email: string
    phone: string
    name: string
    representatives: Representative[]
    client_type: string
}

interface Representative {
    name: string
    lastname: string
    second_lastname: string
    dni: string
    phone: string
    email: string
    representative_position: string
}

export const RepresentativeA: FC<Props> = ({ formik, segment }) => {
    return (
        <Box mt={8} w="90%" color="black">
            <FormControl
                my={4}
                isInvalid={
                    Array.isArray(formik.errors.representatives) &&
                    // @ts-ignore
                    formik.errors?.representatives[0]?.name &&
                    Array.isArray(formik.touched.representatives) &&
                    formik.touched.representatives[0]?.name
                }>
                <FormLabel fontSize={13} fontWeight={700}>
                    Nombre del representante{' '}
                    <chakra.span style={{ color: '#FD7C04' }}>*</chakra.span>
                </FormLabel>
                <Input
                    id="representatives[0].name"
                    placeholder="Nombre"
                    rounded="4px"
                    size="xs"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.representatives[0].name}
                />
                {Array.isArray(formik.errors.representatives) && (
                    <FormErrorMessage>
                        {formik.errors.representatives.map(error => error.name)}
                    </FormErrorMessage>
                )}
            </FormControl>

            <FormControl
                my={4}
                isInvalid={
                    Array.isArray(formik.errors.representatives) &&
                    // @ts-ignore
                    formik.errors?.representatives[0]?.lastname &&
                    Array.isArray(formik.touched.representatives) &&
                    formik.touched.representatives[0]?.lastname
                }>
                <FormLabel fontSize={13} fontWeight={700}>
                    Primer apellido del representante{' '}
                    <chakra.span style={{ color: '#FD7C04' }}>*</chakra.span>
                </FormLabel>
                <Input
                    id="representatives[0].lastname"
                    placeholder="Primer apellido"
                    rounded="4px"
                    size="xs"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.representatives[0].lastname}
                />
                {Array.isArray(formik.errors.representatives) && (
                    <FormErrorMessage>
                        {formik.errors.representatives.map(
                            error => error.lastname,
                        )}
                    </FormErrorMessage>
                )}
            </FormControl>

            <FormControl my={4}>
                <FormLabel fontSize={13} fontWeight={700}>
                    Segundo apellido del representante
                </FormLabel>
                <Input
                    id="representatives[0].second_lastname"
                    placeholder="Segundo apellido"
                    rounded="4px"
                    size="xs"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={
                        formik.values.representatives[0].second_lastname || ''
                    }
                />
            </FormControl>

            <FormControl
                mb={4}
                isInvalid={
                    Array.isArray(formik.errors.representatives) &&
                    // @ts-ignore
                    formik.errors?.representatives[0]?.dni &&
                    Array.isArray(formik.touched.representatives) &&
                    formik.touched.representatives[0]?.dni
                }>
                <FormLabel fontSize={13} fontWeight={700}>
                    NIF{' '}
                    <chakra.span style={{ color: '#FD7C04' }}> *</chakra.span>
                </FormLabel>
                <Input
                    id="representatives[0].dni"
                    placeholder="NIF"
                    rounded="4px"
                    size="xs"
                    onChange={formik.handleChange}
                    value={formik.values.representatives[0].dni}
                />
                {Array.isArray(formik.errors.representatives) && (
                    <FormErrorMessage>
                        {formik.errors.representatives.map(error => error.dni)}
                    </FormErrorMessage>
                )}
            </FormControl>

            {segment === 1 && (
                <>
                    <FormControl
                        mb={4}
                        isInvalid={
                            Array.isArray(formik.errors.representatives) &&
                            // @ts-ignore
                            formik.errors?.representatives[0]?.phone &&
                            Array.isArray(formik.touched.representatives) &&
                            formik.touched.representatives[0]?.phone
                        }>
                        <FormLabel fontSize={13} fontWeight={700}>
                            Teléfono
                            <chakra.span style={{ color: '#FD7C04' }}>
                                {' '}
                                *
                            </chakra.span>
                        </FormLabel>
                        <Input
                            id="representatives[0].phone"
                            placeholder="Teléfono"
                            rounded="4px"
                            size="xs"
                            onChange={formik.handleChange}
                            value={formik.values.representatives[0].phone}
                        />
                        {Array.isArray(formik.errors.representatives) && (
                            <FormErrorMessage>
                                {formik.errors.representatives.map(
                                    error => error.phone,
                                )}
                            </FormErrorMessage>
                        )}
                    </FormControl>

                    <FormControl
                        mb={4}
                        isInvalid={
                            Array.isArray(formik.errors.representatives) &&
                            // @ts-ignore
                            formik.errors?.representatives[0]?.email &&
                            Array.isArray(formik.touched.representatives) &&
                            formik.touched.representatives[0]?.email
                        }>
                        <FormLabel fontSize={13} fontWeight={700}>
                            Correo electrónico
                            <chakra.span style={{ color: '#FD7C04' }}>
                                {' '}
                                *
                            </chakra.span>
                        </FormLabel>
                        <Input
                            id="representatives[0].email"
                            placeholder="Correo electrónico"
                            rounded="4px"
                            size="xs"
                            onChange={formik.handleChange}
                            value={formik.values.representatives[0].email}
                        />
                        {Array.isArray(formik.errors.representatives) && (
                            <FormErrorMessage>
                                {formik.errors.representatives.map(
                                    error => error.email,
                                )}
                            </FormErrorMessage>
                        )}
                    </FormControl>
                </>
            )}
        </Box>
    )
}
