import * as Yup from 'yup'

export const FastOpportunitySchema = Yup.object().shape({
    title: Yup.string().required('El título es requerido'),
    titleNumber: Yup.string().required('El número es requerido'),
    segment: Yup.number().required('El número de empleados es requerido'),
    distributor: Yup.number().required('El distribuidor es requerido'),
    phone: Yup.string()
        .min(10, 'Mínimo 10 caracteres')
        .max(14, 'Maximo 14 caracteres')
        .required('El teléfono es requerido'),
    email: Yup.string()
        .email('Correo inválido')
        .required('El correo es requerido'),

    // Client data
    client_type: Yup.string(),
    name: Yup.string().required('El nombre es requerido'),
    lastName: Yup.string().required('El apellido es requerido'),
    dni: Yup.string().required('El número de identificación es requerida'),
    postal_code: Yup.string().required('El código postal es requerido'),
    town: Yup.string().required('La población es requerida'),
    province: Yup.string().required('La provincia es requerida'),
    home_address: Yup.string().required('La dirección es requerida'),
    antiquity: Yup.string().required(
        'La antigüedad de la empresa es requerida',
    ),
    enterprise_created_date: Yup.string(),

    representatives: Yup.array().of(
        Yup.object({
            name: Yup.string().required('El nombre del contacto es requerido'),
            lastname: Yup.string().required(
                'El primer apellido del contacto es requerido',
            ),
            second_lastname: Yup.string(),
            dni: Yup.string().required(
                'El número de identificación del contacto es requerida',
            ),
            position: Yup.string(),
        }),
    ),

    licenses: Yup.array().of(
        Yup.object({
            license: Yup.string().required('La licencia es requerida'),
            email: Yup.string()
                .email('Correo inválido')
                .required('La licencia es requerida'),
        }),
    ),
    collaborators: Yup.array().of(
        Yup.object({
            name: Yup.string().required('El nombre es requerido'),
            lastName: Yup.string().required('El apellido es requerido'),
            dni: Yup.string().required('El dni es requerido'),
        }),
    ),
    has_printer: Yup.string(),
    has_laptop: Yup.string(),
})
