import React, { FC, useState } from 'react'

import {
    Button,
    Divider,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Text,
    ModalHeader,
    Box,
} from '@chakra-ui/react'

import { colors } from '@/utils/const'

interface Props {
    isLoading: boolean
    isOpen: boolean
    onClose: () => void
    handleDeleteNote: () => void
}

export const DeleteNote: FC<Props> = ({
    isLoading,
    isOpen,
    onClose,
    handleDeleteNote,
}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="md">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader
                    fontSize={20}
                    fontWeight={400}
                    textAlign="center"
                    pt={6}>
                    ¿Eliminar archivo?
                </ModalHeader>
                <ModalBody>
                    <Box display="flex" justifyContent="center">
                        <Box w="75%" textAlign="center">
                            <Text
                                color="#565758"
                                fontSize={16}
                                fontWeight={400}
                                mb={4}>
                                La eliminación de un archivo lo elimina también
                                de cualquier registro.
                            </Text>
                        </Box>
                    </Box>
                </ModalBody>

                <Divider />

                <ModalFooter justifyContent="center" py={4}>
                    <Button
                        bg="white"
                        border="1px"
                        borderColor={colors.secondary}
                        color={colors.secondary}
                        fontSize={14}
                        fontWeight={400}
                        mr={8}
                        h="32px"
                        w={119}
                        onClick={onClose}
                        _active={{
                            transform: 'scale(0.98)',
                        }}
                        _hover={{}}>
                        No
                    </Button>

                    <Button
                        bg={colors.secondary}
                        border="1px"
                        color="white"
                        fontSize={14}
                        isLoading={isLoading}
                        fontWeight={400}
                        h="32px"
                        w={119}
                        onClick={handleDeleteNote}
                        _active={{
                            transform: 'scale(0.98)',
                        }}
                        _hover={{}}>
                        Sí, eliminalo
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
