import React, { FC } from 'react'
import NextLink from 'next/link'

import {
    Box,
    Link,
    ListItem,
    Spinner,
    Text,
    UnorderedList,
} from '@chakra-ui/react'

import { colors } from '@/utils/const'
import { useGetEvents } from '@/hooks/opportunities'

interface Props {
    opportunity_id: number
}

export const EventsCard: FC<Props> = ({ opportunity_id }) => {
    const { events, isLoading } = useGetEvents({ opportunity_id })

    return (
        <Box
            bg="white"
            borderRadius="md"
            borderWidth={1}
            boxShadow="lg"
            fontSize={13}
            h="full"
            w="100%">
            <Text
                bg={colors.primary}
                color="white"
                fontWeight={700}
                px={3}
                py={1.5}
                roundedTop="md"
                w="full">
                Eventos
            </Text>
            {isLoading ? (
                <Box
                    alignItems="center"
                    display="flex"
                    h="80%"
                    justifyContent="center"
                    fontSize={14}
                    fontWeight={500}>
                    <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="md"
                    />
                </Box>
            ) : (
                <>
                    {events?.length === 0 ? (
                        <Box
                            alignItems="center"
                            display="flex"
                            h="80%"
                            justifyContent="center"
                            fontSize={14}
                            fontWeight={500}>
                            <Text>
                                No hay <b>eventos</b> aún
                            </Text>
                        </Box>
                    ) : (
                        <Box h="calc(100% - 30px)">
                            <UnorderedList
                                p={4}
                                h="calc(100% - 30px)"
                                overflow="hidden">
                                {events
                                    ?.sort((a, b) => {
                                        // @ts-ignore
                                        return (
                                            new Date(b.created_at).getTime() -
                                            new Date(a.created_at).getTime()
                                        )
                                    })
                                    .map((item, index) => {
                                        const date = new Date(
                                            item.created_at,
                                        ).toLocaleString('es-ES', {
                                            day: 'numeric',
                                            month: 'short',
                                            hour12: false,
                                        })

                                        let hour = new Date(
                                            item.created_at,
                                        ).toLocaleTimeString('es-Es', {
                                            minute: '2-digit',
                                            hour: '2-digit',
                                            hour12: true,
                                        })

                                        if (hour.slice(0, 2) === '00') {
                                            hour = `12${hour.slice(2)}`
                                        }

                                        return (
                                            <ListItem
                                                key={index}
                                                fontWeight={500}>
                                                <Text>{item.description}</Text>
                                                <Text
                                                    fontSize={10}
                                                    color={
                                                        colors['black-medium']
                                                    }>
                                                    {date}
                                                    {' - '}
                                                    {hour}
                                                </Text>
                                            </ListItem>
                                        )
                                    })}
                            </UnorderedList>
                            <Box
                                color={colors.secondary}
                                display="flex"
                                fontWeight={500}
                                justifyContent="center"
                                w="full">
                                <NextLink
                                    href={`/opportunities/events/${opportunity_id}`}
                                    passHref>
                                    <Link>Ver más</Link>
                                </NextLink>
                            </Box>
                        </Box>
                    )}
                </>
            )}
        </Box>
    )
}
