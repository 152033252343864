import React, { FC } from 'react'

import { FormikProps } from 'formik'
import {
    Text,
    Box,
    FormLabel,
    FormControl,
    Input,
    FormErrorMessage,
    Select,
    chakra,
} from '@chakra-ui/react'

interface Props {
    formik: FormikProps<FormikValues>
}

interface FormikValues {
    resolution_date: string
    amount: string
    caducity_date: string
    bonus_expedient_number: string
}

const SIX_MONTHS = 15634800000

export const ApprovedBonusStep: FC<Props> = ({ formik }) => {
    const handleSetDate = e => {
        formik.setFieldValue('resolution_date', e.target.value)

        const unixTime = new Date(e.target.value).getTime()

        formik.setFieldValue(
            'caducity_date',
            new Date(unixTime + SIX_MONTHS).toJSON().split('T')[0],
        )
    }

    return (
        <Box w="full" mt={4} color="black">
            <Box px="20px" mb={4}>
                <FormControl
                    mb={4}
                    isInvalid={
                        formik.errors.resolution_date &&
                        formik.touched.resolution_date
                    }>
                    <FormLabel fontSize={13} fontWeight={500}>
                        Fecha de resolución
                    </FormLabel>
                    <Input
                        id="resolution_date"
                        placeholder="Fecha de resolución"
                        rounded="4px"
                        size="sm"
                        type="date"
                        value={formik.values.resolution_date}
                        onChange={handleSetDate}
                    />
                    <FormErrorMessage>
                        {formik.errors.resolution_date}
                    </FormErrorMessage>
                </FormControl>

                <FormControl
                    mb={4}
                    isInvalid={formik.errors.amount && formik.touched.amount}>
                    <FormLabel fontSize={13} fontWeight={500}>
                        Importe
                    </FormLabel>
                    <Input
                        fontSize={13}
                        fontWeight={500}
                        id="amount"
                        placeholder="Importe"
                        rounded="4px"
                        size="sm"
                        value={formik.values.amount}
                        onChange={formik.handleChange}
                    />
                    <FormErrorMessage>{formik.errors.amount}</FormErrorMessage>
                </FormControl>

                <FormControl
                    mb={4}
                    isInvalid={
                        formik.errors.bonus_expedient_number &&
                        formik.touched.bonus_expedient_number
                    }>
                    <FormLabel fontSize={13} fontWeight={500}>
                        Número de expediente
                    </FormLabel>
                    <Input
                        fontSize={13}
                        fontWeight={500}
                        id="bonus_expedient_number"
                        placeholder="Número de expediente"
                        rounded="4px"
                        size="sm"
                        value={formik.values.bonus_expedient_number}
                        onChange={formik.handleChange}
                    />
                    <FormErrorMessage>
                        {formik.errors.bonus_expedient_number}
                    </FormErrorMessage>
                </FormControl>

                <FormControl
                    mb={4}
                    isInvalid={
                        formik.errors.caducity_date &&
                        formik.touched.caducity_date
                    }>
                    <FormLabel fontSize={13} fontWeight={500}>
                        Fecha de caducidad
                    </FormLabel>
                    <Input
                        id="caducity_date"
                        placeholder="Fecha de caducidad"
                        rounded="4px"
                        size="sm"
                        type="date"
                        value={formik.values.caducity_date}
                        onChange={formik.handleChange}
                    />
                    <FormErrorMessage>
                        {formik.errors.caducity_date}
                    </FormErrorMessage>
                </FormControl>
            </Box>
        </Box>
    )
}
