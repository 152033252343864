import React, { FC } from 'react'

import { FormikProps } from 'formik'
import { Text, Box, FormControl, FormLabel, Select } from '@chakra-ui/react'

import { RepresentativeType, VolunteerRepresentatives } from '@/interfaces'

interface Props {
    formik: FormikProps<FormikValues>
    representativesTypes: RepresentativeType
    volunteerRepresentatives: VolunteerRepresentatives[]
}

interface FormikValues {
    email: string
    phone: string
    name: string
    representatives: Representative[]
    client_type: string
    representative_volunteer_id: number
}

interface Representative {
    representative_position: string
}

export const Step5: FC<Props> = ({
    formik,
    representativesTypes,
    volunteerRepresentatives,
}) => {
    return (
        <Box w="full" mt={4} color="black">
            <Box
                w="90%"
                display="flex"
                justifyContent="space-between"
                fontSize={13}
                fontWeight={400}>
                <Box display="flex">
                    <Text mr={4}>Cliente</Text>
                    <Text fontWeight={700}>
                        {formik.values.client_type === 'a'
                            ? 'Autónomo'
                            : 'Empresa'}
                    </Text>
                </Box>
                {formik.values.client_type === 's' && (
                    <Box display="flex">
                        <Text mr={4}>Representante</Text>
                        <Text fontWeight={700}>
                            {
                                representativesTypes[
                                    formik.values.representatives[0]
                                        .representative_position
                                ].type
                            }
                        </Text>
                    </Box>
                )}
            </Box>
            <FormControl h="120px" mt={10}>
                <FormLabel fontSize={13} fontWeight={700}>
                    Representante voluntario
                </FormLabel>

                <Select
                    id="representative_volunteer_id"
                    color="#4F4F4F"
                    fontSize={13}
                    value={formik.values.representative_volunteer_id}
                    onChange={formik.handleChange}
                    rounded="md"
                    size="xs">
                    {volunteerRepresentatives.map((item, index) => {
                        if (item.id === 1) return null
                        if (item.id === 2) return null
                        if (item.id === 3) return null
                        return (
                            <option key={index} value={item.id}>
                                {item.name}
                            </option>
                        )
                    })}
                </Select>
            </FormControl>
        </Box>
    )
}
