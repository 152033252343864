import React, { FC, SetStateAction, useState } from 'react'

import { useSWRConfig } from 'swr'
import {
    Button,
    Divider,
    Input,
    Textarea,
    InputGroup,
    InputLeftElement,
    FormLabel,
    Text,
    Box,
    useToast,
    Spinner,
} from '@chakra-ui/react'

import { colors } from '@/utils/const'
import { generateURLClient, useOpportunity } from '@/hooks/opportunities'
import { useFormik } from 'formik'

interface Props {
    opportunity_id: number
    setShowAmountCard: React.Dispatch<SetStateAction<boolean>>
}

export const AmountCard: FC<Props> = ({
    opportunity_id,
    setShowAmountCard,
}) => {
    const {
        opportunity,
        updateOpportunity,
        setStatusOpportunity,
    } = useOpportunity({
        id: opportunity_id,
    })
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingURL, setIsLoadingURL] = useState(false)
    const toast = useToast()
    const { mutate } = useSWRConfig()

    const formik = useFormik({
        initialValues: {
            amount: opportunity?.amount || '0.00',
            description: opportunity?.description || '',
            prev_close_date:
                opportunity?.prev_close_date || opportunity?.end_date || '',
        },
        onSubmit: async values => {
            setIsLoading(true)

            try {
                await updateOpportunity({
                    opportunity_id,
                    data: {
                        amount: values.amount,
                        description: values.description,
                        prev_close_date: values.prev_close_date,
                    },
                })

                if (opportunity?.status === 'pending') {
                    await setStatusOpportunity({
                        opportunity_id,
                        status: 'proccess',
                    })
                }

                toast({
                    title: 'Éxito',
                    description: 'Oportunidad actualizada con éxito',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })
            } catch (error) {
                toast({
                    title: 'Error.',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })

                formik.resetForm()
            }

            setIsLoading(false)
        },
    })

    const formatNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value

        if (value.includes('.')) {
            value.split('.')[1]
            if (value.split('.')[1].length > 2) {
                value = (parseFloat(value) * 10).toFixed(2).toString()
            }
        }
        formik.setFieldValue('amount', value)
    }

    const handleGenerate = async () => {
        setIsLoadingURL(true)

        try {
            await generateURLClient({
                opportunity_id: opportunity?.id,
            })

            mutate(`/api/opportunity/${opportunity?.id}`)
            toast({
                title: 'Éxito',
                description: 'URL generada con éxito',
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
        } catch (error) {
            toast({
                title: 'Error.',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })

            formik.resetForm()
        }

        setIsLoadingURL(false)
    }

    return (
        <Box
            bg="white"
            w="100%"
            borderWidth={1}
            borderRadius="md"
            boxShadow="lg">
            <form onSubmit={formik.handleSubmit}>
                <Box px={6} py={4} color="#474747">
                    <Box display="flex" justifyContent="space-between" mb={4}>
                        <Text fontSize={16} fontWeight={400}>
                            Datos requeridos
                        </Text>

                        {opportunity?.status === 'proccess' &&
                            !opportunity?.url_landing && (
                                <>
                                    {!isLoadingURL ? (
                                        <Button
                                            color={colors.secondary}
                                            fontSize={14}
                                            fontWeight={600}
                                            variant="link"
                                            onClick={handleGenerate}>
                                            Generar URL Cliente
                                        </Button>
                                    ) : (
                                        <Box
                                            width="100px"
                                            display="flex"
                                            justifyContent="center">
                                            <Spinner
                                                thickness="4px"
                                                speed="0.65s"
                                                emptyColor="gray.200"
                                                color="blue.500"
                                            />
                                        </Box>
                                    )}
                                </>
                            )}
                    </Box>

                    {[
                        'pending',
                        'proccess',
                        'qualified',
                        'qualified_error',
                        'qualified_pending',
                    ].includes(opportunity?.status) ? (
                        <>
                            <Box>
                                <Box>
                                    <FormLabel
                                        ml={2}
                                        fontSize={13}
                                        fontWeight={500}>
                                        Importe
                                    </FormLabel>
                                    <InputGroup mb={4}>
                                        <InputLeftElement
                                            pointerEvents="none"
                                            color="gray"
                                            fontSize="1.2em"
                                            children="€"
                                        />
                                        <Input
                                            id="amount"
                                            type="number"
                                            onChange={formatNumber}
                                            placeholder="Importe"
                                            value={formik.values.amount}
                                        />
                                    </InputGroup>

                                    <FormLabel
                                        ml={2}
                                        fontSize={13}
                                        fontWeight={500}>
                                        Fecha prevista de cierre de la
                                        oportunidad
                                    </FormLabel>
                                    <Input
                                        id="prev_close_date"
                                        mb={4}
                                        onChange={formik.handleChange}
                                        type="date"
                                        value={formik.values.prev_close_date}
                                    />

                                    <FormLabel
                                        ml={2}
                                        fontSize={13}
                                        fontWeight={500}>
                                        Descripción
                                    </FormLabel>
                                    <Textarea
                                        id="description"
                                        mb={6}
                                        onChange={formik.handleChange}
                                        value={formik.values.description}
                                    />
                                </Box>
                            </Box>
                            <Divider />
                            <Box display="flex" justifyContent="center" p={6}>
                                {opportunity?.status === 'pending' && (
                                    <Button
                                        bg="white"
                                        border="1px"
                                        borderColor={colors.secondary}
                                        color={colors.secondary}
                                        fontSize={12}
                                        fontWeight={500}
                                        mr={8}
                                        h="32px"
                                        w={155}
                                        onClick={() => setShowAmountCard(false)}
                                        _active={{
                                            bg: colors.secondary,
                                            color: 'white',
                                            transform: 'scale(0.98)',
                                        }}
                                        _hover={{}}>
                                        Cancelar
                                    </Button>
                                )}
                                <Button
                                    bg={colors.secondary}
                                    border="1px"
                                    borderColor={colors.secondary}
                                    color="white"
                                    fontSize={12}
                                    fontWeight={500}
                                    h="32px"
                                    isLoading={isLoading}
                                    type="submit"
                                    w={155}
                                    _active={{
                                        bg: colors.primary,
                                        borderColor: colors.tertiary,
                                        color: colors.tertiary,
                                        transform: 'scale(0.98)',
                                    }}
                                    _hover={{
                                        bg: colors.secondary,
                                    }}>
                                    Guardar
                                </Button>
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box>
                                <Text
                                    ml={2}
                                    mb={2}
                                    fontSize={13}
                                    fontWeight={500}>
                                    Importe
                                </Text>
                                <Text
                                    ml={2}
                                    mb={2}
                                    fontSize={16}
                                    fontWeight={500}>
                                    {opportunity?.amount
                                        ? `${opportunity.amount} €`
                                        : 'Sin definir'}
                                </Text>
                                <Text
                                    ml={2}
                                    mb={2}
                                    fontSize={13}
                                    fontWeight={500}>
                                    Fecha prevista de cierre de la oportunidad
                                </Text>
                                <Text
                                    ml={2}
                                    mb={2}
                                    fontSize={16}
                                    fontWeight={500}>
                                    {opportunity?.prev_close_date
                                        ? new Date(
                                              opportunity?.prev_close_date,
                                          ).toLocaleString('es-ES', {
                                              day: '2-digit',
                                              month: 'long',
                                              year: 'numeric',
                                              hour12: false,
                                          })
                                        : 'Sin definir'}
                                </Text>
                                <Text
                                    ml={2}
                                    mb={2}
                                    fontSize={13}
                                    fontWeight={500}>
                                    Descripción
                                </Text>
                                <Text
                                    ml={2}
                                    mb={2}
                                    fontSize={16}
                                    fontWeight={400}>
                                    {opportunity?.description
                                        ? opportunity.description
                                        : 'No tiene'}
                                </Text>
                            </Box>
                        </>
                    )}
                </Box>
            </form>
        </Box>
    )
}
