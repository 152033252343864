import React, { FC } from 'react'

import { FormikProps } from 'formik'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {
    Text,
    Box,
    FormLabel,
    FormControl,
    Input,
    FormErrorMessage,
    Select,
    chakra,
} from '@chakra-ui/react'

import { RepresentativeType } from '@/interfaces'

interface Props {
    formik: FormikProps<FormikValues>
    representativesTypes: RepresentativeType
}

interface FormikValues {
    email: string
    phone: string
    name: string
    representatives?: Representative[]
    client_type: string
}

interface Representative {
    representative_position: string
}

export const Step3: FC<Props> = ({ formik, representativesTypes }) => {
    const handleChange = event => {
        let newRepresentatives = formik.values.representatives[0]
        newRepresentatives.representative_position = event.target.value
        formik.setFieldValue('representatives', [newRepresentatives])
    }

    return (
        <Box w="full" mt={4} color="black">
            <Box display="flex" fontSize={13} fontWeight={400}>
                <Text mr={4}>Cliente</Text>
                <Text fontWeight={700}>
                    {formik.values.client_type === 'a' ? 'Autónomo' : 'Empresa'}
                </Text>
            </Box>

            <Box my={6} ml={4} w="80%">
                <FormControl
                    my={4}
                    isInvalid={formik.errors.email && formik.touched.email}>
                    <FormLabel fontSize={13} fontWeight={700}>
                        Email
                        <chakra.span style={{ color: '#FD7C04' }}>
                            {' '}
                            *
                        </chakra.span>
                    </FormLabel>
                    <Input
                        id="email"
                        placeholder="Email"
                        rounded="4px"
                        size="xs"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                    />
                    <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                </FormControl>

                <FormControl
                    isInvalid={formik.errors.phone && formik.touched.phone}>
                    <FormLabel fontSize={13} fontWeight={700}>
                        Móvil
                        <chakra.span style={{ color: '#FD7C04' }}>
                            {' '}
                            *
                        </chakra.span>
                    </FormLabel>
                    <PhoneInput
                        inputStyle={{
                            height: '25px',
                            width: '100%',
                        }}
                        country={'es'}
                        value={formik.values.phone}
                        onChange={phone => formik.setFieldValue('phone', phone)}
                    />
                    <FormErrorMessage>{formik.errors.phone}</FormErrorMessage>
                </FormControl>

                {formik.values.client_type === 's' &&
                    formik.values.representatives && (
                        <FormControl mt={4}>
                            <FormLabel fontSize={13} fontWeight={700}>
                                Cargo del representante
                            </FormLabel>

                            <Select
                                id="representatives[0].representative_position"
                                color="#4F4F4F"
                                value={
                                    formik.values.representatives[0]
                                        .representative_position
                                }
                                fontSize={13}
                                onChange={handleChange}
                                rounded="md"
                                size="xs">
                                {Object.values(representativesTypes).map(
                                    (item, index) => (
                                        <option key={index} value={item.name}>
                                            {item.label}
                                        </option>
                                    ),
                                )}
                            </Select>
                        </FormControl>
                    )}
            </Box>
        </Box>
    )
}
