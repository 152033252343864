import React, { FC, useState } from 'react'

import { FormikProps } from 'formik'
import { Box, Radio, RadioGroup, Select, Stack, Text } from '@chakra-ui/react'
import { ProfilesQuestions } from '@/interfaces'

interface Props {
    formik: FormikProps<FormikValues>
    profilesQuestions: ProfilesQuestions[]
}

interface FormikValues {
    profile: boolean
    question_profile_id: number
}

const initialValues = {
    1: { answer_id: null },
    2: { answer_id: null },
    3: { answer_id: null },
    4: { answer_id: null },
    5: { answer_id: null },
    6: { answer_id: null },
    7: { answer_id: null },
    8: { answer_id: null },
    9: { answer_id: null },
    10: { answer_id: null },
    11: { answer_id: null },
    12: { answer_id: null },
    13: { answer_id: null },
}

export const PerfilStep: FC<Props> = ({ formik, profilesQuestions }) => {
    const [value, setValue] = useState(formik.values.profile ? 'Si' : 'No')

    const handlePerfilSelection = () => {
        if (value === 'Si') {
            setValue('No')
            formik.setFieldValue('profile', false)
            formik.setFieldValue('question_profile_id', null)
            formik.setFieldValue('question_test', initialValues)
        } else {
            setValue('Si')
            formik.setFieldValue('profile', true)
        }
    }

    const handleSelect = e => {
        const profileFound = profilesQuestions.find(
            item => item.id === parseInt(e.target.value),
        )

        if (profileFound) {
            formik.setFieldValue(
                'question_profile_id',
                parseInt(e.target.value),
            )
            formik.setFieldValue('question_test', { ...profileFound.data })
        } else {
            formik.setFieldValue('question_profile_id', null)
            formik.setFieldValue('question_test', initialValues)
        }
    }

    return (
        <Box
            minH="180px"
            mt={8}
            mb={4}
            color="black"
            fontSize={16}
            fontWeight={500}>
            <Box display="flex">
                <Text mr={6}>¿Seleccionar Perfil?</Text>

                <RadioGroup onChange={handlePerfilSelection} value={value}>
                    <Stack direction="row">
                        <Radio value="Si">Si</Radio>
                        <Radio value="No">No</Radio>
                    </Stack>
                </RadioGroup>
            </Box>

            {formik.values.profile && (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={8}>
                    <Text mr={4}>Perfil</Text>

                    <Select
                        size="sm"
                        rounded={4}
                        placeholder="Perfil"
                        w={250}
                        defaultValue={formik.values.question_profile_id}
                        onChange={handleSelect}>
                        {profilesQuestions?.map(item => (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </Select>
                </Box>
            )}
        </Box>
    )
}
