import React, { FC } from 'react'

import { useRouter } from 'next/router'

import { FormikProps } from 'formik'
import {
    Text,
    Box,
    FormLabel,
    FormControl,
    Input,
    FormErrorMessage,
    chakra,
    Grid,
    GridItem,
    Button,
} from '@chakra-ui/react'

interface Props {
    formik: FormikProps<FormikValues>
    client_id?: number
    onClose?: () => void
}

interface FormikValues {
    name: string
    lastName: string
    home_address: string
    dni: string
    postal_code: string
    province: string
    town: string
    client_type: string
}

export const Step2: FC<Props> = ({
    formik,
    client_id = undefined,
    onClose,
}) => {
    const router = useRouter()

    const handleClick = () => {
        onClose()
        router.push(`/opportunities/${client_id}`)
    }

    return (
        <Box w="full" mt={4} color="black">
            <Box display="flex" fontSize={13} fontWeight={400}>
                <Text mr={4}>Cliente</Text>
                <Text fontWeight={700}>
                    {formik.values.client_type === 'a' ? 'Autónomo' : 'Empresa'}
                </Text>
            </Box>

            <Box mt={6} ml={4} w="80%">
                <FormControl
                    mb={4}
                    isInvalid={formik.errors.name && formik.touched.name}>
                    <FormLabel fontSize={13} fontWeight={700}>
                        {formik.values.client_type === 's'
                            ? 'Nombre de empresa'
                            : 'Nombre'}
                        <chakra.span style={{ color: '#FD7C04' }}>
                            *
                        </chakra.span>
                    </FormLabel>
                    <Input
                        id="name"
                        placeholder={
                            formik.values.client_type === 's'
                                ? 'Nombre de empresa'
                                : 'Nombre'
                        }
                        rounded="4px"
                        size="xs"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                    />
                    <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                </FormControl>

                {formik.values.client_type === 'a' && (
                    <FormControl
                        mb={4}
                        isInvalid={
                            formik.errors.lastName && formik.touched.lastName
                        }>
                        <FormLabel fontSize={13} fontWeight={700}>
                            Apellido
                            <chakra.span style={{ color: '#FD7C04' }}>
                                *
                            </chakra.span>
                        </FormLabel>
                        <Input
                            id="lastName"
                            placeholder="Apellido"
                            rounded="4px"
                            size="xs"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastName}
                        />
                        <FormErrorMessage>
                            {formik.errors.lastName}
                        </FormErrorMessage>
                    </FormControl>
                )}

                <FormControl
                    mb={4}
                    isInvalid={formik.errors.dni && formik.touched.dni}>
                    <FormLabel fontSize={13} fontWeight={700}>
                        {formik.values.client_type === 's' ? 'CIF' : 'NIF'}
                        <chakra.span style={{ color: '#FD7C04' }}>
                            {' '}
                            *
                        </chakra.span>
                    </FormLabel>
                    <Input
                        id="dni"
                        placeholder={
                            formik.values.client_type === 's' ? 'CIF' : 'NIF'
                        }
                        rounded="4px"
                        size="xs"
                        onChange={formik.handleChange}
                        value={formik.values.dni}
                        onBlur={formik.handleBlur}
                    />
                    <FormErrorMessage>{formik.errors.dni}</FormErrorMessage>
                    {formik.errors.dni && client_id && (
                        <Button
                            variant="link"
                            color="secondary"
                            fontSize={13}
                            fontWeight={500}
                            onClick={handleClick}
                            _active={{
                                transform: 'scale(0.98)',
                            }}
                            _hover={{}}>
                            Ir a detalle
                        </Button>
                    )}
                </FormControl>

                <FormControl
                    mb={4}
                    isInvalid={
                        formik.errors.home_address &&
                        formik.touched.home_address
                    }>
                    <FormLabel fontSize={13} fontWeight={700}>
                        Domicilio
                        <chakra.span style={{ color: '#FD7C04' }}>
                            {' '}
                            *
                        </chakra.span>
                    </FormLabel>
                    <Input
                        id="home_address"
                        placeholder="Domicilio"
                        rounded="4px"
                        size="xs"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.home_address}
                    />
                    <FormErrorMessage>
                        {formik.errors.home_address}
                    </FormErrorMessage>
                </FormControl>

                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                    <GridItem w="100%" mr={4}>
                        <FormControl
                            mb={4}
                            isInvalid={
                                formik.errors.postal_code &&
                                formik.touched.postal_code
                            }>
                            <FormLabel fontSize={13} fontWeight={700}>
                                CP
                                <chakra.span style={{ color: '#FD7C04' }}>
                                    {' '}
                                    *
                                </chakra.span>
                            </FormLabel>
                            <Input
                                id="postal_code"
                                placeholder="CP"
                                rounded="4px"
                                size="xs"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.postal_code}
                            />
                            <FormErrorMessage>
                                {formik.errors.postal_code}
                            </FormErrorMessage>
                        </FormControl>
                    </GridItem>

                    <GridItem w="100%">
                        <FormControl
                            isInvalid={
                                formik.errors.town && formik.touched.town
                            }>
                            <FormLabel fontSize={13} fontWeight={700}>
                                Población
                                <chakra.span style={{ color: '#FD7C04' }}>
                                    {' '}
                                    *
                                </chakra.span>
                            </FormLabel>
                            <Input
                                id="town"
                                placeholder="Población"
                                rounded="4px"
                                size="xs"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.town}
                            />
                            <FormErrorMessage>
                                {formik.errors.town}
                            </FormErrorMessage>
                        </FormControl>
                    </GridItem>
                </Grid>

                <Box w="46%">
                    <FormControl
                        isInvalid={
                            formik.errors.province && formik.touched.province
                        }>
                        <FormLabel fontSize={13} fontWeight={700}>
                            Provincia
                            <chakra.span style={{ color: '#FD7C04' }}>
                                {' '}
                                *
                            </chakra.span>
                        </FormLabel>
                        <Input
                            id="province"
                            placeholder="Provincia"
                            rounded="4px"
                            size="xs"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.province}
                        />
                        <FormErrorMessage>
                            {formik.errors.province}
                        </FormErrorMessage>
                    </FormControl>
                </Box>
            </Box>
        </Box>
    )
}
