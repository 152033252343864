import React, { FC, useState } from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useSWRConfig } from 'swr'
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Text,
    IconButton,
    FormControl,
    FormErrorMessage,
    Input,
    Button,
    Center,
    useToast,
} from '@chakra-ui/react'

import { Opportunity } from '@/interfaces'
import { EditLine } from '../icons'
import { colors } from '@/utils/const'
import { useClient } from '@/hooks/client'

interface Props {
    opportunity: Opportunity
}

const Schema = Yup.object().shape({
    notary_name: Yup.string().required('El nombre del notario es requerido'),
    notary_lastname: Yup.string().required(
        'El primer apellido del notario es requerido',
    ),
    notary_second_lastname: Yup.string().required(
        'El segundo apellido del notario es requerido',
    ),
    notary_protocol_number: Yup.string().required(
        'El número  de protocolo es requerido',
    ),
    notary_protocol_date: Yup.string().required(
        'La fecha de autorización del protocolo es requerido',
    ),
})

export const DeedDataDetails: FC<Props> = ({ opportunity }) => {
    const { mutate } = useSWRConfig()
    const { client, updateClient } = useClient({
        client_id: opportunity?.client?.id,
    })
    const [isEditing, setIsEditing] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const toast = useToast()
    const showIconButton = ['qualified', 'qualified_error'].includes(
        opportunity?.status,
    )
        ? true
        : false

    const formik = useFormik({
        initialValues: {
            notary_name:
                opportunity?.client?.representatives[0]?.notary_name || '',
            notary_lastname:
                opportunity?.client?.representatives[0]?.notary_lastname || '',
            notary_second_lastname:
                opportunity?.client?.representatives[0]
                    ?.notary_second_lastname || '',
            notary_protocol_number:
                opportunity?.client?.representatives[0]
                    ?.notary_protocol_number || '',
            notary_protocol_date:
                opportunity?.client?.representatives[0]?.notary_protocol_date ||
                '',
        },
        validationSchema: Schema,
        onSubmit: async values => {
            setIsLoading(true)

            try {
                await updateClient({
                    client_id: opportunity?.client?.id,
                    data: {
                        representatives: [
                            {
                                ...client.representatives[0],
                                notary_name: values.notary_name,
                                notary_lastname: values.notary_lastname,
                                notary_second_lastname:
                                    values.notary_second_lastname,
                                notary_protocol_number:
                                    values.notary_protocol_number,
                                notary_protocol_date:
                                    values.notary_protocol_date,
                            },
                        ],
                    },
                })

                toast({
                    title: 'Éxito',
                    description: 'Datos actualizados con éxito',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })

                mutate(`/api/opportunity/${opportunity?.id}`)
                setIsEditing(false)
            } catch (error) {
                toast({
                    title: 'Error.',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })

                formik.resetForm()
            }

            setIsLoading(false)
        },
    })

    return (
        <Box
            bg="white"
            w="100%"
            borderWidth={1}
            borderRadius="xl"
            boxShadow="lg">
            <Accordion defaultIndex={[0]} allowMultiple>
                <form onSubmit={formik.handleSubmit}>
                    <AccordionItem>
                        <Box
                            borderBottomWidth={1}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between">
                            <Text
                                ml={4}
                                color="#000"
                                fontSize={14}
                                fontWeight={400}
                                mt={2}>
                                Datos de la escritura
                            </Text>
                            <Box display="flex" alignItems="center">
                                {showIconButton && (
                                    <IconButton
                                        aria-label={''}
                                        bg="transparent"
                                        icon={
                                            <EditLine transform="scale(1.5)" />
                                        }
                                        onClick={() => setIsEditing(!isEditing)}
                                        _active={{}}
                                        _hover={{}}
                                    />
                                )}

                                <AccordionButton>
                                    <AccordionIcon w="35px" h="35px" />
                                </AccordionButton>
                            </Box>
                        </Box>

                        <AccordionPanel px={10}>
                            <Text
                                color="#4F4F4F"
                                fontSize={13}
                                fontWeight={700}
                                mt={2}
                                mb={1}>
                                Nombre del notario
                            </Text>
                            {isEditing ? (
                                <>
                                    <FormControl
                                        mb={4}
                                        isInvalid={
                                            formik.errors.notary_name &&
                                            formik.touched.notary_name
                                        }>
                                        <Input
                                            id="notary_name"
                                            placeholder="Nombre del notario"
                                            w="200px"
                                            rounded="4px"
                                            size="xs"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.notary_name}
                                        />
                                        <FormErrorMessage>
                                            {formik.errors.notary_name}
                                        </FormErrorMessage>
                                    </FormControl>
                                </>
                            ) : (
                                <Text
                                    color="#4F4F4F"
                                    fontSize={12}
                                    fontWeight={400}
                                    ml={4}
                                    mb={4}>
                                    {
                                        opportunity?.client?.representatives[0]
                                            .notary_name
                                    }
                                </Text>
                            )}

                            <Text
                                color="#4F4F4F"
                                fontSize={13}
                                fontWeight={700}
                                mb={1}>
                                Primer apellido notario
                            </Text>
                            {isEditing ? (
                                <>
                                    <FormControl
                                        mb={4}
                                        isInvalid={
                                            formik.errors.notary_lastname &&
                                            formik.touched.notary_lastname
                                        }>
                                        <Input
                                            id="notary_lastname"
                                            placeholder="Primer apellido del notario"
                                            w="200px"
                                            rounded="4px"
                                            size="xs"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={
                                                formik.values.notary_lastname
                                            }
                                        />
                                        <FormErrorMessage>
                                            {formik.errors.notary_lastname}
                                        </FormErrorMessage>
                                    </FormControl>
                                </>
                            ) : (
                                <Text
                                    color="#4F4F4F"
                                    fontSize={12}
                                    fontWeight={400}
                                    ml={4}
                                    mb={4}>
                                    {
                                        opportunity?.client?.representatives[0]
                                            .notary_lastname
                                    }
                                </Text>
                            )}

                            <Text
                                color="#4F4F4F"
                                fontSize={13}
                                fontWeight={700}
                                mb={1}>
                                Segundo apellido notario
                            </Text>
                            {isEditing ? (
                                <>
                                    <FormControl
                                        mb={4}
                                        isInvalid={
                                            formik.errors
                                                .notary_second_lastname &&
                                            formik.touched
                                                .notary_second_lastname
                                        }>
                                        <Input
                                            id="notary_second_lastname"
                                            placeholder="Segundo apellido del notario"
                                            w="200px"
                                            rounded="4px"
                                            size="xs"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={
                                                formik.values
                                                    .notary_second_lastname
                                            }
                                        />
                                        <FormErrorMessage>
                                            {
                                                formik.errors
                                                    .notary_second_lastname
                                            }
                                        </FormErrorMessage>
                                    </FormControl>
                                </>
                            ) : (
                                <Text
                                    color="#4F4F4F"
                                    fontSize={12}
                                    fontWeight={400}
                                    ml={4}
                                    mb={4}>
                                    {
                                        opportunity?.client?.representatives[0]
                                            .notary_second_lastname
                                    }
                                </Text>
                            )}

                            <Text
                                color="#4F4F4F"
                                fontSize={13}
                                fontWeight={700}
                                mb={1}>
                                Número de Protocolo
                            </Text>
                            {isEditing ? (
                                <>
                                    <FormControl
                                        mb={4}
                                        isInvalid={
                                            formik.errors
                                                .notary_protocol_number &&
                                            formik.touched
                                                .notary_protocol_number
                                        }>
                                        <Input
                                            id="notary_protocol_number"
                                            placeholder="Numero del protocolo"
                                            w="200px"
                                            rounded="4px"
                                            size="xs"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={
                                                formik.values
                                                    .notary_protocol_number
                                            }
                                        />
                                        <FormErrorMessage>
                                            {
                                                formik.errors
                                                    .notary_protocol_number
                                            }
                                        </FormErrorMessage>
                                    </FormControl>
                                </>
                            ) : (
                                <Text
                                    color="#4F4F4F"
                                    fontSize={12}
                                    fontWeight={400}
                                    ml={4}
                                    mb={4}>
                                    {
                                        opportunity?.client?.representatives[0]
                                            .notary_protocol_number
                                    }
                                </Text>
                            )}

                            <Text
                                color="#4F4F4F"
                                fontSize={13}
                                fontWeight={700}
                                mb={1}>
                                Fecha Autorización Protocolo
                            </Text>
                            {isEditing ? (
                                <>
                                    <FormControl
                                        mb={4}
                                        isInvalid={
                                            formik.errors
                                                .notary_protocol_date &&
                                            formik.touched.notary_protocol_date
                                        }>
                                        <Input
                                            id="notary_protocol_date"
                                            w="200px"
                                            rounded="4px"
                                            size="xs"
                                            type="date"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={
                                                formik.values
                                                    .notary_protocol_date
                                            }
                                        />
                                        <FormErrorMessage>
                                            {formik.errors.notary_protocol_date}
                                        </FormErrorMessage>
                                    </FormControl>
                                </>
                            ) : (
                                <Text
                                    color="#4F4F4F"
                                    fontSize={12}
                                    fontWeight={400}
                                    ml={4}
                                    mb={4}>
                                    {new Date(
                                        opportunity?.client?.representatives[0].notary_protocol_date,
                                    ).toLocaleString('es-ES', {
                                        day: 'numeric',
                                        month: 'numeric',
                                        year: 'numeric',
                                        hour12: false,
                                    })}
                                </Text>
                            )}

                            {isEditing && (
                                <Center mt={6}>
                                    <Button
                                        bg={colors.secondary}
                                        border="1px"
                                        borderColor={colors.secondary}
                                        color="white"
                                        fontSize={12}
                                        fontWeight={500}
                                        h="32px"
                                        isLoading={isLoading}
                                        type="submit"
                                        w={130}
                                        _active={{
                                            bg: colors.primary,
                                            borderColor: colors.tertiary,
                                            color: colors.tertiary,
                                            transform: 'scale(0.98)',
                                        }}
                                        _hover={{
                                            bg: colors.secondary,
                                        }}>
                                        Guardar
                                    </Button>
                                </Center>
                            )}
                        </AccordionPanel>
                    </AccordionItem>
                </form>
            </Accordion>
        </Box>
    )
}
