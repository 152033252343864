import React, { FC, useState } from 'react'

import { useFormik } from 'formik'
import {
    Box,
    Button,
    Center,
    Divider,
    FormControl,
    IconButton,
    Text,
    Textarea,
    useToast,
} from '@chakra-ui/react'

import { EditLine, User3Line } from '../icons'
import { Opportunity } from '@/interfaces'
import { useClient } from '@/hooks/client'
import { colors } from '@/utils/const'

interface Props {
    opportunity: Opportunity
}

export const DescriptionCard: FC<Props> = ({ opportunity }) => {
    const { client, updateClient } = useClient({
        client_id: opportunity?.client?.id,
    })
    const [isEditing, setIsEditing] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const toast = useToast()
    const showIconButton = ['qualified', 'qualified_error'].includes(
        opportunity?.status,
    )
        ? true
        : false

    const formik = useFormik({
        initialValues: {
            comment: opportunity?.client?.comment || '',
        },
        onSubmit: async values => {
            setIsLoading(true)

            try {
                await updateClient({
                    client_id: opportunity?.client?.id,
                    data: {
                        comment: values.comment,
                    },
                })

                toast({
                    title: 'Éxito',
                    description: 'Cliente actualizado con éxito',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })

                setIsEditing(false)
            } catch (error) {
                toast({
                    title: 'Error.',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })

                formik.resetForm()
            }

            setIsLoading(false)
        },
    })

    return (
        <Box w="100%" borderWidth={1} borderRadius="md" boxShadow="lg">
            <Box
                mx={4}
                my={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between">
                <Box display="flex" alignItems="center">
                    <User3Line transform="scale(1.25)" />

                    <Text ml={2} fontSize={14} color="#858585">
                        Comentario
                    </Text>
                </Box>

                {showIconButton && (
                    <IconButton
                        aria-label={''}
                        size="xs"
                        bg="transparent"
                        icon={<EditLine transform="scale(1.2)" />}
                        onClick={() => setIsEditing(!isEditing)}
                        _active={{}}
                        _hover={{}}
                    />
                )}
            </Box>
            <Divider />
            <form onSubmit={formik.handleSubmit}>
                <Box
                    w="100%"
                    minH="130px"
                    px={8}
                    mt={2}
                    mb={4}
                    fontSize={13}
                    fontWeight={400}>
                    <Text color="#4F4F4F" mb={2}>
                        Descripción
                    </Text>
                    {isEditing ? (
                        <>
                            <FormControl mb={4}>
                                <Textarea
                                    id="comment"
                                    rounded="6px"
                                    size="xs"
                                    onChange={formik.handleChange}
                                    value={formik.values.comment}
                                />
                            </FormControl>
                        </>
                    ) : (
                        <Text
                            color="#4F4F4F"
                            fontSize={12}
                            fontWeight={400}
                            ml={4}
                            mb={4}>
                            {client?.comment}
                        </Text>
                    )}
                </Box>

                {isEditing && (
                    <Center mb={4}>
                        <Button
                            bg={colors.secondary}
                            border="1px"
                            borderColor={colors.secondary}
                            color="white"
                            fontSize={12}
                            fontWeight={500}
                            h="32px"
                            isLoading={isLoading}
                            type="submit"
                            w={130}
                            _active={{
                                bg: colors.primary,
                                borderColor: colors.tertiary,
                                color: colors.tertiary,
                                transform: 'scale(0.98)',
                            }}
                            _hover={{
                                bg: colors.secondary,
                            }}>
                            Guardar
                        </Button>
                    </Center>
                )}
            </form>
        </Box>
    )
}
