import React, { FC, useState } from 'react'

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    Text,
    Button,
} from '@chakra-ui/react'
import { colors } from '@/utils/const'
import { Opportunity } from '@/interfaces'

interface Props {
    isOpen: boolean
    onClose: () => void
    onOpenRepresentative: () => void
    onOpenNoRepresentative: () => void
}

export const QuesionsIsEspecial: FC<Props> = ({
    isOpen,
    onClose,
    onOpenRepresentative,
    onOpenNoRepresentative,
}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="sm">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader
                    color="#474747"
                    display="flex"
                    fontSize={18}
                    fontWeight={600}
                    justifyContent="center"
                    py={4}>
                    Representación voluntaria
                </ModalHeader>

                <ModalBody px={12} mb={10}>
                    <Text
                        color="#565758"
                        mb={6}
                        fontSize={14}
                        fontWeight={700}
                        textAlign="center">
                        Tipo de oportunidad
                    </Text>

                    <Button
                        color="#474747"
                        mb={6}
                        fontSize={12}
                        fontWeight={400}
                        w="full"
                        bg="white"
                        borderColor="#474747"
                        borderWidth="1px"
                        onClick={() => {
                            onClose()
                            onOpenRepresentative()
                        }}
                        _hover={{
                            bg: colors.primary,
                            color: 'white',
                        }}>
                        Con representación voluntaria
                    </Button>

                    <Button
                        color="#474747"
                        mb={6}
                        fontSize={12}
                        fontWeight={400}
                        w="full"
                        bg="white"
                        borderColor="#474747"
                        borderWidth="1px"
                        onClick={() => {
                            onClose()
                            onOpenNoRepresentative()
                        }}
                        _hover={{
                            bg: colors.primary,
                            color: 'white',
                        }}>
                        Sin representación voluntaria
                    </Button>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
