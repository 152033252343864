import { Box, Button, HStack, IconButton, Input, Text } from '@chakra-ui/react'
import React from 'react'
import { EditLine } from '../icons'
import { useClient } from '@/hooks/client'

type Props = {
    initialValue: string
    clientId: number
}

export default function EditableEnterpriseCreatedDate({
    initialValue,
    clientId,
}: Props) {
    const [value, setValue] = React.useState(initialValue)
    const [isEditing, setIsEditing] = React.useState(false)
    const [isPending, setIsPending] = React.useState(false)

    const { updateClient } = useClient({ client_id: clientId })

    async function handleSubmit(event: React.FormEvent) {
        setIsPending(true)
        event.preventDefault()

        try {
            await updateClient({
                client_id: clientId,
                data: { enterprise_created_date: value },
            })
        } finally {
            setIsEditing(false)
            setIsPending(false)
        }
    }

    if (isEditing) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                gap="2"
                onSubmit={handleSubmit}>
                <Input
                    size="sm"
                    type="date"
                    value={value}
                    onChange={e => setValue(e.target.value)}
                />

                <HStack ml="auto">
                    <Button
                        variant="ghost"
                        type="button"
                        size="sm"
                        fontSize="xs"
                        p={2}
                        disabled={isPending}
                        onClick={() => {
                            setValue(initialValue)
                            setIsEditing(false)
                        }}>
                        Cancelar
                    </Button>
                    <Button
                        type="submit"
                        size="sm"
                        fontSize="xs"
                        p={2}
                        disabled={isPending}
                        isLoading={isPending}
                        onClick={handleSubmit}>
                        Guardar
                    </Button>
                </HStack>
            </Box>
        )
    }

    return (
        <HStack alignItems="flex-start">
            <Box>
                <Text color="#4F4F4F" fontSize={13} fontWeight={700}>
                    Fecha de creación de la empresa
                </Text>
                <Text
                    color="#4F4F4F"
                    fontSize={12}
                    fontWeight={400}
                    ml={4}
                    mb={4}>
                    {value?.length ? value : '-'}
                </Text>
            </Box>
            <IconButton
                p={0}
                aria-label=""
                variant="ghost"
                height="24px"
                width="24px"
                onClick={() => setIsEditing(true)}>
                <EditLine />
            </IconButton>
        </HStack>
    )
}
