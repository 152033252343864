import { OPPORTUNITY_TYPES } from '@/constants'
import useToggle from '@/hooks/useToggle'
import {
    Button,
    HStack,
    Icon,
    IconButton,
    Select,
    Text,
    useToast,
} from '@chakra-ui/react'
import React, { FormEvent } from 'react'
import { EditLine } from '../icons'
import { BsFloppy2, BsX } from 'react-icons/bs'
import { useOpportunity } from '@/hooks/opportunities'
import { statusArray } from '@/hooks/statusOpportunity'

type Props = {
    id: number | string
    status: string
}

const FILTERED_STATUSES = statusArray.filter(({ status }) =>
    ['pending', 'proccess', 'closed', 'lost'].includes(status),
)

export default function OpportunityStatusForm({ status, id }: Props) {
    const toast = useToast()

    const [isEditing, toggleEditing] = useToggle()

    const type = FILTERED_STATUSES.find(
        ({ status: statusEnum }) => statusEnum === status,
    )

    const { updateOpportunity, isUpdating } = useOpportunity({
        id,
    })

    async function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()

        const status = e.target['status']?.value

        try {
            await updateOpportunity({
                opportunity_id: id,
                data: { status },
            })

            toast({
                title: 'Éxito',
                description: 'Actualizado con éxito',
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
        } catch (error) {
            console.error('New Error Updating Value', error)
        } finally {
            toggleEditing()
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <HStack hidden={isEditing}>
                <Text fontSize={12} fontWeight={700}>
                    Estado:
                </Text>
                <Text fontSize={12} fontWeight={600} mx={2}>
                    {type?.text ?? '-'}
                </Text>

                <IconButton
                    as={Button}
                    aria-label="Editar propiedad"
                    icon={
                        <Icon
                            as={EditLine}
                            transform="scale(1.2)"
                            color="#858585"
                        />
                    }
                    onClick={toggleEditing}
                    variant="ghost"
                    type="button"
                />
            </HStack>

            <HStack hidden={!isEditing}>
                <Select
                    id="status"
                    size="xs"
                    rounded={6}
                    defaultValue={status}
                    w="250px">
                    {FILTERED_STATUSES.map(item => (
                        <option key={item.text} value={item.status}>
                            {item.text}
                        </option>
                    ))}
                </Select>

                <IconButton
                    size="sm"
                    as={Button}
                    aria-label="Guardar propiedad"
                    icon={<Icon fontSize="xs" as={BsFloppy2} color="#858585" />}
                    variant="ghost"
                    type="submit"
                    isLoading={isUpdating}
                />

                <IconButton
                    size="sm"
                    as={Button}
                    aria-label="Cerrar"
                    onClick={toggleEditing}
                    icon={<Icon as={BsX} color="#858585" />}
                    variant="ghost"
                    type="button"
                    isLoading={isUpdating}
                />
            </HStack>
        </form>
    )
}
