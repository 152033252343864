import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
} from '@chakra-ui/react'
import React from 'react'

type Props = {
    isOpen: boolean
    onClose: () => void
    onContinue: () => void
}

export default function DNIConfirmationAlertModal({
    isOpen,
    onClose,
    onContinue,
}: Props) {
    const cancelRef = React.useRef()

    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Continuar
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        Este DNI ya tiene una oportunidad creada. ¿Estás seguro
                        de querer continuar?
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button
                            ref={cancelRef}
                            onClick={onClose}
                            variant="outline">
                            Cancelar
                        </Button>
                        <Button onClick={onContinue} ml={3}>
                            Continuar
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}
