import React, { FC } from 'react'

import { FormikProps } from 'formik'
import { Text, Box } from '@chakra-ui/react'

import { Distributor, RepresentativeType } from '@/interfaces'

interface Props {
    formik: FormikProps<FormikValues>
    representativesTypes: RepresentativeType
    behavior: boolean
    distributor?: Distributor
}

interface FormikValues {
    comment: string
    representatives: Representative[]
    client_type: string
}

interface Representative {
    representative_position: string
}

export const Step8: FC<Props> = ({
    formik,
    representativesTypes,
    behavior,
    distributor,
}) => {
    let showBehavior = false

    if (behavior) {
        showBehavior = true
    } else {
        if (distributor) {
            showBehavior = distributor.behavior
        }
    }

    return (
        <Box w="full" mt={4} color="black">
            <Box
                w="90%"
                display="flex"
                justifyContent="space-between"
                fontSize={13}
                fontWeight={400}>
                <Box display="flex">
                    <Text mr={4}>Cliente</Text>
                    <Text fontWeight={700}>
                        {formik.values.client_type === 'a'
                            ? 'Autónomo'
                            : 'Empresa'}
                    </Text>
                </Box>
                {formik.values.client_type === 's' && (
                    <Box display="flex">
                        <Text mr={4}>Representante</Text>
                        <Text fontWeight={700}>
                            {
                                representativesTypes[
                                    formik.values.representatives[0]
                                        .representative_position
                                ].type
                            }
                        </Text>
                    </Box>
                )}
            </Box>

            {showBehavior ? (
                <>
                    <Text fontSize={14} fontWeight={700} my={4}>
                        A continuación se van a enviar los documentos al cliente
                        y recibirá un email, donde figura el link para proceder
                        a la firma de los documentos.
                    </Text>
                    <Text fontSize={14} fontWeight={700} mb={4}>
                        El cliente debe seguir las instrucciones del email y
                        posteriormente recibirá un código por SMS para firmar
                        los contratos.
                    </Text>
                </>
            ) : (
                <>
                    <Text fontSize={14} fontWeight={700} my={4}>
                        A continuación se van a enviar los documentos al cliente
                        y recibirá un email para proceder a su firma.
                    </Text>
                    <Text fontSize={14} fontWeight={700} mb={4}>
                        Debe seguir las instrucciones del email y posteriormente
                        recibirá un código por SMS para firmar los contratos.
                    </Text>
                </>
            )}
        </Box>
    )
}
