import React, { FC, useState, useEffect } from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {
    Box,
    Button,
    Divider,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    GridItem,
    IconButton,
    Image,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Spinner,
    Text,
    useToast,
} from '@chakra-ui/react'

import { useOpportunities } from '@/hooks/opportunities'
import { CloseIcon } from '@chakra-ui/icons'
import { Store2Line } from '../icons'
import { colors } from '@/utils/const'
import { UserProfile } from '@/interfaces'

interface Props {
    user_id: number
    userName: string
    userProfile: UserProfile
    isOpen: boolean
    onClose: () => void
}
const NewOpportunitySchema = Yup.object().shape({
    name: Yup.string().required('El nombre es requerido'),
    segment: Yup.number().required('El número de empleados es requerido'),
    distributor: Yup.number().required('El distribuidor es requerido'),
    contact_name: Yup.string(),
    contact_hours: Yup.string(),
    phone: Yup.string()
        .min(10, 'Mínimo 10 caracteres')
        .max(14, 'Maximo 14 caracteres'),
    email: Yup.string().email('Correo inválido'),
    init_date: Yup.string(),
    end_date: Yup.string(),
})

export const NewOpportunity: FC<Props> = ({
    user_id,
    userName,
    userProfile,
    isOpen,
    onClose,
}) => {
    const { createOpportunity, getDistributors } = useOpportunities({})
    const [isLoading, setIsLoading] = useState(false)
    const [distributors, setDistributors] = useState([])
    const toast = useToast()

    useEffect(() => {
        getDistributors()
            .then(values => setDistributors(values))
            .catch(err => onClose())
    }, [])

    const formik = useFormik({
        initialValues: {
            name: '',
            segment: parseInt(userProfile?.data?.segment) || 1,
            distributor: 0,
            contact_name: '',
            contact_hours: '',
            phone: '',
            email: '',
            init_date: '',
            end_date: '',
        },
        validationSchema: NewOpportunitySchema,
        onSubmit: async values => {
            const distributor_id =
                values.distributor !== 0 ? values.distributor : null
            try {
                setIsLoading(true)
                await createOpportunity({
                    user_id,
                    name: values.name,
                    segment: values.segment,
                    distributor_id,
                    contact_name: values.contact_name,
                    contact_hours: values.contact_hours,
                    phone: values.phone ? `+${values.phone}` : null,
                    email: values.email,
                    init_date: values.init_date,
                    end_date: values.end_date,
                    opportunity_version: 'v3',
                })

                toast({
                    title: 'Éxito',
                    description: 'Oportunidad creada con éxito',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })
                formik.resetForm()
                onClose()
            } catch (error) {
                toast({
                    title: 'Error.',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            }

            setIsLoading(false)
        },
    })

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <form onSubmit={formik.handleSubmit}>
                    <ModalHeader
                        px={2}
                        py={2}
                        backgroundColor="primary-soft-hover">
                        <Grid
                            backgroundColor="transparent"
                            templateColumns="100px 1fr 1fr 24px"
                            gap={4}>
                            <GridItem w="100%">
                                <Image src="/images/NewOp.png" alt="logo" />
                            </GridItem>
                            <GridItem
                                alignItems="center"
                                display="flex"
                                fontSize={16}
                                fontWeight={700}
                                w="100%">
                                Nueva oportunidad
                            </GridItem>
                            <GridItem
                                w="100%"
                                justifyContent="end"
                                alignItems="center"
                                display="flex">
                                <Box mr={2}>
                                    <Store2Line />
                                </Box>
                                <Text ml={3} fontSize={13} fontWeight={500}>
                                    {userName}
                                </Text>
                            </GridItem>
                            <GridItem w="100%">
                                <IconButton
                                    fontSize={'10px'}
                                    size="xs"
                                    icon={<CloseIcon />}
                                    onClick={onClose}
                                    aria-label=""
                                />
                            </GridItem>
                        </Grid>
                    </ModalHeader>

                    <Divider />

                    <ModalBody>
                        {isLoading ? (
                            <Box
                                alignItems="center"
                                display="flex"
                                h="300px"
                                justifyContent="center">
                                <Spinner
                                    thickness="4px"
                                    speed="0.65s"
                                    emptyColor="gray.200"
                                    color="blue.500"
                                    size="xl"
                                />
                            </Box>
                        ) : (
                            <Grid
                                templateColumns="repeat(2, 1fr)"
                                gap={6}
                                my={4}>
                                <GridItem w="100%" px={6} py={4}>
                                    <FormControl
                                        mb={4}
                                        isInvalid={
                                            formik.errors.name &&
                                            formik.touched.name
                                        }>
                                        <FormLabel
                                            fontSize={13}
                                            fontWeight={700}>
                                            Titulo de la oportunidad{' '}
                                            <span style={{ color: '#FD7C04' }}>
                                                *
                                            </span>
                                        </FormLabel>
                                        <Input
                                            id="name"
                                            placeholder="Titulo de la oportunidad"
                                            rounded="4px"
                                            size="xs"
                                            onChange={formik.handleChange}
                                            value={formik.values.name}
                                        />
                                        <FormErrorMessage>
                                            {formik.errors.name}
                                        </FormErrorMessage>
                                    </FormControl>

                                    <FormControl
                                        mb={4}
                                        isInvalid={
                                            formik.errors.segment &&
                                            formik.touched.segment
                                        }>
                                        <FormLabel
                                            fontSize={13}
                                            fontWeight={700}>
                                            Segmento
                                        </FormLabel>
                                        <Select
                                            id="segment"
                                            rounded="4px"
                                            size="xs"
                                            onChange={formik.handleChange}
                                            value={formik.values.segment}>
                                            <option value={1}>1</option>
                                            <option value={2}>2</option>
                                            <option value={3}>3</option>
                                        </Select>
                                        <FormErrorMessage>
                                            {formik.errors.segment}
                                        </FormErrorMessage>
                                    </FormControl>

                                    <FormControl mb={4} isRequired>
                                        <FormLabel
                                            fontSize={13}
                                            fontWeight={700}>
                                            Distribuidor
                                        </FormLabel>
                                        <Select
                                            id="distributor"
                                            rounded="4px"
                                            size="xs"
                                            placeholder="Distribuidor"
                                            onChange={formik.handleChange}>
                                            {distributors.map((item, index) => (
                                                <option
                                                    key={index}
                                                    value={item.id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl mb={4}>
                                        <FormLabel
                                            fontSize={13}
                                            fontWeight={700}>
                                            Fecha inicio
                                        </FormLabel>
                                        <Input
                                            id="init_date"
                                            rounded="4px"
                                            size="xs"
                                            type="date"
                                            onChange={formik.handleChange}
                                            value={formik.values.init_date}
                                        />
                                    </FormControl>
                                    <FormControl mb={4}>
                                        <FormLabel
                                            fontSize={13}
                                            fontWeight={700}>
                                            Fecha prevista de cierre
                                        </FormLabel>
                                        <Input
                                            id="end_date"
                                            rounded="4px"
                                            size="xs"
                                            type="date"
                                            onChange={formik.handleChange}
                                            value={formik.values.end_date}
                                        />
                                    </FormControl>
                                </GridItem>

                                <GridItem
                                    bg={colors['gray-soft-30']}
                                    px={6}
                                    pt={4}
                                    pb={1}
                                    rounded={8}
                                    w="100%">
                                    <Text fontSize={13} fontWeight={700} mb={6}>
                                        Datos de contacto
                                    </Text>

                                    <FormControl
                                        mb={4}
                                        isInvalid={
                                            formik.errors.contact_name &&
                                            formik.touched.contact_name
                                        }>
                                        <FormLabel
                                            fontSize={13}
                                            fontWeight={700}>
                                            Nombre{' '}
                                        </FormLabel>
                                        <Input
                                            id="contact_name"
                                            bg="white"
                                            placeholder="Nombre"
                                            rounded="4px"
                                            size="xs"
                                            onChange={formik.handleChange}
                                            value={formik.values.contact_name}
                                        />
                                        <FormErrorMessage>
                                            {formik.errors.contact_name}
                                        </FormErrorMessage>
                                    </FormControl>

                                    <FormControl
                                        mb={4}
                                        isInvalid={
                                            formik.errors.phone &&
                                            formik.touched.phone
                                        }>
                                        <FormLabel
                                            fontSize={13}
                                            fontWeight={700}>
                                            Teléfono{' '}
                                        </FormLabel>
                                        <PhoneInput
                                            inputStyle={{
                                                height: '25px',
                                                width: '100%',
                                            }}
                                            country={'es'}
                                            value={formik.values.phone}
                                            onChange={phone =>
                                                formik.setFieldValue(
                                                    'phone',
                                                    phone,
                                                )
                                            }
                                        />
                                        <FormErrorMessage>
                                            {formik.errors.phone}
                                        </FormErrorMessage>
                                    </FormControl>

                                    <FormControl
                                        mb={4}
                                        isInvalid={
                                            formik.errors.email &&
                                            formik.touched.email
                                        }>
                                        <FormLabel
                                            fontSize={13}
                                            fontWeight={700}>
                                            Email
                                        </FormLabel>
                                        <Input
                                            id="email"
                                            bg="white"
                                            placeholder="Email"
                                            rounded="4px"
                                            size="xs"
                                            type="email"
                                            onChange={formik.handleChange}
                                            value={formik.values.email}
                                        />
                                        <FormErrorMessage>
                                            {formik.errors.email}
                                        </FormErrorMessage>
                                    </FormControl>

                                    <FormControl
                                        mb={4}
                                        isInvalid={
                                            formik.errors.contact_hours &&
                                            formik.touched.contact_hours
                                        }>
                                        <FormLabel
                                            fontSize={13}
                                            fontWeight={700}>
                                            Hora de contacto
                                        </FormLabel>
                                        <Select
                                            id="contact_hours"
                                            rounded="4px"
                                            size="xs"
                                            placeholder="Elige un horario"
                                            onChange={formik.handleChange}
                                            value={formik.values.contact_hours}>
                                            <option
                                                value={
                                                    'Lun-Vie: De 9:00 a 13:00 horas'
                                                }>
                                                Lun-Vie: De 9:00 a 13:00 horas
                                            </option>
                                            <option
                                                value={
                                                    'Lun-Vie: De 16:00 a 19:00 horas'
                                                }>
                                                Lun-Vie: De 16:00 a 19:00 horas
                                            </option>
                                        </Select>
                                        <FormErrorMessage>
                                            {formik.errors.contact_hours}
                                        </FormErrorMessage>
                                    </FormControl>
                                    {/* <Box display="flex" justifyContent="center">
                                        <Button
                                            variant="unstyled"
                                            color={colors.secondary}
                                            fontSize={13}
                                            fontWeight={700}>
                                            + Agregar hora de contacto
                                        </Button>
                                    </Box> */}
                                </GridItem>
                            </Grid>
                        )}
                    </ModalBody>

                    <Divider />

                    <ModalFooter justifyContent="center">
                        <Button
                            bg="white"
                            border="1px"
                            borderColor={colors.secondary}
                            color={colors.secondary}
                            isDisabled={isLoading}
                            fontSize={12}
                            fontWeight={500}
                            h="32px"
                            mr={8}
                            w={120}
                            onClick={() => formik.resetForm()}
                            _active={{
                                transform: 'scale(0.98)',
                            }}
                            _hover={{}}>
                            Empezar de nuevo
                        </Button>
                        <Button
                            bg={colors.secondary}
                            border="1px"
                            borderColor={colors.secondary}
                            color="white"
                            isDisabled={isLoading}
                            fontSize={12}
                            fontWeight={500}
                            h="32px"
                            type="submit"
                            w={112}
                            _active={{
                                transform: 'scale(0.98)',
                            }}
                            _hover={{}}>
                            Crear usuario
                        </Button>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    )
}
