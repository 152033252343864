import React, { FC } from 'react'

import { FormikProps } from 'formik'
import { Text, Box, FormLabel, useRadioGroup, HStack } from '@chakra-ui/react'

import { RadioCard } from '@/components/utils'

interface Props {
    formik: FormikProps<FormikValues>
}

interface FormikValues {
    client_type: string
}

export const Step1WPreliminary: FC<Props> = ({ formik }) => {
    const companyOptions = ['Empresa', 'Autónomo']

    const {
        getRootProps: getRootCompanyProps,
        getRadioProps: getRadioCompanyProps,
    } = useRadioGroup({
        name: 'client_type',
        defaultValue:
            formik.values.client_type === 'a' ? 'Autónomo' : 'Empresa',
    })

    const groupCompany = getRootCompanyProps()

    const handleChangeCompany = () => {
        formik.values.client_type === 'a'
            ? formik.setFieldValue('client_type', 's')
            : formik.setFieldValue('client_type', 'a')
    }

    return (
        <Box
            w="full"
            display="flex"
            justifyContent="center"
            mt={6}
            color="black">
            <Box w="70%">
                <Box mt={6}>
                    <FormLabel
                        color="#4F4F4F"
                        fontSize={13}
                        fontWeight={700}
                        mr={10}>
                        Empresa
                    </FormLabel>

                    <HStack onChange={handleChangeCompany} {...groupCompany}>
                        {companyOptions.map(value => {
                            const radio = getRadioCompanyProps({ value })
                            return (
                                <RadioCard key={value} {...radio}>
                                    <Text
                                        fontSize={12}
                                        fontWeight={400}
                                        color="#474747">
                                        {value}
                                    </Text>
                                </RadioCard>
                            )
                        })}
                    </HStack>
                </Box>
            </Box>
        </Box>
    )
}
