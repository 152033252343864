import React, { FC, useState } from 'react'

import NextLink from 'next/link'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
    Box,
    Button,
    Center,
    FormControl,
    FormErrorMessage,
    IconButton,
    Input,
    Link,
    Text,
    useToast,
} from '@chakra-ui/react'
import { Opportunity } from '@/interfaces'
import { useOpportunity } from '@/hooks/opportunities'
import { colors } from '@/utils/const'
import { EditLine, PencilIcon } from '../icons'

interface Props {
    opportunity: Opportunity
}

const Schema = Yup.object().shape({
    bonus_expedient_number: Yup.string().required(
        'El número de expediente es requerido',
    ),
    bonus_request_date: Yup.string().required(
        'La fecha de solicitud es requerido',
    ),
})

export const BonusCard: FC<Props> = ({ opportunity }) => {
    const { updateOpportunity } = useOpportunity({ id: opportunity?.id })
    const [isEditing, setIsEditing] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const toast = useToast()

    const formik = useFormik({
        initialValues: {
            bonus_expedient_number: opportunity?.bonus_expedient_number || '',
            bonus_request_date: opportunity?.bonus_request_date || '',
        },
        validationSchema: Schema,
        onSubmit: async values => {
            setIsLoading(true)

            try {
                await updateOpportunity({
                    opportunity_id: opportunity?.id,
                    data: {
                        bonus_expedient_number: values.bonus_expedient_number,
                        bonus_request_date: values.bonus_request_date,
                    },
                })

                toast({
                    title: 'Éxito',
                    description: 'Oportunidad actualizada con éxito',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })

                setIsEditing(false)
            } catch (error) {
                toast({
                    title: 'Error.',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })

                formik.resetForm()
            }

            setIsLoading(false)
        },
    })

    return (
        <Box
            bg="white"
            w="100%"
            borderWidth={1}
            borderRadius="xl"
            px={8}
            py={4}
            boxShadow="lg">
            <form onSubmit={formik.handleSubmit}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center">
                    <Text fontSize={14} fontWeight={700} color="#474747">
                        Gestión del bono
                    </Text>

                    <IconButton
                        mt={0}
                        aria-label="Pencil"
                        bg="transparent"
                        onClick={() => setIsEditing(!isEditing)}
                        _active={{}}
                        _hover={{}}>
                        <PencilIcon color="#000" transform="scale(0.9)" />
                    </IconButton>
                </Box>
                <Text color="#858585" fontSize={14} fontWeight={400} my={2}>
                    Número de expediente
                </Text>

                {isEditing ? (
                    <>
                        <FormControl
                            mb={4}
                            isInvalid={
                                formik.errors.bonus_expedient_number &&
                                formik.touched.bonus_expedient_number
                            }>
                            <Input
                                id="bonus_expedient_number"
                                placeholder="Número de expediente"
                                fontSize={13}
                                w="full"
                                maxW="200px"
                                rounded="6px"
                                size="sm"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.bonus_expedient_number}
                            />
                            <FormErrorMessage>
                                {formik.errors.bonus_expedient_number}
                            </FormErrorMessage>
                        </FormControl>
                    </>
                ) : (
                    <Text fontWeight={600} fontSize={14} mb={2}>
                        {opportunity?.bonus_expedient_number}
                    </Text>
                )}

                <Text color="#858585" fontSize={14} fontWeight={400} my={2}>
                    Fecha de solicitud
                </Text>

                {isEditing ? (
                    <>
                        <FormControl
                            mb={4}
                            isInvalid={
                                formik.errors.bonus_request_date &&
                                formik.touched.bonus_request_date
                            }>
                            <Input
                                id="bonus_request_date"
                                placeholder="Nombre"
                                fontSize={13}
                                w="full"
                                maxW="200px"
                                rounded="6px"
                                size="sm"
                                type="date"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.bonus_request_date}
                            />
                            <FormErrorMessage>
                                {formik.errors.bonus_request_date}
                            </FormErrorMessage>
                        </FormControl>
                    </>
                ) : (
                    <Text fontWeight={600} fontSize={14} mb={2}>
                        {opportunity?.bonus_request_date ? (
                            <>
                                {new Date(
                                    opportunity.bonus_request_date,
                                ).toLocaleString('es-ES', {
                                    day: '2-digit',
                                    month: 'long',
                                    year: 'numeric',
                                    hour12: false,
                                })}
                            </>
                        ) : (
                            'No tiene'
                        )}
                    </Text>
                )}

                {opportunity?.voucher_id && (
                    <>
                        <Text
                            color="#858585"
                            fontSize={14}
                            fontWeight={400}
                            my={2}>
                            Bono asociado
                        </Text>

                        <NextLink
                            href={{
                                pathname: '/bonus/[id]',
                                query: {
                                    id: opportunity.voucher_id,
                                },
                            }}>
                            <Link
                                color={colors.tertiary}
                                fontSize={14}
                                fontWeight={600}>
                                Bono {opportunity.voucher_id}
                            </Link>
                        </NextLink>
                    </>
                )}

                {isEditing && (
                    <Center mt={6}>
                        <Button
                            bg={colors.secondary}
                            border="1px"
                            borderColor={colors.secondary}
                            color="white"
                            fontSize={12}
                            fontWeight={500}
                            h="32px"
                            isLoading={isLoading}
                            type="submit"
                            w={130}
                            _active={{
                                bg: colors.primary,
                                borderColor: colors.tertiary,
                                color: colors.tertiary,
                                transform: 'scale(0.98)',
                            }}
                            _hover={{
                                bg: colors.secondary,
                            }}>
                            Guardar
                        </Button>
                    </Center>
                )}
            </form>
        </Box>
    )
}
