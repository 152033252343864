import React, { FC, useState } from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
    Box,
    Button,
    Center,
    Divider,
    FormControl,
    FormErrorMessage,
    IconButton,
    Input,
    SkeletonText,
    Text,
    useToast,
} from '@chakra-ui/react'

import { EditLine, User3Line } from '../icons'
import { Client, Opportunity, RepresentativeType } from '@/interfaces'
import { useClient } from '@/hooks/client'
import { colors } from '@/utils/const'

interface Props {
    opportunity: Opportunity
    client: Client
    representativesTypes: RepresentativeType
}

const Schema = Yup.object().shape({
    name: Yup.string().required('El nombre del representante es requerido'),
    lastname: Yup.string().required(
        'El primer apellido del representante es requerido',
    ),
    second_lastname: Yup.string().required(
        'El segundo apellido del contacto es requerido',
    ),
    dni: Yup.string().required('El número de identificación es requerida'),
})

export const RepresentativeCard: FC<Props> = ({
    opportunity,
    client,
    representativesTypes,
}) => {
    const { updateClient } = useClient({
        client_id: opportunity?.client?.id,
    })
    const [isEditing, setIsEditing] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const toast = useToast()
    const showIconButton = ['qualified', 'qualified_error'].includes(
        opportunity?.status,
    )
        ? true
        : false

    const formik = useFormik({
        initialValues: {
            name: opportunity?.client?.representatives[0]?.name || '',
            lastname: opportunity?.client?.representatives[0]?.lastname || '',
            second_lastname:
                opportunity?.client?.representatives[0]?.second_lastname || '',
            dni: opportunity?.client?.representatives[0]?.dni || '',
        },
        validationSchema: Schema,
        onSubmit: async values => {
            setIsLoading(true)

            let representatives = opportunity?.client?.representatives

            representatives[0].name = values.name
            representatives[0].lastname = values.lastname
            representatives[0].second_lastname = values.second_lastname
            representatives[0].dni = values.dni

            try {
                await updateClient({
                    client_id: opportunity?.client?.id,
                    data: {
                        representatives,
                    },
                })

                toast({
                    title: 'Éxito',
                    description: 'Datos actualizados con éxito',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })

                setIsEditing(false)
            } catch (error) {
                toast({
                    title: 'Error.',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })

                formik.resetForm()
            }

            setIsLoading(false)
        },
    })

    return (
        <Box w="100%" borderWidth={1} borderRadius="md" boxShadow="lg">
            <Box
                mx={4}
                my={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between">
                <Box display="flex" alignItems="center">
                    <User3Line transform="scale(1.25)" />

                    <Text ml={2} fontSize={14} color="#858585">
                        Representante
                    </Text>
                </Box>

                {client?.client_type === 's' && showIconButton && (
                    <IconButton
                        aria-label={''}
                        size="xs"
                        bg="transparent"
                        icon={<EditLine transform="scale(1.2)" />}
                        onClick={() => setIsEditing(!isEditing)}
                        _active={{}}
                        _hover={{}}
                    />
                )}
            </Box>

            <Divider />

            <form onSubmit={formik.handleSubmit}>
                {client?.client_type === 's' ? (
                    <Box
                        w="100%"
                        px={8}
                        mt={2}
                        mb={6}
                        fontSize={13}
                        fontWeight={400}>
                        <Text color="#4F4F4F" mb={1}>
                            Nombre
                        </Text>
                        {isEditing ? (
                            <>
                                <FormControl
                                    mb={4}
                                    isInvalid={
                                        formik.errors.name &&
                                        formik.touched.name
                                    }>
                                    <Input
                                        id="name"
                                        placeholder="Nombre"
                                        rounded="4px"
                                        size="xs"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.name}
                                    />
                                    <FormErrorMessage>
                                        {formik.errors.name}
                                    </FormErrorMessage>
                                </FormControl>
                            </>
                        ) : (
                            <Text fontWeight={700} mb={2}>
                                {client?.representatives[0]?.name}
                            </Text>
                        )}

                        <Text color="#4F4F4F" mb={1}>
                            Primer apellido
                        </Text>
                        {isEditing ? (
                            <>
                                <FormControl
                                    mb={4}
                                    isInvalid={
                                        formik.errors.lastname &&
                                        formik.touched.lastname
                                    }>
                                    <Input
                                        id="lastname"
                                        placeholder="Primer apellido"
                                        rounded="4px"
                                        size="xs"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.lastname}
                                    />
                                    <FormErrorMessage>
                                        {formik.errors.lastname}
                                    </FormErrorMessage>
                                </FormControl>
                            </>
                        ) : (
                            <Text fontWeight={700} mb={2}>
                                {client?.representatives[0]?.lastname}
                            </Text>
                        )}

                        <Text color="#4F4F4F" mb={1}>
                            Segundo apellido
                        </Text>
                        {isEditing ? (
                            <>
                                <FormControl
                                    mb={4}
                                    isInvalid={
                                        formik.errors.second_lastname &&
                                        formik.touched.second_lastname
                                    }>
                                    <Input
                                        id="second_lastname"
                                        placeholder="Segundo apellido"
                                        rounded="4px"
                                        size="xs"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.second_lastname}
                                    />
                                    <FormErrorMessage>
                                        {formik.errors.second_lastname}
                                    </FormErrorMessage>
                                </FormControl>
                            </>
                        ) : (
                            <Text fontWeight={700} mb={2}>
                                {client?.representatives[0]?.second_lastname}
                            </Text>
                        )}
                        <Text color="#4F4F4F" mt={2} mb={1}>
                            DNI
                        </Text>
                        {isEditing ? (
                            <>
                                <FormControl
                                    mb={4}
                                    isInvalid={
                                        formik.errors.dni && formik.touched.dni
                                    }>
                                    <Input
                                        id="dni"
                                        placeholder="DNI"
                                        rounded="4px"
                                        size="xs"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.dni}
                                    />
                                    <FormErrorMessage>
                                        {formik.errors.dni}
                                    </FormErrorMessage>
                                </FormControl>
                            </>
                        ) : (
                            <Text fontWeight={700}>
                                {client?.representatives[0]?.dni}
                            </Text>
                        )}
                        <Text color="#4F4F4F" mt={2} mb={1}>
                            Cargo
                        </Text>
                        {!isLoading ? (
                            <Text fontWeight={700}>
                                {representativesTypes
                                    ? representativesTypes[
                                          client?.representatives[0]?.position
                                      ]?.label
                                    : ''}
                            </Text>
                        ) : (
                            <SkeletonText noOfLines={1} w="70%" />
                        )}
                    </Box>
                ) : (
                    <Box
                        alignItems="center"
                        color="#474747"
                        display="flex"
                        fontSize={14}
                        h="130px"
                        justifyContent="center"
                        w="full">
                        <Text>No tiene</Text>
                    </Box>
                )}
                {isEditing && (
                    <Center mb={4}>
                        <Button
                            bg={colors.secondary}
                            border="1px"
                            borderColor={colors.secondary}
                            color="white"
                            fontSize={12}
                            fontWeight={500}
                            h="32px"
                            isLoading={isLoading}
                            type="submit"
                            w={130}
                            _active={{
                                bg: colors.primary,
                                borderColor: colors.tertiary,
                                color: colors.tertiary,
                                transform: 'scale(0.98)',
                            }}
                            _hover={{
                                bg: colors.secondary,
                            }}>
                            Guardar
                        </Button>
                    </Center>
                )}
            </form>
        </Box>
    )
}
