import {
    Button,
    Editable,
    EditableInput,
    EditablePreview,
    HStack,
    IconButton,
    Text,
    useEditableControls,
    useToast,
} from '@chakra-ui/react'
import React from 'react'
import { EditLine } from '../icons'
import { useClient } from '@/hooks/client'

type Props = {
    id: number
    label: string
    name: string
    initialValue: string
}

export default function EditableProperty({
    id: client_id,
    label,
    initialValue,
    name,
}: Props) {
    const [isLoading, setIsLoading] = React.useState(false)

    const toast = useToast()
    const { updateClient } = useClient({
        client_id,
    })

    async function handleUpdateProp(newValue: string) {
        setIsLoading(true)
        const trimmed = newValue.trim()

        try {
            // Don't update on same value
            if (initialValue === trimmed) return
            if (!trimmed.length) throw new Error('Por favor, ingrese un valor')

            await updateClient({
                client_id,
                data: { [name]: trimmed },
            })

            toast({
                title: 'Éxito',
                description: 'Datos actualizados con éxito',
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
        } catch (error) {
            if (error instanceof Error) {
                toast({
                    title: 'Éxito',
                    description: error.message,
                    status: 'error',
                    isClosable: true,
                })
            }
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Editable
            ml={4}
            mb={4}
            defaultValue={initialValue}
            isPreviewFocusable={false}
            submitOnBlur={false}
            color="#4F4F4F"
            display="flex"
            flexDir="column"
            onSubmit={handleUpdateProp}
            isDisabled={isLoading}>
            <HStack ml={-4}>
                <Text fontSize={13} fontWeight={700}>
                    {label}
                </Text>
                <EditButton disabled={isLoading} name={name} />
            </HStack>

            <EditablePreview fontSize={12} fontWeight={400} />
            <EditableInput fontSize={12} fontWeight={400} />
            <SaveButton />
        </Editable>
    )
}

function EditButton({ name, ...delegated }) {
    const { isEditing, getEditButtonProps } = useEditableControls()

    if (isEditing) return null

    return (
        <IconButton
            variant="ghost"
            aria-label={`edit-${name}`}
            {...getEditButtonProps()}
            p={0}
            height="24px"
            width="24px"
            {...delegated}>
            <EditLine />
        </IconButton>
    )
}

function SaveButton() {
    const { isEditing, getSubmitButtonProps } = useEditableControls()
    if (!isEditing) return null

    return (
        <Button size="xs" mx="auto" mt={2} {...getSubmitButtonProps()}>
            Guardar
        </Button>
    )
}
