import React, { FC, useState } from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    Text,
    Button,
    useToast,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
} from '@chakra-ui/react'
import { colors } from '@/utils/const'
import { Opportunity } from '@/interfaces'
import { useOpportunity } from '@/hooks/opportunities'

interface Props {
    opportunity: Opportunity
    isOpen: boolean
    onClose: () => void
}

const Schema = Yup.object().shape({
    expedient: Yup.string().required('El número de expediente es requerido'),
    request_date: Yup.string().required('La fecha de solicitud es requerida'),
})

export const SendBonusModal: FC<Props> = ({ opportunity, isOpen, onClose }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [isSent, setIsSent] = useState(false)
    const toast = useToast()
    const { setStatusOpportunity, updateOpportunity } = useOpportunity({
        id: opportunity?.id,
    })

    const formik = useFormik({
        initialValues: {
            expedient: '',
            request_date: '',
        },
        validationSchema: Schema,
        onSubmit: async values => {
            try {
                setIsLoading(true)

                await updateOpportunity({
                    opportunity_id: opportunity?.id,
                    data: {
                        bonus_expedient_number: values.expedient,
                        bonus_request_date: values.request_date,
                    },
                })

                await setStatusOpportunity({
                    opportunity_id: opportunity?.id,
                    status: 'bonus_managment_pending_sent',
                })

                toast({
                    title: 'Éxito',
                    description: 'Oportunidad actualizada con éxito',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })
                formik.resetForm()
                onClose()
            } catch (error) {
                toast({
                    title: 'Error.',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            }

            setIsLoading(false)
        },
    })

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="sm">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader
                    color="#474747"
                    display="flex"
                    fontSize={18}
                    fontWeight={600}
                    justifyContent="center"
                    pt={6}
                    pb={4}>
                    Gestión de bono
                </ModalHeader>

                <form onSubmit={formik.handleSubmit}>
                    <ModalBody px={12} mb={10}>
                        <Text
                            color="#565758"
                            mb={6}
                            fontSize={12}
                            fontWeight={400}
                            textAlign="center">
                            Estado de la etapa
                        </Text>

                        {isSent ? (
                            <>
                                <FormControl
                                    mb={4}
                                    isInvalid={
                                        formik.errors.expedient &&
                                        formik.touched.expedient
                                    }>
                                    <FormLabel fontSize={13} fontWeight={700}>
                                        Número de expediente{' '}
                                        <span style={{ color: '#FD7C04' }}>
                                            *
                                        </span>
                                    </FormLabel>
                                    <Input
                                        id="expedient"
                                        placeholder="Número de expediente"
                                        rounded="4px"
                                        size="xs"
                                        onChange={formik.handleChange}
                                        value={formik.values.expedient}
                                    />
                                    <FormErrorMessage>
                                        {formik.errors.expedient}
                                    </FormErrorMessage>
                                </FormControl>

                                <FormControl
                                    mb={8}
                                    isInvalid={
                                        formik.errors.request_date &&
                                        formik.touched.request_date
                                    }>
                                    <FormLabel fontSize={13} fontWeight={700}>
                                        Fecha de solicitud{' '}
                                        <span style={{ color: '#FD7C04' }}>
                                            *
                                        </span>
                                    </FormLabel>
                                    <Input
                                        id="request_date"
                                        rounded="4px"
                                        size="xs"
                                        type="date"
                                        onChange={formik.handleChange}
                                        value={formik.values.request_date}
                                    />

                                    <FormErrorMessage>
                                        {formik.errors.request_date}
                                    </FormErrorMessage>
                                </FormControl>

                                <Button
                                    bg="white"
                                    border="1px"
                                    borderColor={colors.secondary}
                                    color={colors.secondary}
                                    isDisabled={isLoading}
                                    fontSize={12}
                                    fontWeight={500}
                                    h="32px"
                                    mr={8}
                                    w={120}
                                    onClick={() => {
                                        formik.resetForm()
                                        setIsSent(false)
                                    }}
                                    _active={{
                                        transform: 'scale(0.98)',
                                    }}
                                    _hover={{}}>
                                    Cancelar
                                </Button>
                                <Button
                                    bg={colors.secondary}
                                    border="1px"
                                    borderColor={colors.secondary}
                                    color="white"
                                    isDisabled={isLoading}
                                    fontSize={12}
                                    fontWeight={500}
                                    h="32px"
                                    type="submit"
                                    w={112}
                                    _active={{
                                        transform: 'scale(0.98)',
                                    }}
                                    _hover={{}}>
                                    Guardar
                                </Button>
                            </>
                        ) : (
                            <Button
                                color="#474747"
                                mb={6}
                                fontSize={12}
                                fontWeight={400}
                                isLoading={isLoading}
                                w="full"
                                bg="white"
                                borderColor="#474747"
                                borderWidth="1px"
                                onClick={() => setIsSent(true)}
                                _hover={{
                                    bg: colors.primary,
                                    color: 'white',
                                }}>
                                Enviar
                            </Button>
                        )}
                    </ModalBody>
                </form>
            </ModalContent>
        </Modal>
    )
}
