import React, { FC } from 'react'

import { FormikProps } from 'formik'
import { chakra, Box, Text, Input } from '@chakra-ui/react'
import { Test } from '../qualifySteps/AutoDiagnosticTest'

interface Props {
    formik: FormikProps<FormikValues>
    question: string
    answerNumber: number
}

interface FormikValues {
    question_test: Test
}

export const PercentStep: FC<Props> = ({ formik, question, answerNumber }) => {
    const handleChange = e => {
        let aux = parseInt(e.target.value)

        if (isNaN(aux)) {
            formik.setFieldValue(
                `question_test.${answerNumber}.answer_id`,
                null,
            )
            e.target.value = ''
        } else if (aux >= 0 && aux <= 100) {
            formik.setFieldValue(`question_test.${answerNumber}.answer_id`, aux)
            e.target.value = aux
        } else {
            e.target.value = aux - 100
        }
    }

    return (
        <Box minH="180px" my={4} color="black">
            <Text fontSize={14} fontWeight={700} mb={6} textAlign="justify">
                {question}
                <chakra.span color="#FD7C04"> *</chakra.span>
            </Text>

            <Box display="flex" justifyContent="center" alignItems="center">
                <Input
                    max={100}
                    min={0}
                    placeholder="0"
                    rounded={4}
                    size="sm"
                    type="number"
                    value={
                        formik.values.question_test[answerNumber].answer_id ===
                        null
                            ? ''
                            : formik.values.question_test[answerNumber]
                                  .answer_id
                    }
                    w={70}
                    onChange={handleChange}
                />

                <Text color="#FD7C04" ml={4} fontSize={14} fontWeight={400}>
                    Valor entre 0 y 100
                </Text>
            </Box>
        </Box>
    )
}
