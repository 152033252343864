import React, { Dispatch, FC, SetStateAction } from 'react'

import { FormikProps } from 'formik'
import { Box, Button, Divider, ModalFooter } from '@chakra-ui/react'
import { Step, Steps, useSteps } from 'chakra-ui-steps'

import { colors } from '@/utils/const'
import { CircleIcon } from '@/components/icons'
import { PercentStep, PerfilStep, QuestionStep } from '../questionsSteps'
import { ProfilesQuestions, Questions } from '@/interfaces'

interface Props {
    formik: FormikProps<FormikValues>
    next: () => void
    previous: () => void
    lastActiveStep: number
    setLastActiveStep: (value) => void
    questionsTest: Questions[]
    profilesQuestions: ProfilesQuestions[]
}

interface FormikValues {
    question_test: Test
    profile: boolean
    question_profile_id: number
}

export interface Test {
    1: TestObject
    2: TestObject
    3: TestObject
    4: TestObject
    5: TestObject
    6: TestObject
    7: TestObject
    8: TestObject
    9: TestObject
    10: TestObject
    11: TestObject
    12: TestObject
    13: TestObject
}

interface TestObject {
    answer_id: number
}

const steps = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]

export const AutoDiagnosticTest: FC<Props> = ({
    formik,
    next,
    previous,
    lastActiveStep,
    setLastActiveStep,
    questionsTest,
    profilesQuestions,
}) => {
    const initialStep = lastActiveStep === 6 ? 0 : 13
    const { nextStep, prevStep, reset, activeStep } = useSteps({
        initialStep,
    })
    let disableNext = false

    if (
        activeStep > 0 &&
        formik.values.question_test[activeStep]?.answer_id === null
    ) {
        disableNext = true
    }

    if (
        activeStep === 0 &&
        formik.values.profile &&
        formik.values.question_profile_id === null
    ) {
        disableNext = true
    }

    const handlePrevStep = () => {
        if (activeStep === 0) {
            previous()
        } else if (activeStep === 12) {
            if (formik.values.question_test[10].answer_id === 21) {
                prevStep()
            }
            prevStep()
        } else {
            prevStep()
        }
    }

    const handleNextStep = () => {
        if (activeStep === 13) {
            setLastActiveStep(7)
            next()
        } else if (activeStep === 10) {
            if (formik.values.question_test[10].answer_id === 21) {
                nextStep()
            }
            nextStep()
        } else {
            nextStep()
        }
    }

    return (
        <Box>
            <Steps
                labelOrientation="vertical"
                size="xs"
                h={'0px'}
                overflow="hidden"
                colorScheme="telegram"
                activeStep={activeStep}>
                <Step
                    label={activeStep === 0 && 'Perfil'}
                    color={colors.secondary}
                    icon={CircleIcon}>
                    <PerfilStep
                        formik={formik}
                        profilesQuestions={profilesQuestions}
                    />
                </Step>

                {questionsTest?.map(item => {
                    if (item.id === 13) {
                        return (
                            <Step
                                key={item.id}
                                label={activeStep === 13 && '(13/13)'}
                                color={colors.secondary}
                                icon={CircleIcon}>
                                <PercentStep
                                    formik={formik}
                                    question={item.name}
                                    answerNumber={13}
                                />
                            </Step>
                        )
                    }

                    return (
                        <Step
                            key={item.id}
                            label={activeStep === item.id && `(${item.id}/13)`}
                            color={colors.secondary}
                            icon={CircleIcon}>
                            <QuestionStep
                                formik={formik}
                                question={item.name}
                                answerNumber={item.id}
                                answers={item.answers}
                            />
                        </Step>
                    )
                })}
            </Steps>

            <Divider mt={6} />

            <ModalFooter px={0} mb={0}>
                <Box display="flex" justifyContent="center" w="full">
                    <Button
                        bg="white"
                        border="1px"
                        borderColor={colors.secondary}
                        color={colors.secondary}
                        fontSize={12}
                        fontWeight={500}
                        h="32px"
                        mr={10}
                        w={112}
                        onClick={handlePrevStep}
                        _active={{
                            transform: 'scale(0.98)',
                        }}
                        _hover={{}}>
                        Atrás
                    </Button>

                    <Button
                        bg={colors.secondary}
                        border="1px"
                        color="white"
                        fontSize={12}
                        fontWeight={500}
                        isDisabled={disableNext}
                        h="32px"
                        mr={10}
                        type={activeStep === steps.length ? 'submit' : 'button'}
                        w={112}
                        onClick={handleNextStep}
                        _active={{
                            transform: 'scale(0.98)',
                        }}
                        _hover={{}}>
                        Continuar
                    </Button>
                </Box>
            </ModalFooter>
        </Box>
    )
}
