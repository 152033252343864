import { colors } from '@/utils/const'
import { Box, useRadio } from '@chakra-ui/react'

export const SelectPrinterRadio = props => {
    const { getInputProps, getCheckboxProps } = useRadio(props)

    const input = getInputProps()
    const checkbox = getCheckboxProps()

    return (
        <Box as="label">
            <input {...input} />
            <Box
                {...checkbox}
                cursor="pointer"
                bg="white"
                color={colors['data-text-principal']}
                borderWidth="1px"
                borderColor={colors['data-text-principal']}
                borderRadius="md"
                textAlign="center"
                fontWeight={600}
                _checked={{
                    bg: colors['data-button-principal'],
                    color: 'white',
                }}
                _focus={{
                    boxShadow: 'outline',
                }}
                px={6}
                py={2}>
                {props.children}
            </Box>
        </Box>
    )
}
