import { useDisclosure } from '@chakra-ui/react'
import React from 'react'

export function useDNIConfirmation(callback: () => Promise<void> | void) {
    const DNIConfirmation = React.useRef(true)

    const {
        isOpen: isOpenDNIConfirmation,
        onToggle: onToggleDNIConfirmation,
    } = useDisclosure()

    function setDNIConfirmation(value: boolean) {
        DNIConfirmation.current = value
    }

    const handleOnContinueDNIConfirmation = () => {
        setDNIConfirmation(false)
        onToggleDNIConfirmation()
        callback()
    }

    return {
        isOpenDNIConfirmation,
        onToggleDNIConfirmation,
        setDNIConfirmation,
        DNIConfirmation: DNIConfirmation.current,
        onContinue: handleOnContinueDNIConfirmation,
    }
}
