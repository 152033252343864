import React, { FC, useState, useEffect } from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Divider,
    Flex,
    Box,
    useToast,
    ModalCloseButton,
} from '@chakra-ui/react'
import { Step, Steps, useSteps } from 'chakra-ui-steps'

import {
    getRepresentativeTypes,
    useClient,
    verifyDNI,
    verifyEmail,
} from '@/hooks/client'
import { Step2, Step3, Step1WPreliminary } from '../qualifySteps'
import { colors, validateCIF, validateDNI } from '@/utils/const'
import { useOpportunity } from '@/hooks/opportunities'
import { CircleIcon } from '../../icons'
import { Client, Opportunity } from '@/interfaces'
import { useVouchers } from '@/hooks/vouchers'
import { ApprovedBonusStep } from '../qualifySteps/ApprovedBonusStep'

interface Props {
    opportunity: Opportunity
    client: Client
    isOpen: boolean
    onClose: () => void
}

const Schema = Yup.object().shape({
    name: Yup.string().required('El nombre es requerido'),
    lastName: Yup.string().required('El apellido es requerido'),
    phone: Yup.string()
        .min(10, 'Mínimo 10 caracteres')
        .max(14, 'Maximo 14 caracteres')
        .required('El teléfono del cliente es requerido'),
    email: Yup.string()
        .email('Correo inválido')
        .required('El correo es requerido'),
    employees_number: Yup.number(),
    preliminary_agreement: Yup.boolean(),
    client_type: Yup.string(),
    dni: Yup.string().required('El número de identificación es requerida'),
    postal_code: Yup.string().required('El código postal es requerido'),
    town: Yup.string().required('La población es requerida'),
    province: Yup.string().required('La provincia es requerida'),
    home_address: Yup.string().required('La dirección es requerida'),
    comment: Yup.string(),
    representative_volunteer_id: Yup.number(),
    resolution_date: Yup.string().required(
        'La fecha de resolución es requerida',
    ),
    amount: Yup.string().required('El importe es requerido'),
    caducity_date: Yup.string().required('La fecha de caducidad es requerida'),
    bonus_expedient_number: Yup.string().required(
        'El número de expediente es requerido',
    ),
})

const steps = [0, 1, 2, 3]

export const NoVolunteerRepresentativeModal: FC<Props> = ({
    opportunity,
    client,
    isOpen,
    onClose,
}) => {
    const { nextStep, prevStep, reset, activeStep } = useSteps({
        initialStep: 0,
    })
    const toast = useToast()
    const { createVoucher } = useVouchers({})
    const { setStatusOpportunity, updateOpportunity } = useOpportunity({
        id: opportunity?.id,
    })
    const { updateClient } = useClient({
        client_id: client?.id,
    })
    const [isLoading, setIsLoading] = useState(false)
    const [representativesTypes, setRepresentativesTypes] = useState(null)
    const [clientId, setClientId] = useState(null)

    useEffect(() => {
        const fetchRepresentatives = async () => {
            const representatives = await getRepresentativeTypes()
            setRepresentativesTypes(representatives)
        }

        fetchRepresentatives()
    }, [])

    const formik = useFormik({
        initialValues: {
            diagnostic_test: true,
            name: '',
            lastName: '',
            phone: opportunity?.phone?.replace('+', '') || '',
            email: opportunity?.email || '',
            client_type: 'a',
            dni: '',
            postal_code: '',
            town: '',
            province: '',
            home_address: '',
            representative_volunteer_id: 3,
            resolution_date: '',
            amount: '',
            caducity_date: '',
            bonus_expedient_number: '',
        },
        validationSchema: Schema,
        onSubmit: () => {},
    })

    const sendClient = async customerData => {
        try {
            setIsLoading(true)

            await updateClient({
                client_id: client?.id,
                data: customerData,
            })

            await updateOpportunity({
                opportunity_id: opportunity.id,
                data: {
                    bonus_expedient_number:
                        formik.values.bonus_expedient_number,
                },
            })

            await createVoucher({
                voucherData: {
                    client_id: opportunity?.client.id,
                    amount: parseInt(formik.values.amount),
                    expiration_date: new Date(formik.values.caducity_date)
                        .toJSON()
                        .split('T')[0],
                    resolution_date: formik.values.resolution_date,
                    expedient_number: opportunity.bonus_expedient_number,
                },
            })

            await setStatusOpportunity({
                opportunity_id: opportunity.id,
                status: 'approved',
            })

            setIsLoading(false)
            reset()
            formik.resetForm()
            onClose()
        } catch (error) {
            toast({
                title: 'Error.',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }

        setIsLoading(false)
    }

    const handleNextStep = async () => {
        if ([0].includes(activeStep)) {
            nextStep()
        }

        const validations = await formik.validateForm(formik.values)

        if (activeStep === 1) {
            setClientId(null)

            if (
                validations.name ||
                validations.dni ||
                validations.home_address ||
                validations.postal_code ||
                validations.town ||
                validations.province ||
                (validations.lastName && formik.values.client_type === 'a')
            ) {
                formik.setErrors({
                    name: validations.name,
                    lastName:
                        formik.values.client_type === 'a'
                            ? validations.lastName
                            : null,
                    dni: validations.dni,
                    home_address: validations.home_address,
                    postal_code: validations.postal_code,
                    town: validations.town,
                    province: validations.province,
                })
                formik.setTouched({
                    name: true,
                    lastName: formik.values.client_type === 'a' ? true : false,
                    dni: true,
                    home_address: true,
                    postal_code: true,
                    town: true,
                    province: true,
                })
            } else {
                if (formik.values.client_type === 's') {
                    if (!validateCIF(formik.values.dni)) {
                        await formik.setFieldTouched('dni', true)
                        formik.setFieldError('dni', 'El CIF es inválido')
                    } else {
                        try {
                            setIsLoading(true)
                            await verifyDNI({
                                dni: formik.values.dni,
                                exclude_client_id: client?.id,
                            })
                            nextStep()
                        } catch (error) {
                            formik.setErrors({
                                dni: 'Ya existe un cliente con este CIF',
                            })
                            setClientId(error.cause)
                        }
                        setIsLoading(false)
                    }
                }
                if (formik.values.client_type === 'a') {
                    if (!validateDNI(formik.values.dni)) {
                        await formik.setFieldTouched('dni', true)
                        formik.setFieldError('dni', 'El NIF es inválido')
                    } else {
                        try {
                            setIsLoading(true)
                            await verifyDNI({
                                dni: formik.values.dni,
                                exclude_client_id: client?.id,
                            })
                            nextStep()
                        } catch (error) {
                            formik.setErrors({
                                dni: 'Ya existe un cliente con este NIF',
                            })
                            setClientId(error.cause)
                        }
                        setIsLoading(false)
                    }
                }
            }
        }

        if (activeStep === 2) {
            if (validations.phone || validations.email) {
                formik.setFieldError('phone', validations.phone)
                formik.setFieldError('email', validations.email)
                formik.setTouched({ phone: true, email: true })
            } else {
                try {
                    setIsLoading(true)
                    await verifyEmail({
                        email: formik.values.email,
                        exclude_client_id: client.id,
                    })
                    nextStep()
                } catch (error) {
                    await formik.setTouched({ email: true })
                    await formik.setErrors({
                        email: 'El correo ya esta en uso',
                    })
                }
                setIsLoading(false)
            }
        }

        if (activeStep === 3) {
            if (
                validations.resolution_date ||
                validations.bonus_expedient_number ||
                validations.amount ||
                validations.caducity_date
            ) {
                formik.setErrors({
                    resolution_date: validations.resolution_date,
                    bonus_expedient_number: validations.bonus_expedient_number,
                    caducity_date: validations.caducity_date,
                    amount: validations.amount,
                })
                formik.setTouched({
                    resolution_date: true,
                    bonus_expedient_number: true,
                    caducity_date: true,
                    amount: true,
                })
            } else {
                sendClient({
                    opportunity_id: opportunity.id,
                    client_type: formik.values.client_type,
                    name:
                        formik.values.client_type === 'a'
                            ? `${formik.values.name} ${formik.values.lastName}`
                            : formik.values.name,
                    dni: formik.values.dni.toUpperCase(),
                    phone: `+${formik.values.phone}`,
                    email: formik.values.email,
                    home_address: formik.values.home_address,
                    postal_code: formik.values.postal_code,
                    province: formik.values.province,
                    town: formik.values.town,
                    representative_volunteer_id: 3,
                    release_date: new Date().toJSON().split('T')[0],
                    signed_date: new Date().toJSON().split('T')[0],
                })
            }
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader
                    color="#474747"
                    display="flex"
                    fontSize={18}
                    fontWeight={600}
                    justifyContent="center"
                    py={4}>
                    Validar datos
                </ModalHeader>
                <ModalCloseButton />

                <Divider />

                <form onSubmit={formik.handleSubmit}>
                    <ModalBody px={12}>
                        <Flex flexDir="column" width="100%">
                            <Steps
                                labelOrientation="vertical"
                                size="xs"
                                colorScheme="telegram"
                                activeStep={activeStep}>
                                <Step
                                    label={
                                        activeStep === 0 && 'Tipo de cliente'
                                    }
                                    color={colors.secondary}
                                    icon={CircleIcon}>
                                    <Step1WPreliminary formik={formik} />
                                </Step>

                                <Step
                                    label={
                                        activeStep === 1 &&
                                        'Información del cliente'
                                    }
                                    color={colors.secondary}
                                    icon={CircleIcon}>
                                    <Step2
                                        formik={formik}
                                        client_id={clientId}
                                        onClose={onClose}
                                    />
                                </Step>
                                <Step
                                    label={activeStep === 2 && 'Contacto'}
                                    color={colors.secondary}
                                    icon={CircleIcon}>
                                    <Step3
                                        formik={formik}
                                        representativesTypes={
                                            representativesTypes
                                        }
                                    />
                                </Step>
                                <Step
                                    label={activeStep === 3 && 'Datos del bono'}
                                    color={colors.secondary}
                                    icon={CircleIcon}>
                                    <ApprovedBonusStep formik={formik} />
                                </Step>
                            </Steps>
                        </Flex>
                    </ModalBody>

                    <Divider mt={6} />

                    <ModalFooter px={0}>
                        <Box display="flex" justifyContent="center" w="full">
                            {activeStep > 0 && (
                                <Button
                                    bg="white"
                                    border="1px"
                                    borderColor={colors.secondary}
                                    color={colors.secondary}
                                    fontSize={12}
                                    fontWeight={500}
                                    h="32px"
                                    mr={10}
                                    w={112}
                                    onClick={prevStep}
                                    _active={{
                                        transform: 'scale(0.98)',
                                    }}
                                    _hover={{}}>
                                    Atrás
                                </Button>
                            )}
                            <Button
                                bg={colors.secondary}
                                border="1px"
                                color="white"
                                fontSize={12}
                                fontWeight={500}
                                h="32px"
                                isLoading={isLoading}
                                mr={10}
                                type={
                                    activeStep === steps.length
                                        ? 'submit'
                                        : 'button'
                                }
                                w={112}
                                onClick={handleNextStep}
                                _active={{
                                    transform: 'scale(0.98)',
                                }}
                                _hover={{}}>
                                Continuar
                            </Button>
                        </Box>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    )
}
