import React, { FC } from 'react'

import { FormikProps } from 'formik'
import {
    Text,
    Box,
    FormLabel,
    Select,
    useRadioGroup,
    HStack,
    FormControl,
    FormErrorMessage,
} from '@chakra-ui/react'

import { RadioCard, RadioCardWRadio } from '@/components/utils'

interface Props {
    formik: FormikProps<FormikValues>
}

interface FormikValues {
    preliminary_agreement: boolean
    client_type: string
}

export const Step1: FC<Props> = ({ formik }) => {
    const agreementOptions = ['No', 'Sí']

    const {
        getRootProps: getRootAgreementProps,
        getRadioProps: getRadioAgreementProps,
    } = useRadioGroup({
        name: 'preliminary_agreement',
        defaultValue: formik.values.preliminary_agreement ? 'Sí' : 'No',
    })

    const groupAgreement = getRootAgreementProps()

    const handleChangeAgreement = () => {
        formik.values.preliminary_agreement
            ? formik.setFieldValue('preliminary_agreement', false)
            : formik.setFieldValue('preliminary_agreement', true)
    }

    return (
        <Box
            w="full"
            display="flex"
            justifyContent="center"
            mt={6}
            color="black">
            <Box w="50%">
                <FormControl
                    mt={6}
                    isInvalid={
                        formik.errors.preliminary_agreement &&
                        formik.touched.preliminary_agreement
                    }>
                    <FormLabel
                        color="#4F4F4F"
                        fontSize={13}
                        fontWeight={700}
                        mr={10}>
                        Pre-acuerdo
                    </FormLabel>

                    <HStack
                        onChange={handleChangeAgreement}
                        {...groupAgreement}>
                        {agreementOptions.map(value => {
                            const radio = getRadioAgreementProps({ value })

                            if (value !== 'No') return null

                            return (
                                <RadioCardWRadio key={value} {...radio}>
                                    <Text
                                        fontSize={12}
                                        fontWeight={400}
                                        color="#474747">
                                        {value}
                                    </Text>
                                </RadioCardWRadio>
                            )
                        })}
                    </HStack>
                    <FormErrorMessage>
                        {formik.errors.preliminary_agreement}
                    </FormErrorMessage>
                </FormControl>
            </Box>
        </Box>
    )
}
