export function formatDate(
    date: string | Date | null,
    options: Intl.DateTimeFormatOptions = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour12: false,
    },
) {
    if (!date) return '-'
    const dateObj = new Date(date)

    if (isNaN(dateObj.getTime())) return '-'
    return dateObj.toLocaleString('es-ES', options)
}
