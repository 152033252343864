import React, { FC, SetStateAction, useState } from 'react'

import NextLink from 'next/link'

import {
    Text,
    Box,
    Link,
    useClipboard,
    useToast,
    Alert,
    AlertIcon,
    Image,
    Spinner,
} from '@chakra-ui/react'

import { CopyIcon } from '@chakra-ui/icons'
import { colors } from '@/utils/const'
import { sendURLClient } from '@/hooks/opportunities'

interface Props {
    opportunity_id: number
    link: string
    data: string
}

export const URLClientCard: FC<Props> = ({
    opportunity_id,
    link,
    data = '',
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const { onCopy } = useClipboard(link)
    const toast = useToast()
    const { step, stepDescription, employees_number, iae, antiquity } =
        data !== null
            ? JSON.parse(data)
            : {
                  step: '0',
                  stepDescription: '',
                  employees_number: '0',
                  iae: '',
                  antiquity: '',
              }

    const incidents = employees_number === '0' || !iae ? true : false

    const sixMonths = antiquity === 'Menos de 6 meses' ? true : false

    const handleCopy = () => {
        onCopy()
        toast({
            description: 'Link copiado',
            status: 'info',
            duration: 1000,
            isClosable: true,
        })
    }

    const handleSendWs = async () => {
        setIsLoading(true)
        try {
            await sendURLClient({
                opportunity_id,
            })

            toast({
                title: 'Éxito',
                description: 'La URL ha sido enviada',
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
        } catch (error) {
            toast({
                title: 'Error.',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }
        setIsLoading(false)
    }

    return (
        <Box
            bg="white"
            borderWidth={1}
            borderRadius="md"
            boxShadow="lg"
            p={4}
            w="100%">
            <Text fontSize={16} fontWeight={400}>
                URL solicitud cliente
            </Text>

            <Box p={2}>
                <Box display="flex" alignItems="center" mb={2}>
                    <Text fontSize={14} fontWeight={700}>
                        URL:
                    </Text>
                    <NextLink href={link} target="_blank" passHref>
                        <Link
                            ml={2}
                            fontSize={13}
                            color={colors.secondary}
                            textDecoration="underline">
                            {link}
                        </Link>
                    </NextLink>
                    <CopyIcon cursor="pointer" ml={4} onClick={handleCopy} />

                    {isLoading ? (
                        <Spinner
                            ml={4}
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="blue.500"
                        />
                    ) : (
                        <Image
                            alt="WhatsApp"
                            cursor="pointer"
                            ml={4}
                            h="30px"
                            src="/images/WhatsApp.png"
                            w="30px"
                            onClick={handleSendWs}
                        />
                    )}
                </Box>

                <Box display="flex" mb={2}>
                    <Text fontSize={14} fontWeight={700}>
                        Step:
                    </Text>
                    <Text fontSize={14} fontWeight={400} ml={2}>
                        {step} de 8 {stepDescription}
                    </Text>
                </Box>

                <Box display="flex" mb={2}>
                    <Text fontSize={14} fontWeight={700}>
                        Segmento:
                    </Text>
                    <Text fontSize={14} fontWeight={400} ml={2}>
                        {employees_number === '0'
                            ? 'Sin definir'
                            : employees_number}
                    </Text>
                </Box>

                <Box mb={2}>
                    <Text fontSize={14} fontWeight={700} mb={2}>
                        Incidencias:
                    </Text>

                    <Box>
                        {!incidents && !sixMonths ? (
                            <>
                                <Alert
                                    status="success"
                                    mb={4}
                                    color="green"
                                    fontSize={12}
                                    fontWeight={600}
                                    maxW="600px">
                                    <AlertIcon />
                                    Cliente sin incidencias
                                </Alert>
                            </>
                        ) : (
                            <>
                                {incidents ? (
                                    <>
                                        {employees_number === '0' && (
                                            <Alert
                                                status="error"
                                                mb={4}
                                                color="red"
                                                fontSize={12}
                                                fontWeight={600}
                                                maxW="600px">
                                                <AlertIcon />
                                                Cliente sin rellenar su número
                                                de empleados
                                            </Alert>
                                        )}

                                        {!iae && (
                                            <Alert
                                                status="error"
                                                mb={4}
                                                color="red"
                                                fontSize={12}
                                                fontWeight={600}
                                                maxW="600px">
                                                <AlertIcon />
                                                Cliente sin rellenar su número
                                                de IAE
                                            </Alert>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <Alert
                                            status="warning"
                                            mb={4}
                                            color={colors['warning-high']}
                                            fontSize={12}
                                            fontWeight={600}
                                            maxW="600px">
                                            <AlertIcon />
                                            Cliente con menos de 6 meses de
                                            antigüedad
                                        </Alert>
                                    </>
                                )}
                            </>
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
