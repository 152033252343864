import {
    Box,
    Button,
    HStack,
    IconButton,
    Input,
    Select,
    Text,
} from '@chakra-ui/react'
import React from 'react'
import { EditLine } from '../icons'
import { useClient } from '@/hooks/client'
import { ANTIQUITY_OPTIONS } from '@/constants/antiquity'

type Props = {
    initialValue: string
    clientId: number
}

export default function EditableAntiquity({ initialValue, clientId }: Props) {
    const [value, setValue] = React.useState(initialValue)
    const [isEditing, setIsEditing] = React.useState(false)
    const [isPending, setIsPending] = React.useState(false)

    const { updateClient } = useClient({ client_id: clientId })

    async function handleSubmit(event: React.FormEvent) {
        setIsPending(true)
        event.preventDefault()

        try {
            await updateClient({
                client_id: clientId,
                data: { antiquity: value },
            })
        } finally {
            setIsEditing(false)
            setIsPending(false)
        }
    }

    if (isEditing) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                gap="2"
                onSubmit={handleSubmit}>
                <Select
                    id="antiquity"
                    size="sm"
                    value={value}
                    onChange={e => setValue(e.target.value)}>
                    {ANTIQUITY_OPTIONS.map(item => (
                        <option key={item} value={item}>
                            {item}
                        </option>
                    ))}
                </Select>

                <HStack ml="auto">
                    <Button
                        variant="ghost"
                        type="button"
                        size="sm"
                        fontSize="xs"
                        p={2}
                        disabled={isPending}
                        onClick={() => {
                            setValue(initialValue)
                            setIsEditing(false)
                        }}>
                        Cancelar
                    </Button>
                    <Button
                        type="submit"
                        size="sm"
                        fontSize="xs"
                        p={2}
                        disabled={isPending}
                        isLoading={isPending}
                        onClick={handleSubmit}>
                        Guardar
                    </Button>
                </HStack>
            </Box>
        )
    }

    return (
        <HStack alignItems="flex-start">
            <Box>
                <Text color="#4F4F4F" fontSize={13} fontWeight={700}>
                    Antigüedad
                </Text>
                <Text
                    color="#4F4F4F"
                    fontSize={12}
                    fontWeight={400}
                    ml={4}
                    mb={4}>
                    {value}
                </Text>
            </Box>
            <IconButton
                p={0}
                aria-label=""
                variant="ghost"
                height="24px"
                width="24px"
                onClick={() => setIsEditing(true)}>
                <EditLine />
            </IconButton>
        </HStack>
    )
}
