import React, { FC, useState, useEffect } from 'react'

import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
    Box,
    Button,
    Center,
    IconButton,
    Select,
    Text,
    useToast,
} from '@chakra-ui/react'

import { EditLine } from '../icons'
import { Comercial } from '@/interfaces'
import { colors } from '@/utils/const'
import { getUsers } from '@/hooks/users'
import { useOpportunity } from '@/hooks/opportunities'

interface Props {
    opportunity_id: number
    rolUser: string
    comercial: Comercial
}

const Schema = Yup.object().shape({
    gestor: Yup.number().required('El gestor es requerido'),
})

export const GestorDetail: FC<Props> = ({
    opportunity_id,
    rolUser,
    comercial,
}) => {
    const { updateOpportunity } = useOpportunity({ id: opportunity_id })
    const [isLoading, setIsLoading] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [gestors, setGestors] = useState([])
    const toast = useToast()

    useEffect(() => {
        if (['admin'].includes(rolUser)) {
            getUsers({})
                .then(data => setGestors(data))
                .catch(error => {
                    toast({
                        title: 'Error.',
                        description: error.message,
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    })
                })
        }
    }, [])

    const formik = useFormik({
        initialValues: {
            gestor: comercial?.id,
        },
        validationSchema: Schema,
        onSubmit: async values => {
            setIsLoading(true)

            await updateOpportunity({
                opportunity_id,
                data: {
                    user_id: values.gestor,
                },
            })

            try {
                toast({
                    title: 'Éxito',
                    description: 'Gestor actualizado con éxito',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })

                setIsEditing(false)
            } catch (error) {
                toast({
                    title: 'Error.',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })

                formik.resetForm()
            }

            setIsLoading(false)
        },
    })

    return (
        <>
            {isEditing ? (
                <>
                    <Box w="full" maxW={300}>
                        <form onSubmit={formik.handleSubmit}>
                            <Text fontSize={12} fontWeight={700} mb={1}>
                                Gestor:
                            </Text>
                            <Select
                                id="gestor"
                                size="xs"
                                mb={2}
                                rounded={6}
                                value={formik.values.gestor}
                                onChange={formik.handleChange}>
                                {gestors.map(gestor => (
                                    <option key={gestor.id} value={gestor.id}>
                                        {gestor.name}
                                    </option>
                                ))}
                            </Select>

                            <Center>
                                <Button
                                    bg={colors.tertiary}
                                    border="1px"
                                    color="white"
                                    fontSize={12}
                                    fontWeight={500}
                                    h="24px"
                                    isLoading={isLoading}
                                    type="submit"
                                    w={84}
                                    _active={{
                                        transform: 'scale(0.98)',
                                    }}
                                    _hover={{}}>
                                    Guardar
                                </Button>
                            </Center>
                        </form>
                    </Box>

                    {['admin', 'administrative'].includes(rolUser) && (
                        <IconButton
                            aria-label={''}
                            bg="transparent"
                            icon={
                                <EditLine
                                    transform="scale(1.2)"
                                    color="#858585"
                                />
                            }
                            onClick={() => setIsEditing(!isEditing)}
                            _active={{}}
                            _hover={{}}
                        />
                    )}
                </>
            ) : (
                <>
                    <Text fontSize={12} fontWeight={700}>
                        Gestor:
                    </Text>
                    <Text fontSize={12} fontWeight={600} mx={2}>
                        {comercial ? comercial.name : 'Sin definir'}
                    </Text>

                    {['admin', 'administrative'].includes(rolUser) && (
                        <IconButton
                            aria-label={''}
                            bg="transparent"
                            icon={
                                <EditLine
                                    transform="scale(1.2)"
                                    color="#858585"
                                />
                            }
                            onClick={() => setIsEditing(!isEditing)}
                            _active={{}}
                            _hover={{}}
                        />
                    )}
                </>
            )}
        </>
    )
}
